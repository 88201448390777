import React, { useEffect } from "react";

//styling
import { ModalStyle } from "./styles";
import { StyledButton } from "../../styles/styles";

const CustomModal = props => {
  const buttonColor =
    props.buttonColor !== undefined ? props.buttonColor : "#001649";
  const escFunction = e => {
    if (e.keyCode === 27) {
      return props.submit === undefined ? props.onClose(e) : props.submit(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  let element = props.show ? (
    <div className="modal" id="modal">
      <div className="modalContainer">
        <div className="content">{props.children}</div>
        <div
          className="actions"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {props.submit !== undefined ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <StyledButton
                className={buttonColor}
                onClick={props.submit}
                style={{
                  margin: "0",
                  width: "50%",
                  maxWidth: "300px",
                  height: "90%",
                  fontSize: "auto"
                }}
              >
                <b>{"CLOSE"}</b>
              </StyledButton>
            </div>
          ) : null}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <StyledButton
              className={`${buttonColor} ${props.buttonActive}`}
              onClick={props.onClose}
              style={{
                alignSelf: "center",
                margin: "0",
                fontSize: "auto",
                width: "50%",
                maxWidth: "300px",
                height: "90%"
              }}
            >
              {props.value === undefined ? <b>ADD</b> : <b>{props.value}</b>}
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
  return <ModalStyle theme={buttonColor}>{element}</ModalStyle>;
};

export default CustomModal;
