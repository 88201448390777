export const allGrid = {
  data: {
    QUEEN_ANNE: {
      dh: {
        FA: 17.63,
        FW: 17.63,
        FT: 37.65,
        CW: 23.71,
        CA: 23.71,
        CT: 52.21
      },
      sl: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 31.61,
        CA: 31.61,
        CT: 60.11
      },
      sl3: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 31.61,
        CA: 31.61,
        CT: 60.11
      },
      cs: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.57
      },
      pw: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.57
      },
      aw: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.57
      },
      pwc: {
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.57
      }
    },
    DIAMOND: {
      "4D": {
        max_height: 24,
        max_width: 24,
        min_height: 12,
        min_width: 11,
        DW: 17.63,
        DA: 17.63,
        DT: 37.65
      },
      "7D": {
        max_height: 24,
        max_width: 24,
        min_height: 12,
        min_width: 16,
        DW: 23.09,
        DA: 23.09,
        DT: 43.11
      },
      "10D": {
        max_height: 24,
        max_width: 36,
        min_height: 12,
        min_width: 26,
        DW: 25.54,
        DA: 25.54,
        DT: 45.56
      },
      "12D": {
        max_height: 48,
        max_width: 24,
        min_height: 24,
        min_width: 16,
        DW: 27.95,
        DA: 47.97,
        DT: 47.97
      },
      "13D": {
        max_height: 24,
        max_width: 48,
        min_height: 12,
        min_width: 38,
        DW: 31.0,
        DA: 31.0,
        DT: 51.02
      },
      "17D": {
        max_height: 48,
        max_width: 36,
        min_height: 24,
        min_width: 26,
        DW: 41.93,
        DA: 41.93,
        DT: 61.95
      },
      "22D": {
        max_height: 48,
        max_width: 48,
        min_height: 24,
        min_width: 38,
        DW: 54.69,
        DA: 54.69,
        DT: 74.71
      },
      "27D": {
        max_height: 48,
        max_width: 60,
        min_height: 24,
        min_width: 50,
        DW: 70.5,
        DA: 70.5,
        DT: 90.52
      },
      "32D": {
        max_height: 48,
        max_width: 70,
        min_height: 24,
        min_width: 55,
        DW: 82.66,
        DA: 82.66,
        DT: 102.68
      },
      "45D": {
        max_height: 60,
        max_width: 70,
        min_height: 46,
        min_width: 55,
        DW: 105.75,
        DA: 105.75,
        DT: 125.77
      },
      "17DT": {
        max_height: 60,
        max_width: 24,
        min_height: 46,
        min_width: 16,
        DW: 41.95,
        DA: 41.95,
        DT: 61.97
      },
      "24D": {
        max_height: 60,
        max_width: 36,
        min_height: 46,
        min_width: 26,
        DW: 62.1,
        DA: 62.1,
        DT: 82.12
      },
      "31D": {
        max_height: 60,
        max_width: 48,
        min_height: 46,
        min_width: 38,
        DW: 76.58,
        DA: 76.58,
        DT: 96.6
      },
      "38D": {
        max_height: 60,
        max_width: 60,
        min_height: 46,
        min_width: 50,
        DW: 92.38,
        DA: 92.38,
        DT: 112.4
      },
      "22DT": {
        max_height: 84,
        max_width: 24,
        min_height: 60,
        min_width: 16,
        DW: 54.69,
        DA: 54.69,
        DT: 74.71
      },
      "31DT": {
        max_height: 84,
        max_width: 36,
        min_height: 60,
        min_width: 26,
        DW: 76.58,
        DA: 76.58,
        DT: 96.6
      },
      "40D": {
        max_height: 84,
        max_width: 48,
        min_height: 60,
        min_width: 38,
        DW: 98.46,
        DA: 98.46,
        DT: 118.48
      },
      "49D": {
        max_height: 84,
        max_width: 60,
        min_height: 60,
        min_width: 50,
        DW: 114.25,
        DA: 114.25,
        DT: 134.27
      },
      "58D": {
        max_height: 60,
        max_width: 70,
        min_height: 55,
        min_width: 55,
        DW: 130.05,
        DA: 130.05,
        DT: 150.07
      }
    },
    COLONIAL: {
      "1Vx2H": {
        name: "6 Lite Grid",
        max_height: 36,
        max_width: 28,
        min_height: 14,
        min_width: 15,
        FA: 18.24,
        FW: 18.24,
        FT: 38.26,
        CW: 29.78,
        CA: 29.78,
        CT: 58.29
      },
      "2Vx2H": {
        name: "9 Lite Grid",
        max_height: 36,
        max_width: 36,
        min_height: 14,
        min_width: 29,
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.58
      },
      "3Vx2H": {
        name: "12 Lite Grid",
        max_height: 36,
        max_width: 54,
        min_height: 14,
        min_width: 37,
        FA: 32.82,
        FW: 32.82,
        FT: 52.84,
        CW: 44.36,
        CA: 44.36,
        CT: 65.58
      },
      "4Vx2H": {
        name: "15 Lite Grid",
        max_height: 36,
        max_width: 72,
        min_height: 14,
        min_width: 55,
        FA: 41.33,
        FW: 41.33,
        FT: 61.35,
        CW: 52.87,
        CA: 52.87,
        CT: 81.38
      },
      "1Vx3H": {
        name: "8 Lite Grid",
        max_height: 48,
        max_width: 28,
        min_height: 37,
        min_width: 15,
        FA: 25.53,
        FW: 25.53,
        FT: 45.55,
        CW: 37.07,
        CA: 37.07,
        CT: 65.58
      },
      "2Vx3H": {
        name: "12 Lite Grid",
        max_height: 48,
        max_width: 36,
        min_height: 37,
        min_width: 29,
        FA: 32.82,
        FW: 32.82,
        FT: 52.84,
        CW: 44.36,
        CA: 44.36,
        CT: 65.58
      },
      "3Vx3H": {
        name: "16 Lite Grid",
        max_height: 48,
        max_width: 54,
        min_height: 37,
        min_width: 37,
        FA: 41.33,
        FW: 41.33,
        FT: 61.35,
        CW: 52.87,
        CA: 52.87,
        CT: 81.38
      },
      "4Vx3H": {
        name: "20 Lite Grid",
        max_height: 48,
        max_width: 72,
        min_height: 37,
        min_width: 55,
        FA: 48.62,
        FW: 48.62,
        FT: 68.64,
        CW: 60.16,
        CA: 60.16,
        CT: 88.67
      },
      "1Vx4H": {
        name: "10 Lite Grid",
        max_height: 60,
        max_width: 28,
        min_height: 49,
        min_width: 15,
        FA: 32.82,
        FW: 32.82,
        FT: 52.84,
        CW: 44.36,
        CA: 44.36,
        CT: 72.87
      },
      "2Vx4H": {
        name: "15 Lite Grid",
        max_height: 60,
        max_width: 36,
        min_height: 49,
        min_width: 29,
        FA: 41.33,
        FW: 41.33,
        FT: 61.35,
        CW: 52.87,
        CA: 52.87,
        CT: 81.38
      },
      "3Vx4H": {
        name: "20 Lite Grid",
        max_height: 60,
        max_width: 54,
        min_height: 49,
        min_width: 37,
        FA: 48.62,
        FW: 48.62,
        FT: 68.64,
        CW: 60.16,
        CA: 60.16,
        CT: 88.67
      },
      "4Vx4H": {
        name: "25 Lite Grid",
        max_height: 60,
        max_width: 72,
        min_height: 49,
        min_width: 55,
        FA: 55.91,
        FW: 55.91,
        FT: 75.93,
        CW: 67.45,
        CA: 67.45,
        CT: 95.96
      },
      "1Vx5H": {
        name: "12 Lite Grid",
        max_height: 72,
        max_width: 28,
        min_height: 61,
        min_width: 15,
        FA: 32.82,
        FW: 32.82,
        FT: 52.84,
        CW: 44.36,
        CA: 44.36,
        CT: 65.58
      },
      "2Vx5H": {
        name: "18 Lite Grid",
        max_height: 72,
        max_width: 36,
        min_height: 61,
        min_width: 29,
        FA: 48.62,
        FW: 48.62,
        FT: 68.64,
        CW: 60.16,
        CA: 44.36,
        CT: 88.67
      },
      "3Vx5H": {
        name: "24 Lite Grid",
        max_height: 72,
        max_width: 54,
        min_height: 61,
        min_width: 37,
        FA: 55.91,
        FW: 55.91,
        FT: 75.93,
        CW: 67.45,
        CA: 67.45,
        CT: 95.96
      },
      "4Vx5H": {
        name: "30 Lite Grid",
        max_height: 72,
        max_width: 72,
        min_height: 61,
        min_width: 55,
        FA: 66.84,
        FW: 66.84,
        FT: 86.86,
        CW: 78.38,
        CA: 78.38,
        CT: 106.89
      },
      dh: {
        // "0Vx1H": {
        //   name: "1 horz bar",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 3.96,
        //   FW: 3.96,
        //   FT: 23.98,
        //   CW: 10.04,
        //   CA: 10.04,
        //   CT: 38.54
        // },
        // "0Vx2H": {
        //   name: "2 horz bar",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 5.79,
        //   FW: 5.79,
        //   FT: 25.81,
        //   CW: 11.87,
        //   CA: 11.87,
        //   CT: 40.37
        // },
        // "1Vx0H": {
        //   name: "1 vert bar",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 3.96,
        //   FW: 3.96,
        //   FT: 23.98,
        //   CW: 10.04,
        //   CA: 10.04,
        //   CT: 38.54
        // },
        // "2Vx0H": {
        //   name: "2 vert bar",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 5.79,
        //   FW: 5.79,
        //   FT: 25.81,
        //   CW: 11.87,
        //   CA: 11.87,
        //   CT: 40.37
        // },
        // "1Vx1H": {
        //   name: "4 Lite Grid",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 5.79,
        //   FW: 5.79,
        //   FT: 25.81,
        //   CW: 11.87,
        //   CA: 11.87,
        //   CT: 40.37
        // },
        "2Vx1H": {
          name: "6 Lite Grid",
          max_height: 999,
          max_width: 999,
          min_height: 1,
          min_width: 1,
          FA: 8.51,
          FW: 8.51,
          FT: 28.53,
          CW: 14.59,
          CA: 14.59,
          CT: 43.09
        }
        // "3Vx1H": {
        //   name: "8 Lite Grid",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 9.71,
        //   FW: 9.71,
        //   FT: 29.73,
        //   CW: 15.79,
        //   CA: 15.79,
        //   CT: 44.29
        // },
        // "2Vx2H": {
        //   name: "9 Lite Grid",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 12.15,
        //   FW: 12.15,
        //   FT: 32.17,
        //   CW: 18.23,
        //   CA: 18.23,
        //   CT: 46.73
        // },
        // "4Vx1H": {
        //   name: "10 Lite Grid",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 14.58,
        //   FW: 14.58,
        //   FT: 34.6,
        //   CW: 20.66,
        //   CA: 20.66,
        //   CT: 49.16
        // },
        // "3Vx2H": {
        //   name: "12 Lite Grid",
        //   max_height: 999,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 17.63,
        //   FW: 17.63,
        //   FT: 37.65,
        //   CW: 23.71,
        //   CA: 23.71,
        //   CT: 52.21
        // }
      },
      sl: {
        // "1Vx1H": {
        //   name: "4 Lite Slider",
        //   max_height: 48,
        //   max_width: 999,
        //   min_height: 1,
        //   min_width: 1,
        //   FA: 5.79,
        //   FW: 5.79,
        //   FT: 25.81,
        //   CW: 11.87,
        //   CA: 11.87,
        //   CT: 40.37
        // },
        "1Vx2H": {
          name: "6 Lite Slider",
          max_height: 48,
          max_width: 999,
          min_height: 1,
          min_width: 1,
          FA: 8.51,
          FW: 8.51,
          FT: 28.53,
          CW: 14.59,
          CA: 14.59,
          CT: 43.09
        }
        //   "1Vx3H": {
        //     name: "8 Lite Slider",
        //     max_height: 999,
        //     max_width: 999,
        //     min_height: 1,
        //     min_width: 1,
        //     FA: 9.71,
        //     FW: 9.71,
        //     FT: 29.73,
        //     CW: 15.79,
        //     CA: 15.79,
        //     CT: 44.29
        //   },
        //   "2Vx3H": {
        //     name: "12 Lite Slider",
        //     max_height: 999,
        //     max_width: 999,
        //     min_height: 1,
        //     min_width: 1,
        //     FA: 17.63,
        //     FW: 17.63,
        //     FT: 37.65,
        //     CW: 23.71,
        //     CA: 23.71,
        //     CT: 52.21
        //   }
      }
    }
  }
};
