import React, { useContext, useState } from "react";
import { Context } from "../App";
import { StyledButton } from "../styles/styles";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@material-ui/core";
import { StyledSidebar } from "../styles/styles";
import { codeToName } from "../components/utils/helper";

const SideNav = ({ history }) => {
  const {
    show,
    currWindows,
    setCurrWindows,
    cart,
    setCurrentWindow,
    setShow,
    setTotalPrice,
    newCart,
    setEditModal
  } = useContext(Context);

  const deleteIt = index => {
    console.log(index);
    let a = currWindows.filter((x, indexVal) => indexVal !== index);
    console.log(a);
    setCurrWindows(a);
  };
  const checkoutOnClick = e => {
    e.preventDefault();
    getGrandTotal();
    console.log(JSON.stringify(cart));
    console.log("here is my new cart");
    console.log(newCart);
    setShow(false);
    setCurrentWindow({});
    history.push("/checkout");
  };

  const getGrandTotal = () => {
    const final = Object.keys(cart).reduce(
      (acum, curr) => acum + +cart[curr].price,
      0
    );
    setTotalPrice(final);
    return final;
  };

  const editWindow = e => {
    e.preventDefault();
    console.log("here is edit");
    console.log(cart[e.currentTarget.id]);
    setCurrentWindow({});
    history.push(
      `/pricer/${codeToName(cart[e.currentTarget.id]["mts_Style"])}`
    );
    setCurrentWindow(cart[e.currentTarget.id]);
    console.log(cart[e.currentTarget.id]);
    // delete cart[e.currentTarget.id];
    setShow(false);
    // setEditRedirect(true);
  };

  const openModal = e => {
    e.preventDefault();
    setCurrentWindow({});
    console.log(e.currentTarget.id);
    console.log(cart[e.currentTarget.id]);
    setCurrentWindow(cart[e.currentTarget.id]);
    setEditModal(true);
  };

  return (
    <>
      <StyledSidebar open={show}>
        {cart && Object.keys(cart).length > 0 ? (
          <>
            <Table className="shoppingCart">
              <TableHead>
                <TableRow>
                  <TableCell className="cell">
                    <b>Window</b>
                  </TableCell>
                  <TableCell className="cell">
                    <b>Quantity</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart
                  ? Object.keys(cart).map((x, i) => {
                      return (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          key={i}
                          onClick={openModal}
                          id={x}
                        >
                          <TableCell className="cell" id={x}>
                            {cart[x].name}
                          </TableCell>

                          <TableCell className="cell" id={x}>
                            {cart[x].mts_Quantity}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
                {/* {cart ? (
                  <TableRow>
                    <TableCell className="cell">
                      <b>Total</b>
                    </TableCell>

                    <TableCell className="cell">
                      ${parseFloat(+getGrandTotal()).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </TableBody>
            </Table>

            <StyledButton
              onClick={checkoutOnClick}
              // onClick={tmpCheckout}
              margin="15px 0"
            >
              SUBMIT QUOTE
            </StyledButton>
          </>
        ) : (
          <div className="emptyShoppingCart">Cart Empty</div>
        )}
      </StyledSidebar>
    </>
  );
};

export default SideNav;
