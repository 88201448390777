import React, { useState, useContext, useEffect } from "react";
import Home from "./container/home";
import Pricer from "./container/pricer";
import Checkout from "./container/checkout";
import CheckoutAdmin from "./container/checkout_admin";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import SideNav from "./components/SideNav";
import { ThemeProvider } from "styled-components";
import { AzureAD } from "react-aad-msal";
import OtherLoader from "./components/OtherLoader/OtherLoader";
import Login from "./container/Login/Login";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";

import axios from "axios";
import { authProvider } from "./Auth/authConfig";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import PWAPrompt from "react-ios-pwa-prompt";
import Lookup from "./container/Lookup/Lookup";

const theme = {
  lightBlue: "#3cc7fa",
  midnightBlue: "#001649",
  lightSteelBlue: "#E3E9EE",
  slateGrey: "#6f7d8B",
  cadetBlue: "#658eb3",
  challengeGreen: "#49B54A",
  alertRed: "#FF0000",
  backgroundDarkGrey: "#f5f5f5",
  backgroundLightGrey: "#e5e5e5",
  font: "Roboto, sans-serif",
};

const Context = React.createContext({});
export { Context };

const PublicRoute = (props) => {
  const { component: Component, ...rest } = props;
  const { show } = useContext(Context);
  return (
    <AzureAD provider={authProvider}>
      {({ accountInfo }) => {
        return accountInfo && accountInfo.account ? (
          <Route
            {...rest}
            render={(props) => (
              <>
                <Header history={props.history} accountInfo={accountInfo} />
                <div className={show ? "RouterMainOpen" : "RouterMainClosed"}>
                  <ServiceWorkerWrapper />
                  <Component {...props} />
                </div>
                <SideNav history={props.history} />
              </>
            )}
          />
        ) : (
          <Route
            {...rest}
            render={(props) => (
              <Login login={authProvider.login} history={props.history} />
            )}
          />
        );
      }}
    </AzureAD>
  );
};
const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [proposals, setProposals] = useState("");
  const [currWindows, setCurrWindows] = useState([]);
  const [showPrice, setShowPrice] = useState(false);
  const [currentWindow, setCurrentWindow] = useState({});
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState({});
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [editModal, setEditModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [online, setOnline] = useState(true);
  const [sizeErr, setSizeErr] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [isAdminPin, setIsAdminPin] = useState("");
  const [showSave, setShowSave] = useState(false);
  const [multipleSub, setMultipleSub] = useState(false);
  const [managerValues, setManagerValues] = useState({ down: 0, percent: 1 });
  const [isReplaced, setIsReplaced] = useState(false);
  const [propIds, setPropIds] = useState([]);
  const [quickLoad, setQuickLoad] = useState(false);
  //for v2
  const [newCart, setNewCart] = useState({
    OPP_NUM: "",
    PROPOSAL_NAME: "",
    EMAIL: "",
    PROPOSAL_ID: "",
    CART: [],
  });
  const [isEdit, setIsEdit] = useState(false);

  function getIndexedDB() {
    return new Promise(function (resolve, reject) {
      var db = indexedDB.open("savedCart");
      db.onsuccess = function (event) {
        this.result
          .transaction("savedCartObj")
          .objectStore("savedCartObj")
          .getAll().onsuccess = function (event) {
          let data = event.target.result;
          console.log("inside");
          console.log(data);
          resolve(data);
        };
      };
      db.onerror = function (err) {
        reject(err);
      };
    });
  }
  function sendData() {
    getIndexedDB()
      .then((resp) => {
        console.log("here is resp");
        console.log(resp);
        if (resp.length !== 0) {
          let body = { cart: resp };

          var postObj = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          };
          return fetch(
            "https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/insert_windows",
            postObj
          )
            .then((val) => {
              console.log("quote uploaded");
              //refetch
              axios
                .post(
                  `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_proposals`,
                  {
                    opp_num: `${newCart.OPP_NUM}`,
                  }
                )
                .then((v) => {
                  setProposals(v.data);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          return;
        }
      })
      .then(clearData)
      .catch((err) => {
        console.log(err);
      });
  }

  function clearData() {
    return new Promise(function (resolve, reject) {
      const db = indexedDB.open("savedCart");
      db.onsuccess = function (event) {
        this.result
          .transaction(["savedCartObj"], "readwrite")
          .objectStore("savedCartObj")
          .clear().onsuccess = function (event) {};
      };
    });
  }

  const syncIt = () => {
    return sendData();
  };
  useEffect(() => {
    //try to reload
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      });
    }
    //set email
    // setNewCart({ ...newCart, EMAIL: "MBHARRAT@MOMENTUMSOLAR.COM" });
    //check local storage
    let savedCart = localStorage.getItem("cart");
    console.log("here is cart");
    console.log(JSON.parse(savedCart));
    savedCart && setCart(JSON.parse(savedCart));
    window.addEventListener("offline", () => {
      setOnline(false);
      NotificationManager.warning(
        `Don't worry, this app can work offline!`,
        "No Service",
        2000
      );
    });

    window.addEventListener("online", () => {
      setOnline(true);
      NotificationManager.success(
        "All offline quotes will be updloaded.",
        "Found Service",
        2000
      );
      syncIt();
    });
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      NotificationManager.info(``, `CUSTOMER MODE v2.7.0`, 2000);
    } else {
      NotificationManager.warning(``, "ADMIN MODE v2.7.0", 2000);
    }
  }, [isAdmin]);

  const formButtonClick = () => {
    NotificationManager.error(
      "Make sure you have a correct height and width set!",
      "Invalid Window Size",
      3000
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider
        value={{
          isLoading,
          setIsLoading,
          currWindows,
          setCurrWindows,
          show,
          setShow,
          showPrice,
          setShowPrice,
          setCart,
          cart,
          currentWindow,
          setCurrentWindow,
          totalPrice,
          setTotalPrice,
          isAdmin,
          setIsAdmin,
          showPin,
          setShowPin,
          online,
          syncIt,
          setSizeErr,
          formButtonClick,
          showAdd,
          setShowAdd,
          isAdminPin,
          setIsAdminPin,
          setShowSave,
          showSave,
          managerValues,
          setManagerValues,
          proposals,
          setProposals,
          newCart,
          setNewCart,
          isEdit,
          setIsEdit,
          editModal,
          setEditModal,
          multipleSub,
          setMultipleSub,
          setIsReplaced,
          isReplaced,
          propIds,
          setPropIds,
          quickLoad,
          setQuickLoad,
        }}
      >
        {quickLoad ? (
          <OtherLoader />
        ) : (
          <BrowserRouter>
            <Switch>
              <PublicRoute path="/" exact component={Lookup} />
              <PublicRoute path="/pricer" exact component={Home} />
              <PublicRoute path="/pricer/:id" component={Pricer} />
              <PublicRoute path="/checkout" component={Checkout} />
              <PublicRoute path="/checkoutadmin" component={CheckoutAdmin} />
            </Switch>
          </BrowserRouter>
        )}
      </Context.Provider>
      {/* <PWAPrompt /> */}
      <NotificationContainer />
    </ThemeProvider>
  );
};

export default AppRouter;
