import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import OtherLoader from "../../components/OtherLoader/OtherLoader";
import { StyledBody } from "../../styles/styles";
import StyledLookup from "./styles";
import Card from "../../components/AnimatedCard";
import Check from "../../icons/Check/Check";
import { Context } from "../../App";
import { allWindows } from "../../Data/window";
import DynamicModal from "../../components/DynamicModal/DynamicModal";
const { readCart } = require("../../Helper/API");
const moment = require("moment");
const getWindowPricing = () => allWindows;
// axios.get(
//   "https://azhlooipqc.execute-api.us-east-2.amazonaws.com/prod/staticwindows"
// );

const Lookup = ({ history }) => {
  //context
  const {
    show,
    isLoading,
    setIsLoading,
    proposals,
    setProposals,
    newCart,
    setNewCart,
    setCart,
    managerValues,
    setManagerValues
  } = useContext(Context);
  const [windowPricing, setWindowPricing] = useState("");
  const [oppNum, setOppNum] = useState("");
  const [propName, setPropName] = useState("");
  const [addPropModal, setAddPropModal] = useState(false);

  const proposalClick = e => {
    e.preventDefault();
    // alert(e.currentTarget.id);
    //find name
    setIsLoading(true);
    const propFound = proposals.filter(
      i => i.PROPOSAL_ID === e.currentTarget.id
    );

    console.log("verify data is flowing");

    console.log({
      ...newCart,
      PROPOSAL_NAME: propFound[0].PROPOSAL_NAME,
      PROPOSAL_ID: propFound[0].PROPOSAL_ID
    });
    setNewCart({
      ...newCart,
      PROPOSAL_NAME: propFound.PROPOSAL_NAME,
      PROPOSAL_ID: e.currentTarget.id
    });

    //do some lookup
    axios
      .post(
        "https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_cart",
        {
          PROPOSAL_ID: e.currentTarget.id
        }
      )
      .then(v => {
        if (v.data.length !== 0 && v.data.length) {
          let restruct = readCart(v.data);
          restruct.newcart.EMAIL = newCart.EMAIL;
          setNewCart(restruct.newcart);
          setCart(restruct.cart);

          let man = {
            down: +restruct.newcart.TOTAL.DOWNPAYMENT,
            percent: +(
              (100 - +restruct.newcart.TOTAL.PROMO_PERCENT) /
              100
            ).toFixed(2)
          };
          setManagerValues(man);
          console.log(
            +((100 - +restruct.newcart.TOTAL.PROMO_PERCENT) / 100).toFixed(2)
          );
          console.log(restruct.newcart.TOTAL.DOWNPAYMENT);
          history.push("/checkout");
          setIsLoading(false);
        } else {
          console.log("boom");
          console.log(v);
          console.log({
            ...newCart,
            PROPOSAL_NAME: v.data.PROPOSAL_NAME,
            PROPOSAL_ID: v.data.PROPOSAL_ID
          });
          setNewCart({
            ...newCart,
            PROPOSAL_NAME: v.data.PROPOSAL_NAME,
            PROPOSAL_ID: v.data.PROPOSAL_ID
          });
          history.push("/pricer");
          setIsLoading(false);
        }
      });
  };
  const addProp = e => {
    e.preventDefault();

    setAddPropModal(true);
  };

  const close = e => {
    e.preventDefault();
    setAddPropModal(false);
  };
  const saveNum = e => {
    e.preventDefault();
    console.log(e.target.value.replace(/\D/g, ""));
    setOppNum(e.target.value.replace(/\D/g, ""));
  };
  const savePropNam = e => {
    e.preventDefault();
    setPropName(e.target.value.toUpperCase());
  };
  const onSearch = e => {
    e.preventDefault();
    console.log("working");
    console.log(oppNum);

    setNewCart({ ...newCart, OPP_NUM: oppNum });

    setIsLoading(true);
    //run axios search
    //if none found

    axios
      .post(
        `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_proposals`,
        {
          opp_num: `${oppNum}`
        }
      )
      .then(v => {
        setIsLoading(false);
        setProposals(v.data);
        setOppNum("");
      })
      .catch(e => {
        console.log("fail");
      });
  };
  const onSearchProp = e => {
    e.preventDefault();
    console.log("working");
    console.log(propName);
    setIsLoading(true);
    //run axios upload
    //if none found

    axios
      .post(
        `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/create_proposal`,
        {
          email: newCart.EMAIL,
          opp_num: `${newCart.OPP_NUM}`,
          prop_name: `${propName}`
        }
      )
      .then(v => {
        setIsLoading(false);
        setAddPropModal(false);
        setProposals(v.data.prop);
        setNewCart({
          ...newCart,
          PROPOSAL_ID: v.data.id,
          PROPOSAL_NAME: propName
        });
        setPropName("");
        history.push("/pricer");
      })
      .catch(e => {
        console.log("fail");
      });
  };
  useEffect(() => {
    const getWindows = () => {
      const windows = getWindowPricing();

      setWindowPricing(windows.data);
    };

    getWindows();
  }, []);

  return isLoading ? (
    <OtherLoader />
  ) : (
    <StyledBody show={show}>
      {typeof proposals === "string" ? (
        <StyledLookup>
          <div className="lookup">
            <div className="innercard">
              <span className="instruction">Enter Opportunity Number</span>
              <input
                className="oppinput"
                placeholder="opportunity number"
                onChange={saveNum}
                onKeyDown={e => e.keyCode === 13 && onSearch(e)}
              />
              <button onClick={onSearch} className="buttonStyle">
                SEARCH
              </button>
            </div>
          </div>
        </StyledLookup>
      ) : (
        <StyledLookup>
          <div className="mainproposal">
            <button
              title="add proposal"
              onClick={addProp}
              className="addbutton"
            >
              +
            </button>
            <div className="proposalcontainer">
              {proposals.map(v => {
                return (
                  <div
                    key={v.PROPOSAL_ID}
                    id={v.PROPOSAL_ID}
                    className="proposalcard"
                    onClick={proposalClick}
                  >
                    <div className="proptitle">
                      <span>{v.PROPOSAL_NAME}</span>
                      {v.DID_SUBMIT === 1 && (
                        <span className="checkcontain">
                          <Check />
                        </span>
                      )}
                    </div>

                    <div className="updated">
                      <span>Last Updated:</span>
                      <span className="timestamp">
                        {moment
                          .unix(v.LAST_UPDATED)
                          .format("MMMM Do YYYY, h:mm a")}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* addPropModal */}
          <DynamicModal width="50%" showModal={addPropModal} onClose={close}>
            <div className="addProp">
              <span>Enter new proposal name</span>
              <input
                placeholder="proposal name"
                onChange={savePropNam}
                onKeyDown={e => e.keyCode === 13 && onSearchProp(e)}
              />
              <div className="buttonContainer">
                <button onClick={close} className="buttonStyleCancel">
                  Cancel
                </button>
                <button className="buttonStyle" onClick={onSearchProp}>
                  Submit
                </button>
              </div>
            </div>
          </DynamicModal>
        </StyledLookup>
      )}
    </StyledBody>
  );
};

export default Lookup;
