import React, { useContext, useEffect, useState } from "react";
import { IconButton, AppBar, Popover, Typography } from "@material-ui/core";
import { ShoppingCart, Lock, LockOpen } from "@material-ui/icons";
import { Context } from "../App";
import { StyledHeader, StyledButton } from "../styles/styles";
import PinModal from "../components/PinModal/PinModal";
import logo from "../assets/MH_Logo.png";
import DynamicModal from "../components/DynamicModal/DynamicModal";
import { codeToName } from "../components/utils/helper";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import axios from "axios";
// import {
//   NotificationContainer,
//   NotificationManager
// } from "react-notifications";
// import "react-notifications/lib/notifications.css";

const Header = ({ history, accountInfo }) => {
  const {
    show,
    setShow,
    currWindows,
    isAdmin,
    setIsAdmin,
    online,
    showPin,
    setShowPin,
    setIsAdminPin,
    setProposals,
    proposals,
    newCart,
    setNewCart,
    cart,
    setCart,
    editModal,
    setEditModal,
    currentWindow,
    setCurrentWindow,
    managerValues,
    multipleSub,
    setMultipleSub,
    setIsReplaced,
    setPropIds,
    propIds,
    setQuickLoad
  } = useContext(Context);

  //see if reroute works?
  //reload page?
  useEffect(() => {
    //handle token
    !localStorage.getItem("msal.idtoken")
      ? window.location.reload()
      : console.log("logged in");
  });
  useEffect(() => {
    // online
    //   ? NotificationManager.success(
    //       `All quotes made offline will be sent out.`,
    //       "Found Service",
    //       2000
    //     )
    //   : NotificationManager.warning(
    //       `Don't worry, this app can work offline!`,
    //       "No Service",
    //       2000
    //     );

    console.log({
      ...newCart,
      EMAIL: accountInfo.account.userName.trim().toUpperCase()
    });
    setNewCart({
      ...newCart,
      EMAIL: accountInfo.account.userName.trim().toUpperCase()
    });
  }, []);
  const [clicked, setClicked] = useState(0);
  const [inputTxt, setInputTxt] = useState({
    name: "",
    quantity: 0,
    notes: ""
  });
  const [copy, setCopy] = useState(false);
  const closeEdit = e => {
    e.preventDefault();
    setCopy(false);
    setEditModal(false);
  };
  const onCloseSup = e => {
    e.preventDefault();
    setMultipleSub(false);
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const onCopyClick = e => {
    e.preventDefault();
    if (
      inputTxt.notes !== "" &&
      inputTxt.quantity !== 0 &&
      inputTxt.name !== ""
    ) {
      addToShoppingCart({
        ...currentWindow,
        name: inputTxt.name.toUpperCase(),
        PRODUCT_ID: uuidv4(),
        mts_Notes: inputTxt.notes,
        mts_Quantity: +inputTxt.quantity,
        mts_DemarcationLocation: inputTxt.name,
        price: +(
          (+currentWindow.price / currentWindow.mts_Quantity) *
          +inputTxt.quantity
        ).toFixed(2)
      });
      setInputTxt({
        name: "",
        quantity: 0,
        notes: ""
      });
      setCopy(false);
      setEditModal(false);
      setCurrentWindow({});
    } else {
      NotificationManager.error(
        "Make sure all fields are filled out!",
        "Missing Information",
        3000
      );
    }
  };

  const inputChange = e => {
    switch (e.target.id) {
      case "quantity":
        setInputTxt({ ...inputTxt, quantity: +e.target.value });
        break;
      default:
        setInputTxt({ ...inputTxt, [e.target.id]: e.target.value });
    }
  };

  const onEdit = e => {
    setQuickLoad(true);
    setTimeout(() => {
      history.push(`/pricer/${codeToName(currentWindow["mts_Style"])}`);
      setQuickLoad(false);
      setShow(false);
      setEditModal(false);
    }, 1000);
  };

  const onCopy = e => {
    setCopy(true);
  };

  const makeAdmin = e => {
    e.preventDefault();
    setIsAdminPin("admin");
    const state = isAdmin;
    isAdmin ? customerMode() : setShowPin(true);
  };
  const customerMode = () => {
    setIsAdmin(false);
    history.push("/checkout");
  };
  const replace = e => {
    e.preventDefault();

    let data = {
      proposal_ids: propIds.map(v => v.PROPOSAL_ID)
    };
    console.log(data);
    axios
      .post(
        "https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/replace",
        data
      )
      .then(v => {
        setIsReplaced(true);
        setMultipleSub(false);
      })
      .catch(e => {
        setMultipleSub(false);
      });
  };

  const onClose = e => {
    e.preventDefault();
    setShowPin(false);
  };

  const goBack = () => {
    setProposals("");
    history.push("/");
  };

  const oppNum = () => {
    setProposals("");
    setNewCart({
      ...newCart,
      OPP_NUM: "",
      PROPOSAL_NAME: "",
      PROPOSAL_ID: "",
      CART: []
    });
    setCart({});
    // localStorage.clear();
    localStorage.removeItem("cart");
    localStorage.removeItem("newcart");
    history.push("/");
  };

  const newProp = async () => {
    //get missing proposals
    let newProp = await axios.post(
      `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_proposals`,
      {
        opp_num: `${newCart.OPP_NUM}`
      }
    );

    setProposals(newProp.data);

    setNewCart({
      ...newCart,
      OPP_NUM: "",
      PROPOSAL_NAME: "",
      PROPOSAL_ID: "",
      CART: []
    });
    setCart({});
    // localStorage.clear();
    localStorage.removeItem("cart");
    localStorage.removeItem("newcart");
    history.push("/");
  };

  const windowPrice = () => {
    history.push("/pricer");
  };

  //price
  const addToShoppingCart = selected => {
    const gen_window = {
      MTS_SERIES: selected.mts_series.toUpperCase(),
      MTS_STYLE: selected.mts_Style.toUpperCase(),
      MTS_GLASS_TYPE: selected.mts_GlassType.toUpperCase(),
      MTS_COLOR: selected.mts_color.toUpperCase(),
      MTS_QUANTITY: selected.mts_Quantity,
      MTS_WIDTH: selected.mts_Width,
      MTS_HEIGHT: selected.mts_Height,
      MTS_LOCATION: selected.mts_DemarcationLocation.toUpperCase(),
      MTS_REMOVALOPTIONS: selected.mts_RemovalOptions.toUpperCase(),
      MTS_NOTES: selected.mts_Notes.toUpperCase(),
      MTS_GRIDCODE: selected.mts_gridtype.toUpperCase(),
      MTS_GRIDPATTERN: selected.mts_gridpattern,
      MTS_GRIDSTYLE: selected.tmpGridStyle.toUpperCase(),
      MTS_FULLGRIDPATTERN: selected.tmpGridPattern.toUpperCase(),
      MTS_SASHCOUNT: selected.mts_sashCount,
      //
      MTS_LITE_COUNT: selected.mts_Lite,
      MTS_OPERATINGLITE: selected.mts_OperatingLite,
      //doors
      MTS_DOORHEIGHT: selected.mts_DoorHeight,
      MTS_DOORGRID: selected.mts_DoorGrid,
      MTS_TRANSOMHEIGHT: selected.mts_TransomHeight,
      MTS_TRANSOMGRID: selected.mts_TransomGrid,
      MTS_SIDELITEHEIGHT: selected.mts_SideliteHeight,
      MTS_SIDELITEGRID: selected.mts_SideliteGrid
      //bow tilts
    };

    const other_options = {
      MTS_TRIM: selected.mts_Trim,
      MTS_OPTIONS: selected.mts_Options
    };

    let final = {
      PRODUCT_ID: selected.PRODUCT_ID,
      window_details: gen_window,
      options: other_options,
      price_breakdown: {},
      par_price: parseFloat(+selected.price),
      unit_par_price: parseFloat(+selected.price / selected.mts_Quantity),
      actual_price: parseFloat(
        +selected.price * managerValues.percent - managerValues.down
      ),
      actual_unit_price: parseFloat(
        (+selected.price / selected.mts_Quantity) * managerValues.percent -
          managerValues.down
      )
    };

    const updatedCart = newCart.CART.filter(
      v => v.PRODUCT_ID !== selected.PRODUCT_ID
    );

    setNewCart({ ...newCart, CART: [...updatedCart, final] });

    //filter out old aka delete old id key
    let tmpCart = cart;
    delete tmpCart[selected.PRODUCT_ID];
    let test = { ...tmpCart, [selected.PRODUCT_ID]: selected };
    setCart({ ...tmpCart, [selected.PRODUCT_ID]: selected });
    console.log("here is final cart");
    console.log({ ...tmpCart, [selected.PRODUCT_ID]: selected });
    console.log("here is final new cart");
    console.log({ ...newCart, CART: [...updatedCart, final] });
    localStorage.setItem("cart", JSON.stringify(test));
    localStorage.setItem(
      "newcart",
      JSON.stringify({ ...newCart, CART: [...updatedCart, final] })
    );
  };

  return (
    <StyledHeader>
      <AppBar className="navbar" style={{ display: "flex" }}>
        <img
          src={logo}
          style={{ touchAction: "manipulation" }}
          alt="momentum_logo"
        />

        <button className="opp" onClick={oppNum}>
          Opp Lookup
        </button>

        {newCart.OPP_NUM !== "" && newCart.PROPOSAL_NAME !== "" && (
          <button className="opp" onClick={newProp}>
            New Proposal
          </button>
        )}
        {newCart.OPP_NUM !== "" && newCart.PROPOSAL_NAME !== "" && (
          <button className="opp" onClick={windowPrice}>
            Window Pricer
          </button>
        )}
        {/* <IconButton className="Admin badge" onClick={makeAdmin}>
          {isAdmin ? (
            <Lock style={{ fontSize: "36px", color: "#001649" }} />
          ) : (
            <LockOpen style={{ fontSize: "36px", color: "#001649" }} />
          )}
        </IconButton> */}

        <IconButton
          className="menuButtonShop badge"
          data-badge={
            currWindows.reduce((array, curr) => array + curr.count, 0) || null
          }
          aria-label="Menu"
          onClick={() => {
            setShow(!show);
          }}
        >
          <ShoppingCart style={{ fontSize: "36px" }} />
        </IconButton>
      </AppBar>
      {/* <NotificationContainer /> */}
      <PinModal width={"100%"} onClose={onClose} history={history}>
        <div style={{ background: "black", width: "100%" }}>HIIIII</div>
      </PinModal>
      <DynamicModal
        width="50%"
        showModal={editModal}
        onClose={closeEdit}
        ignoreShow
      >
        {!copy ? (
          <div className="wrapedit">
            <span>
              Do you want to <b>edit</b> or <b>copy</b> the window?
            </span>
            <div className="buttonwrap">
              <StyledButton onClick={onEdit}>
                <b>EDIT</b>
              </StyledButton>
              <StyledButton onClick={onCopy}>
                <b>COPY</b>
              </StyledButton>
            </div>
          </div>
        ) : (
          <div className="outtercopy">
            <div className="copy">
              <input
                type="text"
                id="name"
                className="copyinput"
                placeholder="Window Name"
                required
                onChange={inputChange}
              />
              <input
                type="number"
                id="quantity"
                className="copyinput"
                placeholder="Quantity"
                min="1"
                onChange={inputChange}
                required
              />
              <TextField
                label="Notes"
                id="notes"
                type="text"
                multiline="true"
                rows="5"
                variant="outlined"
                required="true"
                style={{ background: "white" }}
                onChange={inputChange}
              />
            </div>
            <button className="buttonStyle" onClick={onCopyClick}>
              COPY
            </button>
          </div>
        )}
      </DynamicModal>
      <DynamicModal
        width="50%"
        showModal={multipleSub}
        onClose={onCloseSup}
        ignoreShow
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <span style={{ fontSize: "1.2em" }}>
            You already have a proposal submitted.
          </span>
          <span>
            Do you want to <strong>replace</strong> it?
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <button
            style={{
              background: "#ff4c4c",
              color: "white",
              padding: "1em",
              borderRadius: "5px",
              fontWeight: "bold",
              margin: "0.5em",
              border: "none",
              fontSize: "1em",
              boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)"
            }}
            onClick={onCloseSup}
          >
            CANCEL
          </button>
          <button
            style={{
              background: "#21283e",
              color: "white",
              padding: "1em",
              borderRadius: "5px",
              fontWeight: "bold",
              margin: "0.5em",
              border: "none",
              fontSize: "1em",
              boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)"
            }}
            onClick={replace}
          >
            REPLACE
          </button>
        </div>
      </DynamicModal>
    </StyledHeader>
  );
};

export default Header;
