export const upCharge = {
  //all windows up by 100
  dh: {
    white: { type: "single", price: 775 + 100 },
    almond: { type: "single", price: 775 * 1.15 + 100 },
    cocoa_white: { type: "single", price: 775 * 1.15 + 100 },
  },
  pw: {
    white: { type: "single", price: 975 + 100 },
    almond: { type: "single", price: 975 * 1.15 + 100 },
    cocoa_white: { type: "single", price: 975 * 1.15 + 100 },
  },
  sl: {
    white: { type: "single", price: 1022 + 100 },
    almond: { type: "single", price: 1022 * 1.15 + 100 },
    cocoa_white: { type: "single", price: 1022 * 1.15 + 100 },
  },
  sl3: {
    white: { type: "single", price: 1485 + 100 },
    almond: { type: "single", price: 1485 * 1.15 + 100 },
    cocoa_white: { type: "single", price: 1485 * 1.15 + 100 },
  },
  aw: {
    white: [
      { type: "single", price: 760 + 100 },
      { type: "single", price: 824 + 100 },
      { type: "single", price: 915 + 100 },
      { type: "single", price: 1230 + 100 },
    ],
    almond: [
      { type: "single", price: 760 * 1.15 + 100 },
      { type: "single", price: 824 * 1.15 + 100 },
      { type: "single", price: 915 * 1.15 + 100 },
      { type: "single", price: 1230 * 1.15 + 100 },
    ],
    cocoa_white: [
      { type: "single", price: 760 * 1.25 + 100 },
      { type: "single", price: 824 * 1.25 + 100 },
      { type: "single", price: 915 * 1.25 + 100 },
      { type: "single", price: 1230 * 1.25 + 100 },
    ],
  },
  cp: {
    white: [
      { type: "single", price: 760 + 100 },
      { type: "single", price: 824 + 100 },
      { type: "single", price: 915 + 100 },
      { type: "single", price: 1230 + 100 },
    ],
    almond: [
      { type: "single", price: 760 * 1.15 + 100 },
      { type: "single", price: 824 * 1.15 + 100 },
      { type: "single", price: 915 * 1.15 + 100 },
      { type: "single", price: 1230 * 1.15 + 100 },
    ],
    cocoa_white: [
      { type: "single", price: 760 * 1.25 + 100 },
      { type: "single", price: 824 * 1.25 + 100 },
      { type: "single", price: 915 * 1.25 + 100 },
      { type: "single", price: 1230 * 1.25 + 100 },
    ],
  },
  gardenbay: {
    white: [
      { type: "single", price: 995.14 + 100 * 3 + 100 },
      { type: "single", price: 1192.77 + 100 * 3 + 100 },
      { type: "single", price: 1386.52 * 3 + 100 },
      { type: "single", price: 1514.88 * 3 + 100 },
      //now bigger heights
      { type: "single", price: 1080.61 * 3 + 100 },
      { type: "single", price: 1281.32 * 3 + 100 },
      { type: "single", price: 1470.15 * 3 + 100 },
      { type: "single", price: 1623.95 * 3 + 100 },
    ],
    almond: [
      { type: "single", price: 995.14 * 3 + 100 },
      { type: "single", price: 1192.77 * 3 + 100 },
      { type: "single", price: 1386.52 * 3 + 100 },
      { type: "single", price: 1514.88 * 3 + 100 },
      //now bigger heights
      { type: "single", price: 1080.61 * 3 + 100 },
      { type: "single", price: 1281.32 * 3 + 100 },
      { type: "single", price: 1470.15 * 3 + 100 },
      { type: "single", price: 1623.95 * 3 + 100 },
    ],
    cocoa_white: [
      { type: "single", price: 995.14 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1192.77 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1386.52 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1514.88 * 3 + 353.57 * 1.2 + 100 },

      //now bigger heights
      { type: "single", price: 1281.32 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1080.61 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1470.15 * 3 + 353.57 * 1.2 + 100 },
      { type: "single", price: 1623.95 * 3 + 353.57 * 1.2 + 100 },
    ],
  },
  //upped by 500
  baybow: {
    three: [
      { type: "single", price: 597.53 * 4 + 500 },
      { type: "single", price: 661.33 * 4 + 500 },
      { type: "single", price: 766.86 * 4 + 500 },
      { type: "single", price: 845.38 * 4 + 500 },
      { type: "single", price: 688.34 * 4 + 500 },
      { type: "single", price: 717.77 * 4 + 500 },
      { type: "single", price: 833.11 * 4 + 500 },
      { type: "single", price: 938.64 * 4 + 500 },
    ],
    four: [
      { type: "single", price: 707.95 * 4 + 500 },
      { type: "single", price: 807.35 * 4 + 500 },
      { type: "single", price: 971.76 * 4 + 500 },
      { type: "single", price: 1082.19 * 4 + 500 },
      { type: "single", price: 738.63 * 4 + 500 },
      { type: "single", price: 893.24 * 4 + 500 },
      { type: "single", price: 1034.33 * 4 + 500 },
      { type: "single", price: 1152.12 * 4 + 500 },
    ],
    five: [
      { type: "single", price: 851.52 * 4 + 500 },
      { type: "single", price: 997.53 * 4 + 500 },
      { type: "single", price: 1157.03 * 4 + 500 },
      { type: "single", price: 1272.37 * 4 + 500 },
      { type: "single", price: 1127.6 * 4 + 500 },
      { type: "single", price: 1231.88 * 4 + 500 },
      { type: "single", price: 1422.04 * 4 + 500 },
      { type: "single", price: 1514.08 * 4 + 500 },
      { type: "single", price: 1186.48 * 4 + 500 },
      { type: "single", price: 1321.45 * 4 + 500 },
      { type: "single", price: 1489.54 * 4 + 500 },
      { type: "single", price: 1613.47 * 4 + 500 },
      { type: "single", price: 1231.88 * 4 + 500 },
      { type: "single", price: 1403.66 * 4 + 500 },
      { type: "single", price: 1572.98 * 4 + 500 },
      { type: "single", price: 1707.94 * 4 + 500 },
      { type: "single", price: 1321.45 * 4 + 500 },
      { type: "single", price: 1493.22 * 4 + 500 },
      { type: "single", price: 1660.09 * 4 + 500 },
      { type: "single", price: 1784.01 * 4 + 500 },
    ],
  },
  //upped by 500
  bay: {
    three: [
      { type: "single", price: 1422.04 * 4 + 500 },
      { type: "single", price: 1660.09 * 4 + 500 },
    ],
  },
  gsld: {
    white: {
      no_grids: [
        { type: "single", price: 677.92 * 4 + 100 },
        { type: "single", price: 697.98 * 4 + 100 },
        { type: "single", price: 697.98 * 4 + 100 },
        { type: "single", price: 891.48 * 5 + 100 },
        { type: "single", price: 1043.41 * 6 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 728.45 * 4 + 100 },
        { type: "single", price: 755.31 * 4 + 100 },
        { type: "single", price: 755.31 * 4 + 100 },
        { type: "single", price: 958.85 * 5 + 100 },
        { type: "single", price: 1129.41 * 6 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 728.45 * 4 + 100 },
        { type: "single", price: 755.31 * 4 + 100 },
        { type: "single", price: 755.31 * 4 + 100 },
        { type: "single", price: 958.85 * 5 + 100 },
        { type: "single", price: 1129.41 * 6 + 100 },
      ],
    },
    almond: {
      no_grids: [
        { type: "single", price: 744.71 * 4 + 100 },
        { type: "single", price: 766.78 * 4 + 100 },
        { type: "single", price: 766.78 * 4 + 100 },
        { type: "single", price: 979.63 * 5 + 100 },
        { type: "single", price: 1146.75 * 6 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 800.29 * 4 + 100 },
        { type: "single", price: 829.84 * 4 + 100 },
        { type: "single", price: 829.84 * 4 + 100 },
        { type: "single", price: 1053.73 * 5 + 100 },
        { type: "single", price: 1241.36 * 6 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 837.73 * 4 + 100 },
        { type: "single", price: 873.21 * 4 + 100 },
        { type: "single", price: 873.21 * 4 + 100 },
        { type: "single", price: 1106.55 * 5 + 100 },
        { type: "single", price: 1306.39 * 6 + 100 },
      ],
    },
    cocoa_white: {
      no_grids: [
        { type: "single", price: 1021.55 * 4 + 100 },
        { type: "single", price: 1048.43 * 4 + 100 },
        { type: "single", price: 1048.43 * 4 + 100 },
        { type: "single", price: 1310.01 * 5 + 100 },
        { type: "single", price: 1595.12 * 6 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 1102.55 * 4 + 100 },
        { type: "single", price: 1140.16 * 4 + 100 },
        { type: "single", price: 1140.16 * 4 + 100 },
        { type: "single", price: 1417.51 * 5 + 100 },
        { type: "single", price: 1673.33 * 6 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 1150.91 * 4 + 100 },
        { type: "single", price: 1194.62 * 4 + 100 },
        { type: "single", price: 1194.62 * 4 + 100 },
        { type: "single", price: 1482.0 * 5 + 100 },
        { type: "single", price: 1755.75 * 6 + 100 },
      ],
    },
    white_transoms: {
      no_grids: [
        { type: "single", price: 227.86 * 6 + 100 },
        { type: "single", price: 250.08 * 6 + 100 },
        { type: "single", price: 250.08 * 6 + 100 },
        { type: "single", price: 328.19 * 7 + 100 },
        { type: "single", price: 354.7 * 9 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 240.04 * 6 + 100 },
        { type: "single", price: 263.7 * 6 + 100 },
        { type: "single", price: 263.7 * 6 + 100 },
        { type: "single", price: 343.24 * 7 + 100 },
        { type: "single", price: 377.63 * 9 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 249.36 * 6 + 100 },
        { type: "single", price: 274.45 * 6 + 100 },
        { type: "single", price: 274.45 * 6 + 100 },
        { type: "single", price: 354.7 * 7 + 100 },
        { type: "single", price: 395.55 * 9 + 100 },
      ],
    },
    almond_transoms: {
      no_grids: [
        { type: "single", price: 249.64 * 6 + 100 },
        { type: "single", price: 274.09 * 6 + 100 },
        { type: "single", price: 274.09 * 6 + 100 },
        { type: "single", price: 360.02 * 7 + 100 },
        { type: "single", price: 389.18 * 9 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 263.05 * 6 + 100 },
        { type: "single", price: 289.06 * 6 + 100 },
        { type: "single", price: 289.06 * 6 + 100 },
        { type: "single", price: 376.58 * 7 + 100 },
        { type: "single", price: 414.4 * 9 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 273.31 * 6 + 100 },
        { type: "single", price: 300.9 * 6 + 100 },
        { type: "single", price: 300.9 * 6 + 100 },
        { type: "single", price: 389.19 * 7 + 100 },
        { type: "single", price: 434.11 * 9 + 100 },
      ],
    },
    cocoa_white_transoms: {
      no_grids: [
        { type: "single", price: 392.69 * 6 + 100 },
        { type: "single", price: 435.69 * 6 + 100 },
        { type: "single", price: 435.69 * 6 + 100 },
        { type: "single", price: 578.3 * 7 + 100 },
        { type: "single", price: 625.6 * 9 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 412.76 * 6 + 100 },
        { type: "single", price: 457.19 * 6 + 100 },
        { type: "single", price: 457.19 * 6 + 100 },
        { type: "single", price: 601.96 * 7 + 100 },
        { type: "single", price: 662.15 * 9 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 423.88 * 6 + 100 },
        { type: "single", price: 470.09 * 6 + 100 },
        { type: "single", price: 470.09 * 6 + 100 },
        { type: "single", price: 615.93 * 7 + 100 },
        { type: "single", price: 683.65 * 9 + 100 },
      ],
    },
    white_sidelites: {
      no_grids: [
        { type: "single", price: 275.16 * 4 + 100 },
        { type: "single", price: 361.16 * 4 + 100 },
        { type: "single", price: 447.15 * 4 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 292.36 * 4 + 100 },
        { type: "single", price: 384.82 * 4 + 100 },
        { type: "single", price: 479.4 * 4 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 305.27 * 4 + 100 },
        { type: "single", price: 403.45 * 4 + 100 },
        { type: "single", price: 505.21 * 4 + 100 },
      ],
    },
    almond_sidelites: {
      no_grids: [
        { type: "single", price: 301.67 * 4 + 100 },
        { type: "single", price: 396.27 * 4 + 100 },
        { type: "single", price: 490.87 * 4 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 320.59 * 4 + 100 },
        { type: "single", price: 422.28 * 4 + 100 },
        { type: "single", price: 526.34 * 4 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 334.78 * 4 + 100 },
        { type: "single", price: 442.79 * 4 + 100 },
        { type: "single", price: 554.72 * 4 + 100 },
      ],
    },
    cocoa_white_sidelites: {
      no_grids: [
        { type: "single", price: 490.15 * 4 + 100 },
        { type: "single", price: 637.78 * 4 + 100 },
        { type: "single", price: 782.54 * 4 + 100 },
      ],
      flat_grids: [
        { type: "single", price: 518.11 * 4 + 100 },
        { type: "single", price: 676.13 * 4 + 100 },
        { type: "single", price: 835.58 * 4 + 100 },
      ],
      contour_grids: [
        { type: "single", price: 534.23 * 4 + 100 },
        { type: "single", price: 699.07 * 4 + 100 },
        { type: "single", price: 867.46 * 4 + 100 },
      ],
    },
  },
  cs: {
    operating: {
      white: [
        { type: "single", price: 804.0 + 100 },
        { type: "single", price: 804.0 + 100 },
        { type: "single", price: 804.0 + 100 },
        { type: "single", price: 1240.0 + 100 },
      ],
      almond: [
        { type: "single", price: 804.0 * 1.15 + 100 },
        { type: "single", price: 804.0 * 1.15 + 100 },
        { type: "single", price: 804.0 * 1.15 + 100 },
        { type: "single", price: 1240.0 * 1.15 + 100 },
      ],
      cocoa_white: [
        { type: "single", price: 804.0 * 1.25 + 100 },
        { type: "single", price: 804.0 * 1.25 + 100 },
        { type: "single", price: 804.0 * 1.25 + 100 },
        { type: "single", price: 1240.0 * 1.25 + 100 },
      ],
    },
    cpw: {
      white: [
        { type: "single", price: 804.0 + 100 },
        { type: "single", price: 804.0 + 100 },
        { type: "single", price: 1301.0 + 100 },
      ],
      almond: [
        { type: "single", price: 804.0 * 1.15 + 100 },
        { type: "single", price: 804.0 * 1.15 + 100 },
        { type: "single", price: 1301.0 * 1.15 + 100 },
      ],
      cocoa_white: [
        { type: "single", price: 804.0 * 1.25 + 100 },
        { type: "single", price: 804.0 * 1.25 + 100 },
        { type: "single", price: 1301.0 * 1.25 + 100 },
      ],
    },
  },
};
