import React, { useContext, useEffect, useState } from "react";
import CustomModal from "../components/CustomModal/CustomModal";
import MoreCustomModal from "../components/MoreCustomModal/MoreCustomModal";
import { Context } from "../App";
import { StyledButton } from "../styles/styles";
import Dexie from "dexie";
import Success from "../components/Success/Success";
import {
  numberWithCommas,
  codeToName,
  currency
} from "../components/utils/helper";
import { FormCard } from "../styles/styles";
import axios from "axios";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Input
} from "@material-ui/core";
import Axios from "axios";
import DynamicModal from "../components/DynamicModal/DynamicModal";
const { readCart } = require("../Helper/API");

const Checkout = ({ history }) => {
  const {
    cart,
    totalPrice,
    setTotalPrice,
    setCart,
    syncIt,
    setShowPin,
    setIsAdmin,
    setIsAdminPin,
    showSave,
    setShowSave,
    showAdd,
    setShowAdd,
    managerValues,
    setManagerValues,
    setCurrentWindow,
    isAdmin,
    newCart,
    setNewCart,
    setProposals,
    setMultipleSub,
    isReplaced,
    setIsReplaced,
    setPropIds
  } = useContext(Context);

  const [customerFirstName, setCustomerFirstName] = useState("");
  const [mh_num, setMHNum] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [clickedWindow, setClickedWindow] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [partial, setShowPartial] = useState(false);
  const [updatedCart, setUpdatedCart] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(totalPrice);
  });

  useEffect(() => {
    isReplaced && submitClick();
  }, [isReplaced]);

  useEffect(() => {
    const final = Object.keys(cart).reduce(
      (acum, curr) => acum + +cart[curr].price,
      0
    );
    setTotalPrice(final);
  }, [cart]);

  const partialToggle = e => {
    let cartVal = updatedCart.CART;

    cartVal = e.target.checked
      ? [
          ...cartVal,
          ...newCart.CART.filter(v => v.PRODUCT_ID === e.currentTarget.id)
        ]
      : [...cartVal.filter(v => v.PRODUCT_ID !== e.currentTarget.id)];

    console.log("here is toggle");

    console.log(cartVal);
    setUpdatedCart({ ...updatedCart, CART: cartVal });
  };

  const partialHandler = e => {
    e.preventDefault();
    setIsLoading(true);
    console.log("here is newCart");
    console.log(newCart);
    console.log("here is updated cart");
    console.log(updatedCart);
    console.log("here is cart");
    console.log(cart);

    console.log("here is fixed new cart");
    let brandNewCart = submitActions(newCart);
    console.log(brandNewCart);
    console.log("here is fixed partial cart");
    const partial = submitActions(updatedCart);
    console.log(partial);

    //post newCart windows
    let promises = [];
    promises.push(
      axios.post(
        `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/insert_windows`,
        {
          cart: [brandNewCart]
        }
      )
    );

    promises.push(
      //this needs to add into that other table as well
      axios.post(
        `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/insert_partial_prop`,
        {
          prop_name: partial.PROPOSAL_NAME,
          opp_num: partial.OPP_NUM,
          email: partial.EMAIL,
          prop_id: partial.PROPOSAL_ID,
          main_prop_id: brandNewCart.PROPOSAL_ID
        }
      )
    );

    axios.all(promises).then(res => {
      axios
        .post(
          `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/insert_windows`,
          {
            cart: [partial]
          }
        )
        .then(res => {
          localStorage.removeItem("cart");
          localStorage.removeItem("newcart");
          setManagerValues({ down: 0, percent: 1 });

          //do some lookup
          axios
            .post(
              "https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_cart",
              {
                PROPOSAL_ID: partial.PROPOSAL_ID
              }
            )
            .then(v => {
              if (v.data.length !== 0 && v.data.length) {
                let restruct = readCart(v.data);
                restruct.newcart.EMAIL = newCart.EMAIL;
                setNewCart(restruct.newcart);
                setCart(restruct.cart);

                let man = {
                  down: +restruct.newcart.TOTAL.DOWNPAYMENT,
                  percent: +(
                    (100 - +restruct.newcart.TOTAL.PROMO_PERCENT) /
                    100
                  ).toFixed(2)
                };
                setManagerValues(man);
                console.log(
                  +(
                    (100 - +restruct.newcart.TOTAL.PROMO_PERCENT) /
                    100
                  ).toFixed(2)
                );
                console.log(restruct.newcart.TOTAL.DOWNPAYMENT);
                history.push("/checkout");
                setShowPartial(false);
                setIsLoading(false);
              } else {
                console.log("boom");
                console.log(v);
                console.log({
                  ...newCart,
                  PROPOSAL_NAME: v.data.PROPOSAL_NAME,
                  PROPOSAL_ID: v.data.PROPOSAL_ID
                });
                setNewCart({
                  ...newCart,
                  PROPOSAL_NAME: v.data.PROPOSAL_NAME,
                  PROPOSAL_ID: v.data.PROPOSAL_ID
                });
                history.push("/pricer");
                setShowPartial(false);
                setIsLoading(false);
              }
            });
        });
    });

    //partial order

    //after done do this
  };
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const openPartial = e => {
    e.preventDefault();

    console.warn(newCart);

    //set the updated cart
    //change the name and proposal ID
    setUpdatedCart({
      ...newCart,
      PROPOSAL_ID: uuidv4(),
      PROPOSAL_NAME: `${newCart.PROPOSAL_NAME}_partial`,
      CART: []
    });
    setShowPartial(true);
  };

  const closePartial = e => {
    e.preventDefault();
    setUpdatedCart({});
    setShowPartial(false);
  };

  const onSave = e => {
    e.preventDefault();
    setIsAdminPin("save");

    setShowPin(true);
  };

  const closeEditModal = e => {
    e.preventDefault();
    setShowEditModal(false);
  };

  const editWindow = e => {
    console.log("editing Window");

    console.log(cart[clickedWindow]);
    setCurrentWindow(cart[clickedWindow]);
    history.push(`/pricer/${codeToName(cart[clickedWindow]["mts_Style"])}`);
    setClickedWindow("");
    setShowEditModal(false);
  };

  const deleteWindow = e => {
    let tmp = cart;
    console.log("here");
    console.log(clickedWindow);
    console.log(tmp[clickedWindow]);
    const price = tmp[clickedWindow].price;
    delete tmp[clickedWindow];

    //get rid of new cart

    // let updatedCart =
    const fixNewCart = {
      ...newCart,
      CART: newCart.CART.filter(i => i.PRODUCT_ID !== clickedWindow),
      TOTAL: {
        ...newCart.TOTAL,
        // TOTAL_WINDOWS: newCart.TOTAL.TOTAL_WINDOWS - 1,
        PAR_TOTAL_PRICE: +(totalPrice - price).toFixed(2),
        ACTUAL_TOTAL_PRICE: +(
          (totalPrice - price) *
          managerValues.percent
        ).toFixed(2)
      }
    };
    setNewCart(fixNewCart);
    console.log("here is old new cart");
    console.log(newCart);
    console.log("here it is");

    console.log(fixNewCart);

    setCart({ ...tmp });
    if (Object.keys(tmp).length === 0) {
      setManagerValues({ down: 0, percent: 1 });
      history.push("/");
    }
    localStorage.setItem("cart", JSON.stringify(tmp));
    localStorage.setItem("newcart", JSON.stringify(fixNewCart));
    //update local storage
    //this is actual price
    console.log(totalPrice - price);
    setTotalPrice(totalPrice - price);
    setClickedWindow("");
    setShowEditModal(false);
  };
  const modalStyle = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    table: {
      width: "35vw",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23)"
    },
    heading: {
      marginBottom: "1em"
    },
    sideHeader: {
      color: "white",
      backgroundColor: "#001d49",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      width: "10%",
      border: "0px",
      textAlign: "center"
    },
    normal: {
      whiteSpace: "nowrap"
    }
  };

  const promoClick = e => {
    setIsAdmin(true);
    history.push("/checkoutadmin");
  };

  const onAdd = e => {
    e.preventDefault();
    setIsAdminPin("add");
    setShowPin(true);
  };
  const onDone = e => {
    e.preventDefault();
    alert("boom");
    setShowAdd(false);
    setShowSave(false);
  };
  const setVal = e => {
    e.preventDefault();
    const tmp = e.target.value;
    switch (e.target.id) {
      case "mh_num":
        setMHNum(tmp);
        break;
      case "first_name":
        setCustomerFirstName(tmp);

        break;
      case "last_name":
        setCustomerLastName(tmp);
        break;
      default:
        break;
    }
  };

  const saveClick = e => {
    e.preventDefault();
    setIsLoading(true);
    let tmp = {};
    const quantity = Object.keys(cart).reduce((total, currVal) => {
      return total + cart[currVal].mts_Quantity;
    }, 0);
    const updatedCart = {};
    Object.keys(cart).forEach(v => {
      updatedCart[v] = cart[v];
      updatedCart[v].one_year_total = (+cart[v].price * 1.12).toFixed(2);
      updatedCart[v].one_year_per = (+cart[v].price * 1.12 * 0.029).toFixed(2);
      updatedCart[v].promo_total = (+cart[v].price).toFixed(2);
      updatedCart[v].promo_per = (+cart[v].price * 0.0151).toFixed(2);
    });

    tmp["mh_num"] = mh_num;
    tmp["first_name"] = customerFirstName;
    tmp["last_name"] = customerLastName;
    tmp["windows"] = updatedCart;
    tmp["date"] = Date.now();
    tmp["total"] = {
      original_promo: +totalPrice.toFixed(2),
      original_promo_per: +(+totalPrice * 0.0151).toFixed(2),
      downpayment: +managerValues.down,
      percent_promo: (1 - +managerValues.percent) * 100,
      quantity: quantity,
      one_year_total: +(+totalPrice * 1.12).toFixed(2),
      one_year_per: +(+totalPrice * 1.12 * 0.029).toFixed(2),
      promo_total: +(
        +totalPrice * managerValues.percent -
        managerValues.down
      ).toFixed(2),
      promo_per: +(
        (+totalPrice * managerValues.percent - managerValues.down) *
        0.0151
      ).toFixed(2)
    };
    tmp["submitted"] = false;
    const updatedNewCart = [];
    //update submitted object
    newCart.CART.forEach(v => {
      updatedNewCart.push({
        ...v,
        actual_price: v.par_price * managerValues.percent,
        actual_unit_price:
          (v.par_price * managerValues.percent) / v.window_details.MTS_QUANTITY
      });
    });

    const orderDetails = {
      DOWNPAYMENT: +managerValues.down,
      PROMO_PERCENT: +((1 - +managerValues.percent) * 100).toFixed(0),
      TOTAL_WINDOWS: quantity,
      PAR_TOTAL_PRICE: +totalPrice.toFixed(2),
      ACTUAL_TOTAL_PRICE: +(
        +totalPrice * managerValues.percent -
        managerValues.down
      ).toFixed(2)
    };
    setNewCart({
      ...newCart,
      CART: updatedNewCart,
      TOTAL: orderDetails,
      DID_SUBMIT: 0
    });
    console.log("fixed");
    const tmpNewCart = {
      ...newCart,
      CART: updatedNewCart,
      TOTAL: orderDetails,
      DID_SUBMIT: 0
    };
    console.log({ ...newCart, CART: updatedNewCart, TOTAL: orderDetails });
    setCart({});
    localStorage.removeItem("cart");
    localStorage.removeItem("newcart");
    setManagerValues({ down: 0, percent: 1 });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration("../App").then(registration => {
        saveData(tmpNewCart)
          .then(() => {
            if (registration && registration.sync) {
              console.log("added to db");
              registration.sync.register("back-sync").catch(function(err) {
                return err;
              });
            } else {
              if (navigator.onLine) {
                //run axios call
                syncIt();
              } else {
                console.log(
                  "You are offline and your quote will be sent whenever you have service."
                );
              }
            }
          })
          .then(() => {
            axios
              .post(
                `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/get_proposals`,
                {
                  opp_num: `${newCart.OPP_NUM}`
                }
              )
              .then(v => {
                console.log("aftermarket");
                console.log(v.data);
                setProposals(v.data);
                setTimeout(() => {
                  setIsLoading(false);
                  setIsAdmin(false);
                  setIsReplaced(false);

                  setNewCart({
                    ...newCart,
                    PROPOSAL_NAME: "",
                    PROPOSAL_ID: "",
                    CART: []
                  });
                  setCart({});
                  localStorage.removeItem("cart");
                  localStorage.removeItem("newcart");

                  history.push("/");
                }, 2000);
              });
          });
      });
    } else {
      console.log("not valid sw");
    }
  };

  const initialSubmitClick = e => {
    e.preventDefault();

    axios
      .post(
        `https://3la7os45f5.execute-api.us-east-1.amazonaws.com/latest/is_submitted`,
        { opp_num: newCart.OPP_NUM }
      )
      .then(v => {
        console.log(v.data);
        if (v.data.length > 0) {
          setMultipleSub(true);
          setPropIds(v.data);
        } else {
          submitClick(e);
        }
      })
      .catch(e => {
        alert(e);

        // alert("broke");
      });
  };

  //working
  const submitActions = passedCart => {
    let tmp = {};
    // const quantity = Object.keys(cart).reduce((total, currVal) => {
    //   return total + cart[currVal].mts_Quantity;
    // }, 0);
    const quantity = passedCart.CART.reduce((total, currVal) => {
      return total + currVal.window_details.MTS_QUANTITY;
    }, 0);
    const newTotal = passedCart.CART.reduce((priceTotal, e) => {
      return priceTotal + e.par_price;
    }, 0);

    const updatedCart = {};

    Object.keys(cart).forEach(v => {
      if (passedCart.CART.find(i => i.PRODUCT_ID === v)) {
        updatedCart[v] = cart[v];
        updatedCart[v].one_year_total = (+cart[v].price * 1.12).toFixed(2);
        updatedCart[v].one_year_per = (+cart[v].price * 1.12 * 0.029).toFixed(
          2
        );
        updatedCart[v].promo_total = (+cart[v].price).toFixed(2);
        updatedCart[v].promo_per = (+cart[v].price * 0.0151).toFixed(2);
      }
    });

    const updatedNewCart = [];
    //update submitted object
    passedCart.CART.forEach(v => {
      updatedNewCart.push({
        ...v,
        actual_price: v.par_price * managerValues.percent,
        actual_unit_price:
          (v.par_price * managerValues.percent) / v.window_details.MTS_QUANTITY
      });
    });

    const orderDetails = {
      DOWNPAYMENT: +managerValues.down,
      PROMO_PERCENT: +((1 - +managerValues.percent) * 100).toFixed(0),
      TOTAL_WINDOWS: quantity,
      PAR_TOTAL_PRICE: +newTotal.toFixed(2),
      ACTUAL_TOTAL_PRICE: +(+newTotal * managerValues.percent).toFixed(2)
    };

    return {
      ...passedCart,
      CART: updatedNewCart,
      TOTAL: orderDetails,
      DID_SUBMIT: 0
    };

    // setCart({});

    //couple cart and new cart
    // localStorage.removeItem("cart");
    // localStorage.removeItem("newcart");
    // setManagerValues({ down: 0, percent: 1 });

    //do post after transformation
  };

  const submitClick = e => {
    e && e.preventDefault();
    setIsLoading(true);

    console.log(newCart);
    console.log("here is cart");
    console.log(cart);
    let tmp = {};
    const quantity = Object.keys(cart).reduce((total, currVal) => {
      return total + cart[currVal].mts_Quantity;
    }, 0);

    const updatedCart = {};
    Object.keys(cart).forEach(v => {
      updatedCart[v] = cart[v];
      updatedCart[v].one_year_total = (+cart[v].price * 1.12).toFixed(2);
      updatedCart[v].one_year_per = (+cart[v].price * 1.12 * 0.029).toFixed(2);
      updatedCart[v].promo_total = (+cart[v].price).toFixed(2);
      updatedCart[v].promo_per = (+cart[v].price * 0.0151).toFixed(2);
    });

    tmp["mh_num"] = mh_num;
    tmp["first_name"] = customerFirstName;
    tmp["last_name"] = customerLastName;
    tmp["windows"] = updatedCart;
    tmp["date"] = Date.now();
    tmp["total"] = {
      original_promo: +totalPrice.toFixed(2),
      original_promo_per: (+totalPrice * 0.0151).toFixed(2),
      downpayment: +managerValues.down,
      percent_promo: (1 - +managerValues.percent) * 100,
      quantity: quantity,
      one_year_total: +(+totalPrice * 1.12).toFixed(2),
      one_year_per: +(+totalPrice * 1.12 * 0.029).toFixed(2),
      promo_total: +(
        +totalPrice * managerValues.percent -
        managerValues.down
      ).toFixed(2),
      promo_per: +(
        (+totalPrice * managerValues.percent - managerValues.down) *
        0.0151
      ).toFixed(2)
    };

    const updatedNewCart = [];
    //update submitted object
    newCart.CART.forEach(v => {
      updatedNewCart.push({
        ...v,
        actual_price: v.par_price * managerValues.percent,
        actual_unit_price:
          (v.par_price * managerValues.percent) / v.window_details.MTS_QUANTITY
      });
      console.log("here is par price");
      console.log(v.par_price);
      console.log(managerValues.percent);
      console.log(v.window_details);
    });

    const orderDetails = {
      DOWNPAYMENT: +managerValues.down,
      PROMO_PERCENT: +((1 - +managerValues.percent) * 100).toFixed(0),
      TOTAL_WINDOWS: quantity,
      PAR_TOTAL_PRICE: +totalPrice.toFixed(2),
      ACTUAL_TOTAL_PRICE: +(+totalPrice * managerValues.percent).toFixed(2)
    };
    setNewCart({
      ...newCart,
      CART: updatedNewCart,
      TOTAL: orderDetails,
      DID_SUBMIT: 1
    });
    console.log("fixed");
    const tmpNewCart = {
      ...newCart,
      CART: updatedNewCart,
      TOTAL: orderDetails,
      DID_SUBMIT: 1
    };
    console.log({ ...newCart, CART: updatedNewCart, TOTAL: orderDetails });
    tmp["submitted"] = true;
    console.log("here is total");
    console.log(tmp["total"]);
    setCart({});
    console.log("here is new cart");
    console.log(newCart);
    //couple cart and new cart
    localStorage.removeItem("cart");
    localStorage.removeItem("newcart");
    setManagerValues({ down: 0, percent: 1 });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration("../App").then(registration => {
        console.log("saving in dex");
        console.log(tmpNewCart);
        saveData(tmpNewCart)
          .then(() => {
            if (registration && registration.sync) {
              console.log("added to db");
              registration.sync.register("back-sync").catch(function(err) {
                return err;
              });
            } else {
              if (navigator.onLine) {
                //run axios call
                syncIt();
              } else {
                console.log(
                  "You are offline and your quote will be sent whenever you have service."
                );
              }
            }
          })
          .then(() => {
            setShowAdd(false);

            setTimeout(() => {
              setIsLoading(false);
              setIsAdmin(false);
              setIsReplaced(false);

              setNewCart({
                ...newCart,
                PROPOSAL_NAME: "",
                PROPOSAL_ID: "",
                CART: []
              });
              setCart({});
              localStorage.removeItem("cart");
              localStorage.removeItem("newcart");

              history.push("/");
            }, 2000);
          });
      });
    } else {
      console.log("not valid sw");
    }
  };

  function saveData(finalCart) {
    console.log("here is final cart in saveData");
    console.log(finalCart);
    return new Promise(function(resolve, reject) {
      var db = new Dexie("savedCart");
      db.version(2).stores({ savedCartObj: "PROPOSAL_ID" });

      db.savedCartObj
        .add(finalCart)
        .then(() => {
          db.close();
          resolve();
        })
        .catch(e => {
          console.log(e);
          reject();
        });
    });
  }

  //@TO DO
  const editDeleteModal = e => {
    e.preventDefault();
    console.log("clicked");
    console.log(cart);
    console.log(e.currentTarget.id);
    console.log(cart[e.currentTarget.id]);
    setClickedWindow(e.currentTarget.id);
    setShowEditModal(true);
  };

  return !isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Table
        style={{
          width: "90%",
          marginTop: "1em",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
        }}
      >
        <TableHead style={{ background: "#001649", color: "white" }}>
          <TableRow>
            {/* <TableCell style={{ color: "white" }} align="center">
                <b>Submit</b>
              </TableCell> */}
            <TableCell style={{ color: "white" }} align="center">
              <b>Window</b>
            </TableCell>
            <TableCell style={{ color: "white" }} align="center">
              <b>Quantity</b>
            </TableCell>
            <TableCell style={{ color: "white" }} align="center">
              <b>One Year/per</b>
            </TableCell>
            <TableCell style={{ color: "white" }} align="center">
              <b>One Year Total</b>
            </TableCell>
            <TableCell style={{ color: "white" }} align="center">
              <b>Promo/per</b>
            </TableCell>
            <TableCell style={{ color: "white" }} align="center">
              <b>Promo Total</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(cart).map((v, i) => {
            return (
              <TableRow
                key={i}
                id={v}
                onClick={editDeleteModal}
                hover="true"
                style={{ cursor: "pointer" }}
              >
                {/* <TableCell align="center">
                    <Toggle />
                  </TableCell> */}
                <TableCell id={cart[v].name} align="center">
                  {cart[v].name}
                </TableCell>
                <TableCell
                  id={cart[v].name}
                  align="center"
                >{`${cart[v].mts_Quantity}`}</TableCell>
                {/* <TableCell
                  id={cart[v].name}
                  align="center"
                >{`$${numberWithCommas(
                  (
                    (+cart[v].price - managerValues.down) *
                    1.12 *
                    0.029
                  ).toFixed(2)
                )}`}</TableCell> */}
                {i === 0 ? (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    (
                      (+cart[v].price - managerValues.down) *
                      1.12 *
                      0.029
                    ).toFixed(2)
                  )}`}</TableCell>
                ) : (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    (+cart[v].price * 1.12 * 0.029).toFixed(2)
                  )}`}</TableCell>
                )}
                {i === 0 ? (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    ((+cart[v].price - managerValues.down) * 1.12).toFixed(2)
                  )}`}</TableCell>
                ) : (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    (+cart[v].price * 1.12).toFixed(2)
                  )}`}</TableCell>
                )}
                {i === 0 ? (
                  <TableCell id={cart[v].name} align="center">
                    {`$${numberWithCommas(
                      (
                        (+cart[v].price * managerValues.percent -
                          managerValues.down) *
                        0.0151
                      ).toFixed(2)
                    )}`}
                  </TableCell>
                ) : (
                  <TableCell id={cart[v].name} align="center">
                    {`$${numberWithCommas(
                      (+cart[v].price * managerValues.percent * 0.0151).toFixed(
                        2
                      )
                    )}`}
                  </TableCell>
                )}
                {i === 0 ? (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    (
                      +cart[v].price * managerValues.percent -
                      managerValues.down
                    ).toFixed(2)
                  )}`}</TableCell>
                ) : (
                  <TableCell
                    id={cart[v].name}
                    align="center"
                  >{`$${numberWithCommas(
                    (+cart[v].price * managerValues.percent).toFixed(2)
                  )}`}</TableCell>
                )}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="center">
              <b>TOTAL</b>
            </TableCell>
            <TableCell align="center">
              <b>
                {Object.keys(cart).reduce((total, currVal) => {
                  return total + cart[currVal].mts_Quantity;
                }, 0)}
              </b>
            </TableCell>

            <TableCell align="center">
              <b>{`$${numberWithCommas(
                ((+totalPrice - managerValues.down) * 1.12 * 0.029).toFixed(2)
              )}`}</b>
            </TableCell>

            <TableCell align="center">
              <b>{`$${numberWithCommas(
                ((+totalPrice - managerValues.down) * 1.12).toFixed(2)
              )}`}</b>
            </TableCell>
            <TableCell align="center" style={{ color: "green" }}>
              <b>{`$${numberWithCommas(
                (
                  (+totalPrice * managerValues.percent - managerValues.down) *
                  0.0151
                ).toFixed(2)
              )}`}</b>
            </TableCell>
            <TableCell align="center" style={{ color: "green" }}>
              <b>{`$${numberWithCommas(
                (
                  +totalPrice * managerValues.percent -
                  managerValues.down
                ).toFixed(2)
              )}`}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div style={{ display: "flex" }}>
        <StyledButton onClick={initialSubmitClick}>
          <b>SUBMIT</b>
        </StyledButton>
        <StyledButton onClick={saveClick}>
          <b>SAVE</b>
        </StyledButton>
        <StyledButton onClick={openPartial}>
          <b>PARTIAL ORDER</b>
        </StyledButton>
        <StyledButton onClick={promoClick}>
          <b>PROMO</b>
        </StyledButton>
      </div>

      <CustomModal
        show={showAdd}
        onClose={submitClick}
        value="SUBMIT"
        submit={onDone}
      >
        <FormCard
          show="false"
          style={{
            height: "350px",
            border: "solid",
            display: "flex",
            flexDirection: "column",
            width: "65%",
            transform: "none"
          }}
        >
          <h1 style={{ alignSelf: "center" }}>Customer Information</h1>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">MH NUM</InputLabel>
            <Input
              id="mh_num"
              placeholder="MH NUM"
              value={mh_num}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">First Name</InputLabel>
            <Input
              id="first_name"
              placeholder="First Name"
              value={customerFirstName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">Last Name</InputLabel>
            <Input
              id="last_name"
              placeholder="Last Name"
              value={customerLastName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
        </FormCard>
      </CustomModal>
      <CustomModal
        show={showSave}
        onClose={saveClick}
        value="SAVE"
        submit={onDone}
      >
        <FormCard
          show="false"
          style={{
            height: "350px",
            border: "solid",
            display: "flex",
            flexDirection: "column",
            width: "65%",
            transform: "none"
          }}
        >
          <h1 style={{ alignSelf: "center" }}>Customer Information</h1>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">MH NUM</InputLabel>
            <Input
              id="mh_num"
              placeholder="MH NUM"
              value={mh_num}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">First Name</InputLabel>
            <Input
              id="first_name"
              placeholder="First Name"
              value={customerFirstName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">Last Name</InputLabel>
            <Input
              id="last_name"
              placeholder="Last Name"
              value={customerLastName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
        </FormCard>
      </CustomModal>
      {/* onClose={onCloseSup}
        ignoreShow */}

      {/* @todo */}

      <DynamicModal width="80%" showModal={partial} onClose={closePartial}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Table
            style={{
              width: "90%",
              marginTop: "1em",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
            }}
          >
            <TableHead style={{ background: "#001649", color: "white" }}>
              <TableRow>
                {/* <TableCell style={{ color: "white" }} align="center">
                <b>Submit</b>
              </TableCell> */}
                <TableCell style={{ color: "white" }} align="center">
                  <b>Select Window</b>
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  <b>Type</b>
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  <b>Qty</b>
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  <b>Price</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newCart.CART.map(v => {
                return (
                  <TableRow id={v.PRODUCT_ID}>
                    <TableCell id={v.PRODUCT_ID} align="center">
                      <input
                        type="checkbox"
                        id={v.PRODUCT_ID}
                        onChange={partialToggle}
                      />
                    </TableCell>
                    <TableCell id={v.PRODUCT_ID} align="center">
                      {v.window_details.MTS_STYLE}
                    </TableCell>
                    <TableCell id={v.PRODUCT_ID} align="center">
                      {v.window_details.MTS_LOCATION}
                    </TableCell>
                    <TableCell id={v.PRODUCT_ID} align="center">
                      {v.window_details.MTS_QUANTITY}
                    </TableCell>
                    <TableCell id={v.PRODUCT_ID} align="center">
                      {currency.format(v.par_price)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                background: "#ff4c4c",
                color: "white",
                padding: "1em",
                borderRadius: "5px",
                fontWeight: "bold",
                margin: "0.5em",
                border: "none",
                fontSize: "1em",
                boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)"
              }}
              onClick={closePartial}
            >
              CANCEL
            </button>
            {updatedCart && updatedCart.CART && updatedCart.CART.length > 0 && (
              <button
                onClick={partialHandler}
                style={{
                  background: "#21283e",
                  color: "white",
                  padding: "1em",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  margin: "0.5em",
                  border: "none",
                  fontSize: "1em",
                  boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)"
                }}
              >
                SUBMIT
              </button>
            )}
          </div>
        </div>
      </DynamicModal>

      <MoreCustomModal
        show={showEditModal}
        onClose={closeEditModal}
        buttonOne={deleteWindow}
        buttonOneText="DELETE"
        buttonTwo={editWindow}
        buttonTwoText="EDIT"
      >
        <div style={modalStyle.container}>
          <span style={modalStyle.heading}>
            Do You Want to <b>EDIT</b> or <b>DELETE</b> this window?
          </span>

          {cart[clickedWindow] && (
            <div style={{ display: "flex" }}>
              <Table style={modalStyle.table}>
                <TableBody>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Name</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_DemarcationLocation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>
                      Quantity
                    </TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_Quantity}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Style</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_Style === "cs"
                        ? `${cart[clickedWindow].mts_Lite} ${codeToName(
                            cart[clickedWindow].mts_Style
                          )}`
                        : codeToName(cart[clickedWindow].mts_Style)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Size</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_Style !== "gsld"
                        ? `${cart[clickedWindow].mts_Width}" x ${cart[clickedWindow].mts_Height}"`
                        : `${cart[clickedWindow].mts_DoorHeight}`}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Glass</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_GlassType}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table style={modalStyle.table}>
                <TableBody>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Color</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_color}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Grid</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_Style !== "gsld"
                        ? cart[clickedWindow].mts_gridpattern
                        : `${cart[clickedWindow].mts_DoorGrid.replace(
                            /[^0-9a-z]/gi,
                            " "
                          )}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>Options</TableCell>
                    <TableCell style={modalStyle.normal}>
                      {`${cart[clickedWindow].mts_Options.length} option(s) selected`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>
                      Removal Option
                    </TableCell>
                    <TableCell style={modalStyle.normal}>
                      {cart[clickedWindow].mts_RemovalOptions}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={modalStyle.sideHeader}>
                      Trim Options
                    </TableCell>
                    <TableCell
                      style={modalStyle.normal}
                    >{`${cart[clickedWindow].mts_Trim.length} option(s) selected`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </MoreCustomModal>
    </div>
  ) : (
    <Success />
  );
};

export default Checkout;
