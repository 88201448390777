// const axios = require("axios");
import { allGrid } from "../../Data/gridOptions";
import { allUp } from "../../Data/optionsUpCharge";
import { allWindows } from "../../Data/window";

function getPrice(price, ui, type) {
  return type === "single" ? price : ui * price;
}

export const noSpecialChar = text => {
  return text.replace(/[^0-9a-z]/gi, " ");
};

export const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

export const getOptionPrice = ({ selected, windowOptions, option }) => {
  let price = 0.0;
  const ui = +selected.mts_Width + +selected.mts_Height;

  windowOptions.options[option].forEach((v, i) => {
    if (selected.mts_Style.toLowerCase() === "gardenbay") {
      if (
        +selected.mts_Width >= v.min_width &&
        +selected.mts_Width <= v.max_width
      ) {
        let tmpPrice = getPrice(v.price, 0, v.type);
        console.log("manufacturing option before markup");
        console.log(tmpPrice);
        tmpPrice =
          getPrice(v.price, 0, v.type) *
          allUp[selected.mts_Style.toLowerCase()]["options"][option];
        console.log("option after markup");
        console.log(tmpPrice);
        price += tmpPrice;
      }
    } else {
      if (ui >= v.min_ui && ui <= v.max_ui) {
        if (option === "snap_mullion") {
          if (v.type === selected.mts_color.toLowerCase()) {
            price = v.price * +selected.mts_Height;
          }
        } else {
          let tmpPrice = getPrice(v.price, ui, v.type);
          console.log("manufacturing option before markup");
          console.log(tmpPrice);
          tmpPrice =
            getPrice(v.price, ui, v.type) *
            allUp[selected.mts_Style.toLowerCase()]["options"][option];
          console.log("option after markup");
          console.log(tmpPrice);
          price += tmpPrice;
        }
      }
    }
  });
  console.log("option before markup");
  console.log(price);

  return price;
};

export const getWindowPrice = ({ selected, windowOptions }) => {
  let price;
  const ui = +selected.mts_Width + +selected.mts_Height;
  //not baybow
  if (selected.mts_Style !== "baybow" && selected.mts_Style !== "bay") {
    if (selected.mts_Style === "gsld") {
      let priceObj = windowOptions.colors[selected.mts_color.toLowerCase()][
        selected.mts_DoorGrid
      ].filter(v => v.height === selected.mts_DoorHeight);
      price = getPrice(priceObj[0].price, ui, priceObj[0].type);
    } else if (selected.mts_Style.toLowerCase() === "cs") {
      console.log(selected);
      //calculate all the operating cs
      //what is the count
      let opPrice = 0.0;
      const opCount = selected.mts_OperatingLite;
      console.log("winoptions");
      console.log(windowOptions);
      windowOptions.operating[selected.mts_color.toLowerCase()].forEach(
        (v, i) => {
          if (ui >= v.min_ui && ui <= v.max_ui) {
            opPrice = getPrice(v.price, ui, v.type);
          }
        }
      );
      console.log("here is opPrice just one");
      console.log(opPrice);
      opPrice = opPrice * opCount;

      console.log("here is opPrice all");
      console.log(opPrice);
      //search
      //calculate all the nonop cs
      let cpwPrice = 0.0;
      const nonOpCount = selected.mts_Lite - opCount;
      windowOptions.cpw[selected.mts_color.toLowerCase()].forEach((v, i) => {
        if (ui >= v.min_ui && ui <= v.max_ui) {
          cpwPrice = getPrice(v.price, ui, v.type);
        }
      });
      console.log("here is cpwPrice just one");
      console.log(cpwPrice);

      cpwPrice = cpwPrice * nonOpCount;
      console.log("here is cpwPrice all");
      console.log(cpwPrice);

      price = opPrice + cpwPrice;
      console.log("here is total price");
      console.log(price);
    } else {
      windowOptions.colors[selected.mts_color.toLowerCase()].forEach((v, i) => {
        if (selected.mts_Style.toLowerCase() === "gardenbay") {
          if (
            +selected.mts_Width >= v.min_width &&
            +selected.mts_Width <= v.max_width &&
            +selected.mts_Height >= v.min_height &&
            +selected.mts_Height <= v.max_height
          ) {
            price = getPrice(v.price, 0, v.type);
          }
        } else {
          if (ui >= v.min_ui && ui <= v.max_ui) {
            price = getPrice(v.price, ui, v.type);
          }
        }
      });
    }
  } else {
    console.log("here are window options");
    console.log(windowOptions);
    let filteredObj = windowOptions.lite_options.filter(
      v => v.count === +selected.mts_Lite
    );
    console.log("here is filtered obj");
    console.log(filteredObj);
    filteredObj.forEach((v, i) => {
      if (
        +selected.mts_Width >= v.min_width &&
        +selected.mts_Width <= v.max_width &&
        +selected.mts_Height >= v.min_height &&
        +selected.mts_Height <= v.max_height
      ) {
        price = getPrice(v.price, ui, v.type);
      }
    });
  }
  console.log("here is price for window");
  console.log(price);
  return price;
};

export const getGlassPrice = ({ selected, windowOptions }) => {
  let price;

  const ui = +selected.mts_Width + +selected.mts_Height;

  windowOptions.glass[selected.mts_GlassType.toLowerCase()].forEach((v, i) => {
    if (ui >= v.min_ui && ui <= v.max_ui) {
      price = getPrice(v.price, ui, v.type);
    }
  });
  console.log("glass price before markup");
  console.log(price);
  console.log("glass price after markup");
  console.log(
    price *
      allUp[selected.mts_Style.toLowerCase()]["glass"][selected.mts_GlassType]
  );
  return selected.mts_Style.toLowerCase() !== "cs"
    ? price *
        allUp[selected.mts_Style.toLowerCase()]["glass"][selected.mts_GlassType]
    : price *
        allUp[selected.mts_Style.toLowerCase()]["glass"][
          selected.mts_GlassType.toLowerCase()
        ] *
        selected.mts_Lite;
};

export const nameToCode = name => {
  switch (name.toLowerCase()) {
    case "double hung":
      return "dh";
    case "casement":
      return "cs";
    case "awning":
      return "aw";
    case "picture window":
      return "pw";
    case "picture window casement":
      return "pwc";
    case "slider (2 lites)":
      return "sl";
    case "slider (3 lites)":
      return "sl3";
    case "ventana garden bay":
      return "gardenbay";
    case "bow windows":
      return "baybow";
    case "camelot sliding glass door":
      return "gsld";
    case "bay windows":
      return "bay";
    default:
      break;
  }
};

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const firstCharCap = text => {
  return text
    .toLowerCase()
    .split(" ")
    .map(s => {
      if (s !== "oz" && s !== "oz.") {
        return s.charAt(0).toUpperCase() + s.substring(1);
      } else {
        return s;
      }
    })
    .join(" ");
};

export const codeToName = code => {
  switch (code.toUpperCase()) {
    case "DH":
      return "double hung";
    case "CS":
      return "casement";
    case "AW":
      return "awning";
    case "PW":
      return "picture window";
    case "PWC":
      return "picture window casement";
    case "SL":
      return "slider (2 lites)";
    case "SL3":
      return "slider (3 lites)";
    case "GARDENBAY":
      return "ventana garden bay";
    case "BAYBOW":
      return "bow windows";
    case "GSLD":
      return "camelot sliding glass door";
    case "BAY":
      return "bay windows";
    default:
      break;
  }
};

export const verifyCanGenerateWindow = ({
  mts_Height,
  mts_Width,
  tmpGridStyle,
  mts_Style,
  mts_color
}) => {
  if (
    +mts_Height !== 0 &&
    +mts_Width !== 0 &&
    tmpGridStyle !== "" &&
    mts_Style !== "" &&
    mts_color !== "" &&
    +mts_Height !== undefined &&
    +mts_Width !== undefined &&
    tmpGridStyle !== undefined &&
    mts_Style !== undefined &&
    mts_color !== undefined
  ) {
    return "visible";
  } else {
    return "hidden";
  }
};
export const codeToPictureAPI = code => {
  switch (code.toLowerCase()) {
    case "dh":
      return "DOUBLEHUNG";
    case "cs":
      return "CASEMENT";
    case "aw":
      return "AWNING";
    case "pw":
    case "pwc":
      return "PICTURE";
    case "sl":
    case "sl3":
      return "SLIDER";
    case "baybow":
      return "BOW WINDOWS";
    case "gsld":
      return "CAMELOT SLIDING GLASS DOOR";
    case "bay":
      return "BAY WINDOWS";
    default:
      break;
  }
};

export const getContourGridType = selectedOptions => {
  switch (selectedOptions["mts_color"].toLowerCase()) {
    case "almond":
      return "CA";
    case "white":
      return "CW";
    case "cocoa-white":
      return "CT";
    default:
      return "err";
  }
};

export const pickGridPattern = ({
  mts_Height,
  mts_Width,
  mts_gridtype,
  tmpGridStyle,
  mts_Style,
  mts_gridpattern,
  mts_DoorGrid
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (mts_gridpattern.toLowerCase() === "gardenbay grid") {
        allWindows.data["gardenbay"].options.grids.forEach((v, i) => {
          if (mts_Width >= v.min_width && mts_Width <= v.max_width) {
            resolve({
              price: v.price,
              name: "GBAY Grids"
            });
          }
        });
      } else if (mts_DoorGrid.toLowerCase() !== "none") {
        //price calculate for door grids
        //let them know grid price is calculated
        resolve({
          price: 0.0,
          name: `${mts_DoorGrid.replace(/[^0-9a-z]/gi, " ")}`
        });
      } else {
        let { data } = allGrid;
        switch (tmpGridStyle.toLowerCase()) {
          case "queenanne":
            resolve({
              price: data["QUEEN_ANNE"][mts_Style][mts_gridtype],
              name: "QA4"
            });
            break;
          case "diamond":
            let diamond = data["DIAMOND"];
            console.log(diamond);
            let final = [];
            Object.keys(diamond).forEach(v => {
              if (
                diamond[v].max_height >= +mts_Height &&
                diamond[v].max_width >= +mts_Width &&
                diamond[v].min_height <= +mts_Height &&
                diamond[v].min_width <= +mts_Width
              ) {
                let priceVal = diamond[v][mts_gridtype.toUpperCase()];

                final.push({
                  price: priceVal.toFixed(2),
                  name: v
                });
              }
            });

            resolve(final[0]);

            break;
          case "colonial":
            let colonial = data["COLONIAL"];

            let finalTwo = [];

            if (mts_Style === "dh") {
              finalTwo.push({
                price:
                  colonial["dh"][mts_gridpattern][mts_gridtype.toUpperCase()],
                name: mts_gridpattern
              });

              resolve(finalTwo[0]);
            } else if (mts_Style === "sl" || mts_Style === "sl3") {
              finalTwo.push({
                price:
                  colonial["sl"][mts_gridpattern][mts_gridtype.toUpperCase()],
                name: mts_gridpattern
              });

              resolve(finalTwo[0]);
            } else {
              Object.keys(colonial["dh"]).forEach(v => {
                if (
                  colonial["dh"][v].max_height >= +mts_Height &&
                  colonial["dh"][v].max_width >= +mts_Width &&
                  colonial["dh"][v].min_height <= +mts_Height &&
                  colonial["dh"][v].min_width <= +mts_Width
                ) {
                  let priceVal = colonial["dh"][v][mts_gridtype.toUpperCase()];
                  console.log(priceVal.toFixed(2));
                  finalTwo.push({
                    price: priceVal.toFixed(2),
                    name: v
                  });
                }
              });

              resolve(finalTwo[0]);
            }

            break;
          default:
            resolve({ price: (0.0).toFixed(2), name: "---" });
            break;
        }
      }
    } catch (e) {
      console.log(e);
    }
  });
};

export const isValidGrid = ({
  mts_Height,
  mts_Width,
  tmpGridStyle,
  mts_Style
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let { data } = allGrid;
      let final = [];
      switch (tmpGridStyle.toLowerCase()) {
        case "queenanne":
          resolve(true);
          break;
        case "diamond":
          let diamond = data["DIAMOND"];

          Object.keys(diamond).forEach(v => {
            if (
              diamond[v].max_height >= +mts_Height &&
              diamond[v].max_width >= +mts_Width &&
              diamond[v].min_height <= +mts_Height &&
              diamond[v].min_width <= +mts_Width
            ) {
              final.push(v);
            }
          });
          final.length !== 0 ? resolve(true) : resolve(false);
          break;
        case "colonial":
          let colonial = data["COLONIAL"];
          final = [];
          if (mts_Style === "dh") {
            Object.keys(colonial["dh"]).forEach(v => {
              if (
                colonial["dh"][v].max_height >= +mts_Height &&
                colonial["dh"][v].max_width >= +mts_Width &&
                colonial["dh"][v].min_height <= +mts_Height &&
                colonial["dh"][v].min_width <= +mts_Width
              ) {
                final.push(v);
              }
            });
            console.log("here is length");
            console.log(final.length);
            final.length !== 0 ? resolve(true) : resolve(false);
            break;
          } else {
            Object.keys(colonial).forEach(v => {
              if (
                colonial[v].max_height >= +mts_Height &&
                colonial[v].max_width >= +mts_Width &&
                colonial[v].min_height <= +mts_Height &&
                colonial[v].min_width <= +mts_Width
              ) {
                final.push(v);
              }
            });
            console.log("here is length");
            console.log(final.length);
            final.length !== 0 ? resolve(true) : resolve(false);
            break;
          }

        default:
          resolve(true);
          break;
      }
    } catch (e) {
      console.log(e);
    }
  });
};
