import React, { useContext, useState, useEffect } from "react";
import Modal from "../components/Modal/Modal";
// import AnimatedNumber from "react-animated-number";
import { StyledTable } from "./styled";
import { TableRow, TableCell, TableHead, TableBody } from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import styled from "styled-components";
import { Context } from "../App";
import OtherLoader from "../components/OtherLoader/OtherLoader";
import { Redirect } from "react-router";

const StyledShoppingCart = styled(AddShoppingCart)`
  font-size: 28px;
  color: #313e50;
  &:hover {
    transform: scale(1.2);
  }
`;

const DisplayWindow = ({ finalVal, setFinalVal, windowPrice, selected }) => {
  //set context
  const {
    setShowPrice,
    setShow,
    setCart,
    cart,
    managerValues,
    newCart,
    setNewCart,
    setCurrentWindow,
    isLoading
  } = useContext(Context);

  const [redirect, setRedirect] = useState(false);

  //This handles quantity DO NOT DO IT ANYWHERE ELSE
  const addToShoppingCart = e => {
    e.preventDefault();
    console.log("here");
    console.log(selected);
    //manipulate selected to fit what we need
    const gen_window = {
      MTS_SERIES: selected.mts_series.toUpperCase(),
      MTS_STYLE: selected.mts_Style.toUpperCase(),
      MTS_GLASS_TYPE: selected.mts_GlassType.toUpperCase(),
      MTS_COLOR: selected.mts_color.toUpperCase(),
      MTS_QUANTITY: selected.mts_Quantity,
      MTS_WIDTH: selected.mts_Width,
      MTS_HEIGHT: selected.mts_Height,
      MTS_LOCATION: selected.mts_DemarcationLocation.toUpperCase(),
      MTS_REMOVALOPTIONS: selected.mts_RemovalOptions.toUpperCase(),
      MTS_NOTES: selected.mts_Notes.toUpperCase(),
      MTS_GRIDCODE: selected.mts_gridtype.toUpperCase(),
      MTS_GRIDPATTERN: selected.mts_gridpattern,
      MTS_GRIDSTYLE: selected.tmpGridStyle.toUpperCase(),
      MTS_FULLGRIDPATTERN: selected.tmpGridPattern.toUpperCase(),
      MTS_SASHCOUNT: selected.mts_sashCount,
      //
      MTS_LITE_COUNT: selected.mts_Lite,
      MTS_OPERATINGLITE: selected.mts_OperatingLite,
      //doors
      MTS_DOORHEIGHT: selected.mts_DoorHeight,
      MTS_DOORGRID: selected.mts_DoorGrid,
      MTS_TRANSOMHEIGHT: selected.mts_TransomHeight,
      MTS_TRANSOMGRID: selected.mts_TransomGrid,
      MTS_SIDELITEHEIGHT: selected.mts_SideliteHeight,
      MTS_SIDELITEGRID: selected.mts_SideliteGrid,
      //bow tilts
      MTS_DHTILTS: selected.mts_DHTilts,
      MTS_VENTS: selected.mts_Vents
    };
    console.log(gen_window);
    const other_options = {
      MTS_TRIM: selected.mts_Trim,
      MTS_OPTIONS: selected.mts_Options
    };
    console.log(other_options);
    let final = {
      PRODUCT_ID: selected.PRODUCT_ID,
      window_details: gen_window,
      options: other_options,
      price_breakdown: windowPrice,
      par_price: parseFloat(+calculateTotal() * selected.mts_Quantity),
      unit_par_price: parseFloat(+calculateTotal()),
      actual_price: parseFloat(
        +calculateTotal() * selected.mts_Quantity * managerValues.percent -
          managerValues.down
      ),
      actual_unit_price: parseFloat(
        +calculateTotal() * managerValues.percent - managerValues.down
      )
    };
    console.log("here is manager values");
    console.log(managerValues);
    console.log("here is the check for final unit price");
    console.log(final);
    //filter out old
    // const filtered
    console.log("here is newCart");
    console.log(newCart.CART);
    const updatedCart = newCart.CART.filter(
      v => v.PRODUCT_ID !== selected.PRODUCT_ID
    );
    console.log("after filter");
    console.log(updatedCart);
    console.log("bbok");
    console.log({ ...newCart, CART: [...updatedCart, final] });
    setNewCart({ ...newCart, CART: [...updatedCart, final] });

    let val = {
      name: windowPrice.window_price.type,
      price: parseFloat(+calculateTotal() * selected.mts_Quantity),
      ...selected
    };
    console.log("here is final");
    console.log(final);
    console.log(windowPrice);
    //filter out old aka delete old id key
    let tmpCart = cart;
    delete tmpCart[selected.PRODUCT_ID];
    let test = { ...tmpCart, [selected.PRODUCT_ID]: val };
    setCart({ ...tmpCart, [selected.PRODUCT_ID]: val });
    localStorage.setItem("cart", JSON.stringify(test));
    localStorage.setItem(
      "newcart",
      JSON.stringify({ ...newCart, CART: [...updatedCart, final] })
    );
    setCurrentWindow({});
    setShowPrice(false);
    setShow(true);
    setRedirect(true);
  };

  const closePrice = e => {
    e.preventDefault();
    setShowPrice(false);
  };

  const calculateTotal = () => {
    return windowPrice
      ? +Object.keys(windowPrice)
          .reduce((acum, curr) => acum + +windowPrice[curr]["price"], 0)
          .toFixed(2)
      : 0;
  };

  return !redirect ? (
    <Modal
      openState={finalVal}
      handleClose={() => setFinalVal("")}
      width={"80%"}
      onClose={closePrice}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div className="tableDiv" style={{ width: "100%" }}>
          <StyledTable
            style={{
              borderRadius: "5px",
              fontFamily: '"Roboto", sans-serif',
              borderCollapse: "inherit"
            }}
          >
            <TableHead>
              <TableRow className="highlighted">
                <TableCell className="headerCells">Item</TableCell>
                <TableCell className="headerCells">Selected</TableCell>
                {/* <TableCell className="headerCells">Price</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(windowPrice).map((v, i) => {
                return windowPrice[v] ? (
                  <TableRow key={i}>
                    <TableCell className="rowCells">
                      {windowPrice[v]["name"]}
                    </TableCell>
                    <TableCell className="rowCells">
                      {windowPrice[v]["type"]}
                    </TableCell>
                    {/* <TableCell className="rowCells">
                      {`$${windowPrice[v]["price"]}`}
                    </TableCell> */}
                  </TableRow>
                ) : null;
              })}

              {/* <TableRow>
                <TableCell className="rowCells">Total</TableCell>
                <TableCell className="rowCells">-</TableCell>
                <TableCell className="rowCells">
                  <AnimatedNumber
                    component="i"
                    value={parseFloat(+calculateTotal())}
                    style={{
                      transition: "1s ease-in-out",

                      transitionProperty: "background-color, color, opacity"
                    }}
                    duration={1000}
                    stepPrecision={2}
                    formatValue={n =>
                      n === 0
                        ? n
                        : n.toString().split(".")[1] &&
                          n.toString().split(".")[1].length > 1
                        ? `$${n.toFixed(2)}`
                        : `$${n.toFixed(2)}`
                    }
                  />
                </TableCell>
              </TableRow> */}
            </TableBody>
          </StyledTable>
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <StyledShoppingCart onClick={addToShoppingCart} />
        </div>
      </div>
    </Modal>
  ) : (
    <Redirect to="/pricer" />
  );
};

export default DisplayWindow;
