export const allUp = {
  trim: {
    interior_casing: {
      name: "Interior Casing",
      price: 250.0
    },
    stool_with_apron: {
      name: "Stool With Apron",
      price: 150.0
    },
    brick_molding: {
      name: "Brick Molding",
      price: 100.0
    },
    buck_frame: {
      name: "Buck Frame",
      price: 100.0
    },
    shutter_removal: {
      name: "Shutter Removal (with re-install)",
      price: 50.0
    },
    security_bars: {
      name: "Security Bars (removal only)",
      price: 50.0
    },
    sill: {
      name: "Sill",
      price: 50.0
    }
  },
  removal: {
    none: {
      name: "None",
      price: 0.0
    },
    steel: {
      name: "Steel",
      price: 250.0
    },
    wood: {
      name: "Wood",
      price: 0.0
    },
    aluminum: {
      name: "Aluminum",
      price: 100.0
    },
    vinyl: {
      name: "Vinyl",
      price: 100.0
    },
    egg_crate: {
      name: "Egg Crate",
      price: 350.0
    }
  },
  dh: {
    glass: {
      clear: 1.15,
      tempered: 1.15,
      obscure: 1.15
    },
    options: {
      full_screens: 1.15,
      oriel_sash: 1.15,
      grids: 3
    }
  },
  sl: {
    glass: {
      clear: 1.15,
      tempered: 1.15,
      obscure: 1.15
    },
    options: {
      full_screens: 1.15,
      grids: 3
    }
  },
  sl3: {
    glass: {
      clear: 1.15,
      tempered: 1.15,
      obscure: 1.15
    },
    options: {
      three_equal_section_slider: 1,
      quarter_half_quarter_slider: 1,
      grids: 3
    }
  },
  aw: {
    glass: {
      clear: 1,
      tempered: 1,
      obscure: 1
    },
    options: {
      grids: 3
    }
  },
  pw: {
    glass: {
      clear: 1.15,
      tempered: 1.15,
      obscure: 1.15
    },
    options: {
      grids: 3
    }
  },
  gardenbay: {
    glass: {
      clear: 1
    },
    options: {
      extra_glass_shelf: 1.2,
      grids: 1.2
    }
  },
  bay: {
    options: {
      almond_vinyl: 1,
      cocoa_white_color: 1,
      red_oak_seat_board: 3,
      extended_sill: 3,
      roof_option: 10,
      jamb_width_extended: 5,
      box_bow: 5,
      white_vinyl_board_construction: 2,
      grids: 1,
      casement_vents: 2,
      dh_tilt: 2
    }
  },
  baybow: {
    options: {
      almond_vinyl: 1,
      cocoa_white_color: 1,
      red_oak_seat_board: 3,
      extended_sill: 3,
      roof_option: 10,
      jamb_width_extended: 5,
      box_bow: 5,
      white_vinyl_board_construction: 2,
      grids: 1,
      casement_vents: 2,
      dh_tilt: 2
    }
  },
  gsld: {
    options: {
      grids: 1,
      custom_size: 1.2,
      internal_blinds_5ft: 1.2,
      internal_blinds_6ft: 1.2,
      internal_blinds_9ft: 1.2,
      queen_ann_grids: 1.2
    }
  },
  cs: {
    glass: {
      clear: 1.15,
      tempered: 1.15,
      obscure: 1.15
    },
    options: {
      grids: 3
    }
  }
};
