import React, { useState, useEffect, useContext } from "react";
import DisplayWindow from "../components/displayWindow";
import CustomModal from "../components/CustomModal/CustomModal";
import OtherLoader from "../components/OtherLoader/OtherLoader";
import colonial from "./colonial.png";
import queen_anne from "./queen_anne.png";
import diamond from "./diamond.png";
import flat from "./flat.png";
import contour from "./contour.png";
import none from "./none.png";
import { allWindows } from "../Data/window";
import { allUp } from "../Data/optionsUpCharge";
import { allGrid } from "../Data/gridOptions";
import {
  nameToCode,
  getContourGridType,
  pickGridPattern,
  verifyCanGenerateWindow,
  isValidGrid,
  codeToName,
  firstCharCap,
  getWindowPrice,
  getGlassPrice,
  getOptionPrice,
  noSpecialChar
} from "../components/utils/helper";
import { getWindow } from "../Helper/API";
import { Context } from "../App";
import background from "../assets/blueSky.png";
import { FormCard, StyledButton, StyledGrid } from "../styles/styles";
import hideKeyboard from "hide-virtual-keyboard";

import {
  FormControl,
  Typography,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  RadioGroup,
  Radio,
  Slider,
  FormControlLabel
} from "@material-ui/core";

const Pricer = props => {
  //context
  const {
    setShowPrice,
    setCurrentWindow,
    currentWindow,
    setShow,
    show,
    formButtonClick,
    setIsEdit,
    isEdit
  } = useContext(Context);

  //styling for gsld
  const hide = { margin: "0 1em 0 1em", display: "none" };
  const view = { margin: "0 1em 0 1em" };
  let sideGrid = view;
  let transGrid = view;

  //normal state
  const [door, setDoor] = useState({ sideGrid: hide, transGrid: hide });
  const [validGrid, setValidGrid] = useState({
    main: "hidden",
    others: "visible"
  });
  const [priceValid, setPriceValid] = useState({
    valid: "priceVisible",
    invalid: "priceHidden"
  });
  const [modalWindowShow, setModalWindowShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState({ height: false, width: false });
  const [windowOptions, setWindowOptions] = useState({});
  const [widthGuide, setWidthGuide] = useState("");
  const [heightGuide, setHeightGuide] = useState("");

  //window selections
  const [selected, setSelected] = useState({
    PRODUCT_ID: "",
    mts_series: "CE",
    mts_Style: nameToCode(props.match.params.id),
    mts_GlassType:
      nameToCode(props.match.params.id) !== "gsld" ? "clear" : "low e glass",
    mts_color: "white",
    mts_Width: 1,
    mts_Height: 1,
    mts_Quantity: 1,
    mts_DemarcationLocation: "",
    mts_gridtype: "",
    mts_gridpattern: "",
    tmpGridStyle: "",
    tmpGridPattern: "",
    mts_Notes: "",
    mts_sashCount: 1,
    mts_Options: [],
    mts_RemovalOptions: "none",
    mts_Trim: [],
    mts_Lite:
      nameToCode(props.match.params.id) === "baybow" ||
      nameToCode(props.match.params.id) === "bay"
        ? 3
        : nameToCode(props.match.params.id) === "cs"
        ? 1
        : "none",
    mts_OperatingLite: nameToCode(props.match.params.id) === "cs" ? 0 : "none",
    mts_Vents: nameToCode(props.match.params.id) === "bay" ? 0 : "none",
    mts_DHTilts: nameToCode(props.match.params.id) === "bay" ? 0 : "none",
    mts_DoorHeight:
      nameToCode(props.match.params.id) === "gsld"
        ? "5 ft (59.25 x 79.5)"
        : "none",
    mts_DoorGrid:
      nameToCode(props.match.params.id) === "gsld" ? "no_grids" : "none",
    mts_TransomHeight: "none",
    mts_TransomGrid:
      nameToCode(props.match.params.id) === "gsld" ? "no_grids" : "none",
    mts_SideliteHeight: "none",
    mts_SideliteGrid:
      nameToCode(props.match.params.id) === "gsld" ? "no_grids" : "none"
  });

  const [hideButton, setHideButton] = useState("");
  const [windowImage, setwindowImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalVal, setFinalVal] = useState("");
  const [finalWindowPrice, setFinalWindowPrice] = useState({});
  const [gColorClass, setGColorClass] = useState("visible");
  const [gOption, setgOptionClass] = useState("visible");
  const [clicked, setClicked] = useState({
    colonial: selected.tmpGridStyle === "colonial" ? "clicked" : "",
    queenanne: selected.tmpGridStyle === "queenanne" ? "clicked" : "",
    diamond: selected.tmpGridStyle === "diamond" ? "clicked" : "",
    none:
      selected.tmpGridStyle === "" || selected.tmpGridStyle === "none"
        ? "clicked"
        : "",
    flat: "clicked",
    contour: ""
  });
  const [windowButton, setWindowButton] = useState("hidden");

  const enterFunction = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      hideKeyboard();
    }
  };

  const optionStyle = {
    baybow: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr ",
      width: "100%"
    },
    other: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      width: "100%"
    }
  };
  const sashChangeHandler = e => {
    let tmp = selected;
    tmp.mts_sashCount = e.target.value;
    // console.log(e.target);
    setSelected({ ...tmp });
    console.log("updated");
  };

  const clickFlat = e => {
    e.preventDefault();
    let tmp = clicked;
    tmp["flat"] = "clicked";
    tmp["contour"] = "";
    setClicked(tmp);
    setGColorClass("visible");
    tmp = selected;
    tmp["tmpGridPattern"] = "flat";
    tmp["mts_gridtype"] = "";
    setSelected(tmp);
  };

  const viewSampleWindow = async e => {
    e.preventDefault();
    setLoading(true);
    const window = await getWindow(selected);
    setwindowImage(window);
    setTimeout(() => {
      setLoading(false);
      setModalWindowShow(true);
    }, 1000);
  };

  const clickContour = e => {
    e.preventDefault();
    let tmp = clicked;
    tmp["flat"] = "";
    tmp["contour"] = "clicked";
    setClicked(tmp);
    const grid = getContourGridType(selected);
    tmp = selected;
    tmp["mts_gridtype"] = grid;

    setSelected(tmp);
    setGColorClass("hidden");
    tmp = selected;
    tmp["tmpGridPattern"] = "contour";
    setSelected(tmp);
  };

  const getWindowPricing = async () => {
    setLoading(true);
    const windows = allWindows;
    let id = nameToCode(props.match.params.id);

    setWindowOptions(windows.data[id]);

    console.log(windows.data[id]["sash"]);
    console.log("found");
    setWidthGuide(
      `${windows.data[id].restriction[0].min_width}" to ${windows.data[id].restriction[0].max_width}"`
    );
    setHeightGuide(
      `${windows.data[id].restriction[0].min_height}" to ${windows.data[id].restriction[0].max_height}"`
    );
    let tmp = selected;
    tmp.mts_Width = windows.data[id].restriction[0].min_width;
    tmp.mts_Height = windows.data[id].restriction[0].min_height;
    setSelected({ ...tmp });
    setLoading(false);
  };
  // useEffect(() => {
  //   console.log("inside other");
  //   if (Object.keys(currentWindow).length !== 0) {
  //     console.log("inside update");
  //     console.log(currentWindow);
  //     const savedWindow = {
  //       PRODUCT_ID: currentWindow.PRODUCT_ID,
  //       mts_series: currentWindow.mts_series,
  //       mts_Style: currentWindow.mts_Style,
  //       mts_GlassType: currentWindow.mts_GlassType,
  //       mts_color: currentWindow.mts_color,
  //       mts_Quantity: currentWindow.mts_Quantity,
  //       mts_Width: +currentWindow.mts_Width,
  //       mts_Height: +currentWindow.mts_Height,
  //       mts_DemarcationLocation: currentWindow.mts_DemarcationLocation,
  //       mts_gridtype: currentWindow.mts_gridtype,
  //       mts_gridpattern: currentWindow.mts_gridpattern,
  //       tmpGridStyle: currentWindow.tmpGridStyle,
  //       tmpGridPattern: currentWindow.tmpGridPattern,
  //       mts_Notes: currentWindow.mts_Notes,
  //       mts_Options: currentWindow.mts_Options,
  //       mts_sashCount: +currentWindow.mts_sashCount,
  //       mts_RemovalOptions: currentWindow.mts_RemovalOptions,
  //       mts_Trim: currentWindow.mts_Trim,
  //       mts_Lite: currentWindow.mts_Lite,
  //       mts_Vents: currentWindow.mts_Vents,
  //       mts_DHTilts: currentWindow.mts_DHTilts,
  //       mts_DoorHeight: currentWindow.mts_DoorHeight,
  //       mts_DoorGrid: currentWindow.mts_DoorGrid,
  //       mts_TransomHeight: currentWindow.mts_TransomHeight,
  //       mts_TransomGrid: currentWindow.mts_TransomGrid,
  //       mts_SideliteHeight: currentWindow.mts_SideliteHeight,
  //       mts_SideliteGrid: currentWindow.mts_SideliteGrid,
  //       mts_OperatingLite: currentWindow.mts_OperatingLite
  //     };
  //     console.log("here is saved window");
  //     console.log(savedWindow);
  //     setSelected(savedWindow);
  //     setClicked({
  //       colonial: currentWindow.tmpGridStyle === "colonial" ? "clicked" : "",
  //       queenanne: currentWindow.tmpGridStyle === "queenanne" ? "clicked" : "",
  //       diamond: currentWindow.tmpGridStyle === "diamond" ? "clicked" : "",
  //       flat: currentWindow.tmpGridPattern === "flat" ? "clicked" : "",
  //       none:
  //         currentWindow.tmpGridStyle === "" ||
  //         currentWindow.tmpGridStyle === "none"
  //           ? "clicked"
  //           : "",
  //       contour: currentWindow.tmpGridPattern === "contour" ? "clicked" : ""
  //     });
  //     setCurrentWindow({});
  //   }
  // }, [currentWindow]);
  useEffect(() => {
    const getWindows = async () => {
      await getWindowPricing();
    };

    window.scrollTo(0, 0);
    document.addEventListener("keydown", enterFunction, false);
    getWindows();

    setShow(false);
    console.log("not inside");
    console.log(currentWindow);
    if (Object.keys(currentWindow).length !== 0) {
      console.log("inside update");
      console.log(currentWindow);
      const savedWindow = {
        PRODUCT_ID: currentWindow.PRODUCT_ID,
        mts_series: currentWindow.mts_series,
        mts_Style: currentWindow.mts_Style,
        mts_GlassType: currentWindow.mts_GlassType,
        mts_color: currentWindow.mts_color,
        mts_Quantity: currentWindow.mts_Quantity,
        mts_Width: +currentWindow.mts_Width,
        mts_Height: +currentWindow.mts_Height,
        mts_DemarcationLocation: currentWindow.mts_DemarcationLocation,
        mts_gridtype: currentWindow.mts_gridtype,
        mts_gridpattern: currentWindow.mts_gridpattern,
        tmpGridStyle: currentWindow.tmpGridStyle,
        tmpGridPattern: currentWindow.tmpGridPattern,
        mts_Notes: currentWindow.mts_Notes,
        mts_Options: currentWindow.mts_Options,
        mts_sashCount: +currentWindow.mts_sashCount,
        mts_RemovalOptions: currentWindow.mts_RemovalOptions,
        mts_Trim: currentWindow.mts_Trim,
        mts_Lite: currentWindow.mts_Lite,
        mts_Vents: currentWindow.mts_Vents,
        mts_DHTilts: currentWindow.mts_DHTilts,
        mts_DoorHeight: currentWindow.mts_DoorHeight,
        mts_DoorGrid: currentWindow.mts_DoorGrid,
        mts_TransomHeight: currentWindow.mts_TransomHeight,
        mts_TransomGrid: currentWindow.mts_TransomGrid,
        mts_SideliteHeight: currentWindow.mts_SideliteHeight,
        mts_SideliteGrid: currentWindow.mts_SideliteGrid,
        mts_OperatingLite: currentWindow.mts_OperatingLite
      };
      console.log("here is saved window");
      console.log(savedWindow);
      setSelected(savedWindow);
      setClicked({
        colonial: currentWindow.tmpGridStyle === "colonial" ? "clicked" : "",
        queenanne: currentWindow.tmpGridStyle === "queenanne" ? "clicked" : "",
        diamond: currentWindow.tmpGridStyle === "diamond" ? "clicked" : "",
        flat: currentWindow.tmpGridPattern === "flat" ? "clicked" : "",
        none:
          currentWindow.tmpGridStyle === "" ||
          currentWindow.tmpGridStyle === "none"
            ? "clicked"
            : "",
        contour: currentWindow.tmpGridPattern === "contour" ? "clicked" : ""
      });
      setCurrentWindow({});
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);

  //dependency is selected so anything that is dependent on selected
  useEffect(() => {
    let tmp = door;
    tmp.transGrid =
      selected.mts_Style === "gsld" && selected.mts_TransomHeight === "none"
        ? hide
        : view;
    tmp.sideGrid =
      selected.mts_Style === "gsld" && selected.mts_SideliteHeight === "none"
        ? hide
        : view;

    setDoor({ ...tmp });
  }, [selected]);

  //   //run always
  useEffect(() => {
    setWindowButton(verifyCanGenerateWindow(selected));

    //force users to select if grid options allowed
    // gColorClass === "visible" && console.log("conditions met");
    let val =
      gColorClass === "visible" &&
      (selected.mts_gridtype === "" || selected.mts_gridpattern === "")
        ? "buttonHide"
        : "";
    console.log("here is to see if you can add");
    console.log(selected);
    setHideButton(val);
  });

  //run when correct height and width
  useEffect(() => {
    setLoading(false);
  }, [validGrid]);

  const modalWindowClickHandler = e => {
    e.preventDefault();
    setModalWindowShow(false);
  };

  const selectClickHandler = async e => {
    e.preventDefault();
    let tmp = selected;
    tmp[e.target.name] = e.target.value;
    //run calculations for grid

    const grid = await pickGridPattern(selected);
    tmp.mts_gridpattern = grid.name === "---" ? "" : grid.name;
    console.log("here is selected");
    console.log(selected);
    console.log("here is what we gather");
    console.log(e.target.value);
    console.log(tmp.mts_gridpattern);
    if (e.target.name === "mts_Vents") {
      switch (e.target.value) {
        case "double_hung":
          tmp.mts_DHTilts = 2;
          tmp.mts_Vents = 0;
          break;
        case "casement":
          tmp.mts_Vents = 2;
          tmp.mts_DHTilts = 0;
          break;
        default:
          tmp.mts_DHTilts = 0;
          tmp.mts_Vents = 0;
      }
    }
    setSelected({ ...tmp });
  };

  const modalClickHandler = e => {
    e.preventDefault();
    setModalShow(false);
  };

  const gridClick = async e => {
    let id = e.target.id;
    e.preventDefault();
    setLoading(false);

    let tmp = selected;
    tmp["tmpGridStyle"] = id;
    tmp["mts_gridtype"] = "";
    id === "diamond" ? setgOptionClass("hidden") : setgOptionClass("visible");
    if (id === none) {
      tmp["mts_gridtype"] = "";
    }
    let gridy = await isValidGrid(selected);
    if (!gridy) {
      tmp["mts_gridtype"] = "";
      tmp["tmpGridStyle"] = "none";
    }
    console.log("here is tmp");
    console.log(tmp);
    setSelected(tmp);

    if (tmp.tmpGridStyle === "colonial" && tmp.mts_Style === "dh") {
      tmp.mts_gridpattern = "2Vx1H";
    }
    if (
      tmp.tmpGridStyle === "colonial" &&
      (tmp.mts_Style === "sl" || tmp.mts_Style === "sl3")
    ) {
      tmp.mts_gridpattern = "1Vx2H";
    }

    tmp = clicked;
    tmp["diamond"] = "";
    tmp["queenanne"] = "";
    tmp["colonial"] = "";
    tmp["none"] = "";
    tmp["flat"] = "clicked";
    tmp["contour"] = "";

    tmp[id] = "clicked";
    if (!gridy) {
      tmp["diamond"] = "";
      tmp["queenanne"] = "";
      tmp["colonial"] = "";
      tmp["none"] = "";
      tmp["flat"] = "clicked";
      tmp["contour"] = "";
      tmp["none"] = "clicked";
    }

    setClicked(tmp);
    setGColorClass("visible");

    gridy
      ? setValidGrid({ main: "hidden", others: "visible" })
      : setValidGrid({ main: "visible", others: "hidden" });

    id !== "none" && setModalShow(true);
  };

  const handleQuantityChange = (e, value) => {
    e.preventDefault();
    let tmp = selected;
    tmp.mts_Quantity = value;
    setSelected({ ...tmp });
  };

  //@TO-DO - figure out and test more
  const handleWidthChange = e => {
    const tmpWidth = e.target.value;
    console.log("here is tmpWidth");
    console.log(tmpWidth);
    const actualHeight = selected.mts_Height;
    let key = 0;
    let tmp;
    let tmpError = error;
    //quick logic
    if (selected.mts_Style !== "baybow") {
      for (let index = 0; index < windowOptions.restriction.length; index++) {
        if (
          tmpWidth <= windowOptions.restriction[index].max_width &&
          tmpWidth >= windowOptions.restriction[index].min_width
        ) {
          if (
            actualHeight <= windowOptions.restriction[index].max_height &&
            actualHeight >= windowOptions.restriction[index].min_height
          ) {
            //no error
            tmpError.width = false;
            tmpError.height = false;
            key = index;

            break;
          } else {
            //error on height
            tmpError.height = true;
            tmpError.width = false;
            key = index;

            break;
          }
        } else {
          //error on width in every case so the whole thing is not valid

          //if height is correct lets set some guidelines for width
          if (
            actualHeight <= windowOptions.restriction[index].max_height &&
            actualHeight >= windowOptions.restriction[index].min_height
          ) {
            tmpError.width = true;
            tmpError.height = false;
          } else {
            //still erroring
            tmpError.width = true;
            tmpError.height = true;
            key = index;
          }
        }

        console.log("here is key");
        console.log(key);
        setWidthGuide(
          `${windowOptions.restriction[key].min_width}" to ${windowOptions.restriction[key].max_width}"`
        );
        setHeightGuide(
          `${windowOptions.restriction[key].min_height}" to ${windowOptions.restriction[key].max_height}"`
        );
      }
    } else {
      //check based on preset
      let obj = windowOptions.restriction.filter(
        lites => lites.lite_count === selected.mts_Lite
      );

      if (tmpWidth >= obj[0].min_width && tmpWidth <= obj[0].max_width) {
        if (
          actualHeight >= obj[0].min_height &&
          actualHeight <= obj[0].max_height
        ) {
          //no error in either
          console.log("one");
          tmpError.width = false;
          tmpError.height = false;
        } else {
          //error in height
          console.log("two");
          tmpError.width = false;
          tmpError.height = true;
        }
      } else {
        //error in width at least
        if (
          actualHeight >= obj[0].min_height &&
          actualHeight <= obj[0].max_height
        ) {
          //no height error
          console.log("three");
          tmpError.width = true;
          tmpError.height = false;
        } else {
          console.log("four");
          tmpError.width = true;
          tmpError.height = true;
        }
      }
    }

    tmp = selected;
    tmp.mts_Width = e.target.value;

    setError({ ...tmpError });

    setSelected({ ...tmp });
  };

  //@TO-DO - figure out and test more
  const handleHeightChange = e => {
    const tmpHeight = e.target.value;
    const actualWidth = selected.mts_Width;
    let tmp = selected;
    let tmpError = error;
    let key = 0;
    //quick logic for everything but bayboy
    if (selected.mts_Style !== "baybow") {
      for (let index = 0; index < windowOptions.restriction.length; index++) {
        //height takes precendence
        if (
          tmpHeight <= windowOptions.restriction[index].max_height &&
          tmpHeight >= windowOptions.restriction[index].min_height
        ) {
          if (
            actualWidth <= windowOptions.restriction[index].max_width &&
            actualWidth >= windowOptions.restriction[index].min_width
          ) {
            //no error
            tmpError.width = false;
            tmpError.height = false;
            key = index;
            break;
          } else {
            //error on width
            tmpError.width = true;
            tmpError.height = false;
            key = index;
            break;
          }
        } else {
          //error on height in every case so the whole thing is not valid

          //if width is correct lets set some guidelines for height
          if (
            actualWidth <= windowOptions.restriction[index].max_width &&
            actualWidth >= windowOptions.restriction[index].min_width
          ) {
            tmpError.height = true;
            tmpError.width = false;

            key = index;
          } else {
            //still erroring
            tmpError.width = true;
            tmpError.height = true;
          }
        }
      }
      setWidthGuide(
        `${windowOptions.restriction[key].min_width}" to ${windowOptions.restriction[key].max_width}"`
      );
      setHeightGuide(
        `${windowOptions.restriction[key].min_height}" to ${windowOptions.restriction[key].max_height}"`
      );
    } else {
      console.log("in bay bow");
      //check based on preset
      let obj = windowOptions.restriction.filter(
        lites => lites.lite_count === selected.mts_Lite
      );

      if (tmpHeight >= obj[0].min_height && tmpHeight <= obj[0].max_height) {
        if (
          actualWidth >= obj[0].min_width &&
          actualWidth <= obj[0].max_width
        ) {
          //no error in either
          console.log("one");
          tmpError.width = false;
          tmpError.height = false;
        } else {
          //error in width
          console.log("two");
          tmpError.width = true;
          tmpError.height = false;
        }
      } else {
        //error in height at least
        if (
          actualWidth >= obj[0].min_width &&
          actualWidth <= obj[0].max_width
        ) {
          //no width error
          console.log("three");
          tmpError.width = false;
          tmpError.height = true;
        } else {
          console.log("four");
          tmpError.width = true;
          tmpError.height = true;
        }
      }
    }

    tmp = selected;
    tmp.mts_Height = tmpHeight;
    setError({ ...tmpError });
    setSelected({ ...tmp });
  };

  const formSubmit = e => {
    e.preventDefault();
    console.log(error);
    console.log("here is windowoption");
    console.log(windowOptions);
    error.width === false && error.height === false
      ? cont(e)
      : formButtonClick();
  };
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  const cont = e => {
    if (selected.mts_Style === "sl3") {
      let tmp2 = selected;
      if (tmp2.mts_Options.length !== 1) {
        tmp2.mts_Options = ["three_equal_section_slider"];
      }
      setSelected(tmp2);
    }
    !isEdit && setSelected({ ...selected, PRODUCT_ID: uuidv4() });
    calculatePrice();
    setShowPrice(true);
  };

  const setLocation = e => {
    setSelected({ ...selected, mts_DemarcationLocation: e.target.value });
  };

  const changeNote = e => {
    setSelected({ ...selected, mts_Notes: e.target.value });
  };

  const checkBoxClick = e => {
    let tmp = selected;
    if (e.target.value === "grids") {
      tmp.mts_gridpattern = e.target.checked ? "gardenbay grid" : "";
      console.log("clicked grid");
    } else {
      e.target.checked
        ? tmp.mts_Options.push(e.target.value)
        : (tmp.mts_Options = tmp.mts_Options.filter(v => v !== e.target.value));
    }

    console.log("here is tmp");
    console.log(tmp);
    setSelected({ ...tmp });
  };

  const checkBoxClickTrim = e => {
    let tmp = selected;
    e.target.checked
      ? tmp.mts_Trim.push(e.target.value)
      : (tmp.mts_Trim = tmp.mts_Trim.filter(v => v !== e.target.value));
    console.log("here is tmp");
    console.log(tmp);
    setSelected({ ...tmp });
  };

  const removalToggle = e => {
    console.log("being clicked");
    console.log(e.target.value);
    let tmp = selected;

    tmp.mts_RemovalOptions = e.target.value;
    console.log(tmp);
    console.log(selected);
    setSelected(tmp);
  };
  const operatingLiteChange = e => {
    let tmp = selected;

    tmp.mts_OperatingLite = e.target.value;
    setSelected({ ...tmp });
  };
  const liteChange = e => {
    let tmp = selected;
    let tmpError = error;
    tmp.mts_Lite = e.target.value;
    let obj;
    if (selected.mts_Style === "cs") {
      //do nothing with this cool info
      setSelected({ ...tmp });
    } else {
      //set the guides as well
      setSelected({ ...tmp });
      //get height and width
      console.log(e.target.value);
      let obj = windowOptions.restriction.filter(
        lites => lites.lite_count === e.target.value
      );
      tmpError.height =
        selected.mts_Height < obj[0].min_height ||
        selected.mts_Height > obj[0].max_height
          ? true
          : false;
      tmpError.width =
        selected.mts_Width < obj[0].min_width ||
        selected.mts_Width > obj[0].max_width
          ? true
          : false;
      setError({ ...tmpError });
      setWidthGuide(`${obj[0].min_width}" to ${obj[0].max_width}"`);
      setHeightGuide(`${obj[0].min_height}" to ${obj[0].max_height}"`);
    }
  };

  const calculatePrice = async () => {
    //@WORKING
    console.log("calc price function");
    console.log(selected);

    let basePrice = getWindowPrice({ selected, windowOptions });

    console.log("here is base price");
    console.log(basePrice);

    let glassPrice =
      selected.mts_Style.toLowerCase() !== "baybow" &&
      selected.mts_Style.toLowerCase() !== "gsld" &&
      selected.mts_Style.toLowerCase() !== "bay"
        ? getGlassPrice({ selected, windowOptions })
        : 0.0;

    const grid = await pickGridPattern(selected);
    console.log("manufacturing grid price before");
    console.log(grid.price);

    const gridPrice =
      selected.mts_Style.toLowerCase() !== "cs"
        ? grid.price *
          selected.mts_sashCount *
          allUp[selected.mts_Style.toLowerCase()].options.grids
        : grid.price *
          selected.mts_sashCount *
          allUp[selected.mts_Style.toLowerCase()].options.grids *
          selected.mts_Lite;
    console.log("grid price after sashing");
    console.log(grid.price * selected.mts_sashCount);
    console.log("grid price after multiplier");
    console.log(gridPrice);
    console.log("grid price after casing");
    console.log(
      grid.price *
        selected.mts_sashCount *
        allUp[selected.mts_Style.toLowerCase()].options.grids *
        selected.mts_Lite
    );
    let tmp = selected;
    selected.mts_gridpattern = grid.name === "---" ? "" : grid.name;

    if (tmp.tmpGridStyle.toLowerCase() === "none") {
      tmp.mts_gridtype = "";
      tmp.gridPattern = "";
    }

    // setSelected(tmp);
    let windowPriceObj = {
      window_price: {
        name: selected.mts_Style.toLowerCase() !== "gsld" ? "Window" : "Door",
        type: `${selected.mts_Style} ${selected.mts_DemarcationLocation}`,
        price: basePrice
      },
      glass_price: {
        name: "Glass",
        type: selected.mts_GlassType,
        price: glassPrice
      },
      grid_price: {
        name: "Grid",
        type: grid.name,
        price: gridPrice
      }
    };
    console.log("fixing windowoprtions");
    console.log(windowOptions);
    //add transom grid price
    if (selected.mts_Style.toLowerCase() === "gsld") {
      //grab transom price
      const transom = windowOptions.transoms[selected.mts_color.toLowerCase()][
        selected.mts_TransomGrid
      ].filter(v => v.height === selected.mts_TransomHeight);
      console.log("here is transom price");
      console.log(transom[0].price);
      windowPriceObj["transom_price"] = {
        name: "Transom",
        type: `${selected.mts_TransomHeight}`,
        price: transom[0].price
      };
      windowPriceObj["transom_grid_price"] = {
        name: "Transom Grid",
        type: `${selected.mts_TransomGrid.replace(/[^0-9a-z]/gi, " ")}`,
        price: 0.0
      };
      //grab sidelite price
      const sidelite = windowOptions.sidelites[
        selected.mts_color.toLowerCase()
      ][selected.mts_SideliteGrid].filter(
        v => v.height === selected.mts_SideliteHeight
      );
      console.log("here is sidelite price");
      console.log(sidelite[0].price);
      windowPriceObj["sidelite_price"] = {
        name: "Sidelite",
        type: `${selected.mts_SideliteHeight}`,
        price: sidelite[0].price
      };
      windowPriceObj["sidelite_grid_price"] = {
        name: "Sidelite Grid",
        type: `${selected.mts_SideliteGrid.replace(/[^0-9a-z]/gi, " ")}`,
        price: 0.0
      };
    }

    selected.mts_Options.forEach((option, i) => {
      let optionPrice = getOptionPrice({ selected, windowOptions, option });
      console.log("option price");
      console.log(option);
      console.log(optionPrice);

      console.log(optionPrice * selected.mts_Quantity);
      windowPriceObj[`${option}_price`] = {
        name: "Option",
        type: noSpecialChar(option),
        price: optionPrice
      };
    });

    //get option price for the last two options of baybow
    if (selected.mts_Style.toLowerCase() === "bay") {
      windowPriceObj["casement_vent_price"] = {
        name: "Option",
        type: noSpecialChar("casement vents"),
        price:
          windowOptions.number_options.casement_vents[0].price *
          allUp.baybow.options.casement_vents *
          selected.mts_Vents
      };
      windowPriceObj["dh_tilt_price"] = {
        name: "Option",
        type: noSpecialChar("dh tilt"),
        price:
          windowOptions.number_options.dh_tilt[0].price *
          allUp.baybow.options.dh_tilt *
          selected.mts_DHTilts
      };
      console.log(windowPriceObj);
    }

    //no longer a array
    console.log("removal type single pricing");
    console.log(
      allUp.removal[selected.mts_RemovalOptions.toLowerCase()]["price"]
    );
    windowPriceObj[`${selected.mts_RemovalOptions}_price`] = {
      name: "Removal Option",
      type: noSpecialChar(
        allUp.removal[selected.mts_RemovalOptions.toLowerCase()]["name"]
      ),
      price: allUp.removal[selected.mts_RemovalOptions.toLowerCase()]["price"]
    };

    //trim
    selected.mts_Trim.forEach((option, i) => {
      windowPriceObj[`${option}_price`] = {
        name: "Trim",
        type: noSpecialChar(option),
        price: allUp.trim[option].price
      };
    });

    console.log("here is window price");
    console.log(windowPriceObj);

    setFinalWindowPrice({ ...windowPriceObj });
  };

  let el = loading ? (
    <OtherLoader />
  ) : (
    <>
      <DisplayWindow
        finalVal={finalVal}
        setFinalVal={setFinalVal}
        windowPrice={finalWindowPrice}
        selected={selected}
        id="modalBackground"
      />
      <FormCard show={show}>
        <form onSubmit={formSubmit}>
          <h1>{windowOptions.name}</h1>
          <FormControl className="inputMargin">
            <InputLabel htmlFor="location">Location</InputLabel>
            <Input
              placeholder="Location"
              value={selected.mts_DemarcationLocation}
              onChange={setLocation}
              required="true"
            />
          </FormControl>

          <FormControl className="inputMargin">
            <Typography id="continuous-slider" gutterBottom>
              {`Quantity: ${selected.mts_Quantity}`}
            </Typography>
            <Slider
              valueLabelDisplay="auto"
              id="continuous-slider"
              name="quantity"
              label="quantity"
              value={selected.mts_Quantity}
              onChange={handleQuantityChange}
              min={1}
              max={30}
              step={1}
            />
          </FormControl>

          {windowOptions.lite_count && selected.mts_Style !== "bay" && (
            <FormControl>
              <InputLabel>Lite Count</InputLabel>
              <Select value={selected.mts_Lite} onChange={liteChange} required>
                {windowOptions.lite_count.map(v => {
                  return (
                    <MenuItem value={v}>
                      {v === 1 ? `${v} Lite` : `${v} Lites`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {windowOptions.operating_lite && (
            <FormControl>
              <InputLabel>Operating Lite Count</InputLabel>
              <Select
                value={selected.mts_OperatingLite}
                onChange={operatingLiteChange}
                required
              >
                {windowOptions.operating_lite
                  .slice(0, selected.mts_Lite + 1)
                  .map(v => {
                    return (
                      <MenuItem value={v}>
                        {v === 1 ? `${v} Lite` : `${v} Lites`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
          {selected.mts_Style !== "gsld" && (
            <FormControl className="inputMargin">
              <TextField
                label={`Width (Inches) - (${widthGuide})`}
                id="mts_Width"
                type="number"
                inputProps={{ step: 0.01 }}
                onChange={handleWidthChange}
                value={selected.mts_Width}
                required="true"
                error={error.width}
              />
            </FormControl>
          )}
          {selected.mts_Style !== "gsld" && (
            <FormControl className="inputMargin">
              <TextField
                label={`Height (Inches) - (${heightGuide})`}
                id="mts_Height"
                type="number"
                inputProps={{ step: 0.01 }}
                onChange={handleHeightChange}
                value={selected.mts_Height}
                required="true"
                error={error.height}
              />
            </FormControl>
          )}
          {windowOptions.glass &&
          Object.keys(windowOptions.glass).length > 0 ? (
            <FormControl
              placeholder="Select Glass Option"
              className="inputMargin"
            >
              <InputLabel htmlFor="glassOption">Glass Options</InputLabel>
              <Select
                value={selected.mts_GlassType}
                onChange={selectClickHandler}
                name="mts_GlassType"
                id="mts_GlassType"
                autoWidth={true}
              >
                {Object.keys(windowOptions.glass).map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {windowOptions.colors &&
          Object.keys(windowOptions.colors).length > 0 ? (
            <FormControl
              placeholder="Select Window Color"
              className="inputMargin"
            >
              <InputLabel htmlFor="glassOption">Color Options</InputLabel>
              <Select
                value={selected.mts_color}
                onChange={selectClickHandler}
                name="mts_color"
                id="mts_color"
                autoWidth={true}
              >
                {Object.keys(windowOptions.colors).map((color, index) => (
                  <MenuItem key={index} value={color}>
                    {color.replace(/[^0-9a-z]/gi, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          {selected.mts_Style === "gsld" &&
            windowOptions.colors &&
            Object.keys(windowOptions.colors.white.no_grids).length > 0 && (
              <>
                <h2>Glass Door</h2>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <FormControl
                    placeholder="Select Door Height"
                    className="inputMargin"
                    style={{ margin: "0 1em 0 1em" }}
                  >
                    <InputLabel htmlFor="doorHeight">Height</InputLabel>
                    <Select
                      value={selected.mts_DoorHeight}
                      onChange={selectClickHandler}
                      name="mts_DoorHeight"
                      id="mts_DoorHeight"
                      autoWidth={true}
                    >
                      {windowOptions.colors.white.no_grids.map((i, index) => (
                        <MenuItem key={index} value={i.height}>
                          {i.height}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    placeholder="Select Door Grid"
                    className="inputMargin"
                    style={{ margin: "0 1em 0 1em" }}
                  >
                    <InputLabel htmlFor="doorGrid">Grid Type</InputLabel>
                    <Select
                      value={selected.mts_DoorGrid}
                      onChange={selectClickHandler}
                      name="mts_DoorGrid"
                      id="mts_DoorGrid"
                      autoWidth={true}
                    >
                      {Object.keys(windowOptions.colors.white).map(
                        (i, index) => (
                          <MenuItem key={index} value={i}>
                            {i.replace(/[^0-9a-z]/gi, " ")}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>

                <h2>Transoms</h2>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <FormControl
                    placeholder="Select Transom Height"
                    className="inputMargin"
                    style={{ margin: "0 1em 0 1em" }}
                  >
                    <InputLabel htmlFor="transomHeight">Height</InputLabel>
                    <Select
                      value={selected.mts_TransomHeight}
                      onChange={selectClickHandler}
                      name="mts_TransomHeight"
                      id="mts_TransomHeight"
                      autoWidth={true}
                    >
                      {windowOptions.transoms.white.no_grids.map((i, index) => (
                        <MenuItem key={index} value={i.height}>
                          {i.height}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    placeholder="Select Transom Grid"
                    className="inputMargin"
                    style={door.transGrid}
                  >
                    <InputLabel htmlFor="doorGrid">Grid Type</InputLabel>
                    <Select
                      value={selected.mts_TransomGrid}
                      onChange={selectClickHandler}
                      name="mts_TransomGrid"
                      id="mts_TransomGrid"
                      autoWidth={true}
                    >
                      {Object.keys(windowOptions.transoms.white).map(
                        (i, index) => (
                          <MenuItem key={index} value={i}>
                            {i.replace(/[^0-9a-z]/gi, " ")}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>

                <h2>Sidelites</h2>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <FormControl
                    placeholder="Select Sidelite Height"
                    className="inputMargin"
                    style={{ margin: "0 1em 0 1em" }}
                  >
                    <InputLabel htmlFor="sideliteHeight">Height</InputLabel>
                    <Select
                      value={selected.mts_SideliteHeight}
                      onChange={selectClickHandler}
                      name="mts_SideliteHeight"
                      id="mts_SideliteHeight"
                      autoWidth={true}
                    >
                      {windowOptions.sidelites.white.no_grids.map(
                        (i, index) => (
                          <MenuItem key={index} value={i.height}>
                            {i.height}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    placeholder="Select Sidelite Grid"
                    className="inputMargin"
                    style={door.sideGrid}
                  >
                    <InputLabel htmlFor="sideliteGrid">Grid Type</InputLabel>
                    <Select
                      value={selected.mts_SideliteGrid}
                      onChange={selectClickHandler}
                      name="mts_SideliteGrid"
                      id="mts_SideliteGrid"
                      autoWidth={true}
                    >
                      {Object.keys(windowOptions.sidelites.white).map(
                        (i, index) => (
                          <MenuItem key={index} value={i}>
                            {i.replace(/[^0-9a-z]/gi, " ")}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}

          {selected.mts_Style !== "gardenbay" &&
            selected.mts_Style !== "bay" &&
            selected.mts_Style !== "baybow" &&
            selected.mts_Style !== "gsld" && (
              <StyledGrid>
                <Typography className="gridTitle">Grid Options</Typography>
                <div className="gridOptions">
                  <div className="colonial" id="colonial" onClick={gridClick}>
                    <h3 id="colonial">Colonial</h3>
                    <img
                      className={`g_option ${clicked.colonial}`}
                      id="colonial"
                      alt="colonial example grids"
                      src={colonial}
                    />
                  </div>
                  <div className="anne" id="queenanne" onClick={gridClick}>
                    <h3 id="queenanne">Queen Anne</h3>
                    <img
                      className={`g_option ${clicked.queenanne}`}
                      id="queenanne"
                      alt="queen anne example grids"
                      src={queen_anne}
                    />
                  </div>
                  <div className="diamond" id="diamond" onClick={gridClick}>
                    <h3 id="diamond">Diamond</h3>
                    <img
                      className={`g_option ${clicked.diamond}`}
                      id="diamond"
                      alt="diamond example grids"
                      src={diamond}
                    />
                  </div>
                  <div className="none" id="none" onClick={gridClick}>
                    <h3 id="none">None</h3>
                    <img
                      className={`g_option ${clicked.none}`}
                      id="none"
                      alt="no grids"
                      src={none}
                    />
                  </div>
                </div>
                {/* <StyledButton
  className={windowButton}
  margin="15px auto"
  onClick={viewSampleWindow}
  style={{ paddingBottom: "1em" }}
>
  view sample window
</StyledButton> */}
              </StyledGrid>
            )}

          {/* SL3 Options */}
          {selected.mts_Style === "sl3" && windowOptions.options && (
            <FormControl className="inputMargin">
              <h2>Window Options</h2>
              <div>
                <RadioGroup
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    width: "100%"
                  }}
                  defaultValue={"three_equal_section_slider"}
                  onChange={checkBoxClick}
                >
                  {Object.keys(windowOptions.options).map((option, index) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio color="primary" />}
                      label={option.replace(/[^0-9a-z]/gi, " ")}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </div>
            </FormControl>
          )}
          {/* Options */}
          {selected.mts_Style !== "sl3" &&
          windowOptions.options &&
          Object.keys(windowOptions.options).length > 0 ? (
            <FormControl className="inputMargin">
              <h2>Options</h2>
              {selected.mts_Style === "bay" && (
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <FormControl
                    placeholder="Select Glass Option"
                    style={{ margin: "0 1em 0 1em " }}
                  >
                    <InputLabel htmlFor="ventOption">Optional Vents</InputLabel>
                    <Select
                      onChange={selectClickHandler}
                      name="mts_Vents"
                      id="mts_Vents"
                      autoWidth={false}
                      value={
                        selected.mts_Vents === 2
                          ? "casement"
                          : selected.mts_DHTilts === 2
                          ? "double_hung"
                          : "none"
                      }
                    >
                      {/* {Array.from(Array(selected.mts_Lite + 1).keys()).map(
                        (v, i) => {
                          return (
                            <MenuItem key={i} value={v}>
                              {`${v} vents`}
                            </MenuItem>
                          );
                        }
                      )} */}
                      <MenuItem value="none">{`none`}</MenuItem>
                      <MenuItem value="double_hung">
                        {`Double Hung Vents`}
                      </MenuItem>
                      <MenuItem value="casement">{`Casement Vents`}</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <FormControl
                    placeholder="Select Glass Option"
                    style={{ margin: "0 1em 0 1em " }}
                  >
                    <InputLabel htmlFor="tiltOption">
                      Double Hung Tilts
                    </InputLabel>
                    <Select
                      onChange={selectClickHandler}
                      name="mts_DHTilts"
                      id="mts_DHTilts"
                      autoWidth={false}
                      value={selected.mts_DHTilts}
                    >
                      {Array.from(Array(selected.mts_Lite + 1).keys()).map(
                        (v, i) => {
                          return (
                            <MenuItem key={i} value={v}>
                              {`${v} tilts`}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl> */}
                </div>
              )}

              <div
                style={
                  selected.mts_Style === "bay" ||
                  selected.mts_Style === "baybow"
                    ? optionStyle.baybow
                    : optionStyle.other
                }
              >
                {Object.keys(windowOptions.options).map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          option !== "grids"
                            ? selected.mts_Options.find(v => v === option)
                              ? true
                              : false
                            : selected.mts_gridpattern === "gardenbay grid"
                            ? true
                            : false
                        }
                      />
                    }
                    label={option.replace(/[^0-9a-z]/gi, " ")}
                    onClick={checkBoxClick}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}
          {/* Removal Options */}
          {allUp.removal && Object.keys(allUp.removal).length > 0 ? (
            <FormControl className="inputMargin">
              <h2>Removal Options</h2>
              <div>
                <RadioGroup
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    width: "100%"
                  }}
                  defaultValue={selected.mts_RemovalOptions}
                  onChange={removalToggle}
                >
                  {Object.keys(allUp.removal).map((option, index) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio color="primary" />}
                      label={allUp.removal[option].name}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </div>
            </FormControl>
          ) : null}
          {/* Trim Options */}
          {allUp.trim && Object.keys(allUp.trim).length > 0 ? (
            <FormControl className="inputMargin">
              <h2>Trim Options</h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  width: "100%"
                }}
              >
                {Object.keys(allUp.trim).map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          selected.mts_Trim.find(v => v === option)
                            ? true
                            : false
                        }
                      />
                    }
                    label={allUp.trim[option].name}
                    onClick={checkBoxClickTrim}
                  />
                ))}
              </div>
            </FormControl>
          ) : null}

          <FormControl className="inputMargin">
            <TextField
              label="Notes"
              id="mts_Notes"
              type="text"
              multiline="true"
              rows="5"
              variant="outlined"
              onChange={changeNote}
              value={selected.mts_Notes}
              required="true"
            />
          </FormControl>

          <StyledButton
            className={`${priceValid.valid}`}
            margin="15px auto"
            type="submit"
          >
            PRICE THIS WINDOW
          </StyledButton>
          <StyledButton
            className={`${priceValid.invalid}`}
            style={{ opacity: "0.4", cursor: "not-allowed" }}
            disabled
            margin="15px auto"
            type="submit"
          >
            PRICE THIS WINDOW
          </StyledButton>
        </form>
      </FormCard>
      <CustomModal
        show={modalShow}
        onClose={modalClickHandler}
        buttonActive={hideButton}
      >
        <StyledGrid style={{ border: "none" }} className={`${validGrid.main}`}>
          <div className={`colonial`} id="contour" style={{ width: "100%" }}>
            <div
              style={{
                background: "red",
                width: "60%",
                height: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
                boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                backgroundColor: "#FFD2D2",
                color: "#D8000C"
              }}
            >
              <h3>Whoops</h3>
              Window Size is not valid for grid
            </div>
            <StyledButton
              style={{ background: "red" }}
              onClick={modalClickHandler}
            >
              <b>BACK</b>
            </StyledButton>
          </div>
        </StyledGrid>
        <StyledGrid className={`${validGrid.others}`}>
          <Typography className={`gridTitle`}>Grid Options</Typography>
          <div
            className="gridOptions"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div className={`colonial`} id="flat" onClick={clickFlat}>
              <h3 id="flat">Flat</h3>
              <img
                className={`g_option ${clicked.flat}`}
                id="flat"
                alt="flat grid option"
                src={flat}
              />
            </div>
            <div
              className={`colonial ${gOption}`}
              id="contour"
              onClick={clickContour}
            >
              <h3 id="contour" className={`${gOption}`}>
                Contour
              </h3>
              <img
                className={`g_option ${gOption} ${clicked.contour}`}
                id="contour"
                alt="contour grids"
                src={contour}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <FormControl
              placeholder="Select Window Color"
              className={`inputMargin gridColor ${gColorClass}`}
              style={{ width: "75%", padding: "0" }}
            >
              <InputLabel htmlFor="glassOption">Grid Color Options</InputLabel>

              {selected.tmpGridStyle !== "diamond" ? (
                <Select
                  required
                  value={selected.mts_gridtype}
                  onChange={selectClickHandler}
                  name="mts_gridtype"
                  id="mts_gridtype"
                >
                  <MenuItem value="FW">WHITE</MenuItem>
                  <MenuItem value="FA">ALMOND</MenuItem>
                  <MenuItem value="FT">COCOA</MenuItem>
                </Select>
              ) : (
                <Select
                  value={selected.mts_gridtype}
                  onChange={selectClickHandler}
                  name="mts_gridtype"
                  id="mts_gridtype"
                >
                  <MenuItem value="DW">WHITE</MenuItem>
                  <MenuItem value="DA">ALMOND</MenuItem>
                  <MenuItem value="DT">COCOA</MenuItem>
                </Select>
              )}
            </FormControl>

            {/* only display if dh
            {selected.mts_Style === "dh" &&
              selected.tmpGridStyle === "colonial" && (
                <FormControl style={{ width: "75%" }}>
                  <InputLabel htmlFor="sashCount">Grid Formation</InputLabel>
                  <Select
                    onChange={selectClickHandler}
                    name="mts_gridpattern"
                    id="mts_gridpattern"
                    value={"2Vx1H"}
                  >
                    {allGrid["data"]["COLONIAL"]["dh"] &&
                      Object.keys(allGrid["data"]["COLONIAL"]["dh"]).map(v => {
                        return (
                          <MenuItem key={v} value={v}>
                            {allGrid["data"]["COLONIAL"]["dh"][v].name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )} */}

            {/* only display if sl */}
            {(selected.mts_Style === "sl" || selected.mts_Style === "sl3") &&
              selected.mts_Height < 48.1 &&
              selected.tmpGridStyle === "colonial" && (
                <FormControl style={{ width: "75%" }}>
                  <InputLabel htmlFor="sashCount">Grid Formation</InputLabel>
                  {/* <Select
                    value={selected.mts_gridpattern}
                    onChange={selectClickHandler}
                    name="mts_gridpattern"
                    id="mts_gridpattern"
                    defaultValue={
                      allGrid["data"]["COLONIAL"]["sl"]["1Vx2H"].name
                    }
                  >
                    {allGrid["data"]["COLONIAL"]["sl"] &&
                      Object.keys(allGrid["data"]["COLONIAL"]["sl"]).map(v => {
                        return (
                          <MenuItem key={v} value={v}>
                            {allGrid["data"]["COLONIAL"]["sl"][v].name}
                          </MenuItem>
                        );
                      })}
                  </Select> */}
                </FormControl>
              )}

            <FormControl style={{ width: "75%" }}>
              <InputLabel htmlFor="sashCount">Sashes to Grid</InputLabel>
              <Select
                value={selected.mts_sashCount}
                onChange={selectClickHandler}
                name="mts_sashCount"
                id="mts_sashCount"
              >
                {windowOptions.sash &&
                  windowOptions["sash"].map(v => {
                    return (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        </StyledGrid>
      </CustomModal>
      <CustomModal
        show={modalWindowShow}
        onClose={modalWindowClickHandler}
        value="CLOSE"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h1>{`${firstCharCap(
            codeToName(selected.mts_Style)
          )} - ${firstCharCap(selected.mts_color)}`}</h1>
          <div
            style={{
              height: "auto",
              backgroundImage: `url(${background})`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "thin",
              borderRadius: "5px",
              boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
            }}
          >
            <img height="auto" src={windowImage} alt="window sample" />
          </div>
        </div>
      </CustomModal>
    </>
  );

  return <div>{el}</div>;
};

export default Pricer;
