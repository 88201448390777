import React, { useEffect, useContext } from "react";
//components
import Modal from "./styles";
import Pin from "../Pin/Pin";
import { Context } from "../../App";

const PinModal = ({ onClose, width, history }) => {
  const { showPin, show } = useContext(Context);

  const escFunction = e => {
    if (e.keyCode === 27) {
      onClose(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  const handleClick = e => {
    if (e.target.id === "modalBackground") {
      return onClose(e);
    } else {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", handleClick, false);
  }, []);

  return showPin ? (
    <Modal sideNavOpen={show} id="modalBackground" width={width}>
      <div className="modalMain" onClick={e => handleClick(e)}>
        <Pin history={history} />
        {/* <div>HI</div> */}
      </div>
    </Modal>
  ) : null;
};

export default PinModal;
