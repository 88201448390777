import styled from "styled-components";

const ModalStyle = styled.div`
 
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    
    
    
    
  }
.modal {
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  position: fixed; /* Sit on top of the page content */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
 
}

.modalContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.modal .content {
  
  background: white;
  width: 75%;
  height: 80%;
  padding: 1rem;
  

}
table {
    width: 75%;
    
}
td {
    border:1px solid black;
 }
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 15px 15px 0 0;
    
    
}
.modal .actions {
  width: 75%;
  height: 6%;
  background: white;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end; 
}
button:first-of-type {
  justify-self: end;
}

.modal .actions button {
  border: 0;
  color: white;
  background: ${props => props.theme}
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 2.5;
  
}

.actions {
    border-radius: 0 0 15px 15px;
}

button {
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    width: 10%;
    
 
}

.hidden {
  display: none;
}

.hidden.colonial {
  display: none;
}

.clicked {
  border-style:solid;
  border-color: #001649
}

.buttonHide {
  display: none;
}



`;

export { ModalStyle };
