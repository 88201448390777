// const upCharge = {
//   dh: {
//     white: { type: "single", price: 775 },
//     almond: { type: "single", price: 775 * 1.15 },
//     cocoa_white: { type: "single", price: 775 * 1.15 }
//   },
//   pw: {
//     white: { type: "single", price: 975 },
//     almond: { type: "single", price: 975 * 1.15 },
//     cocoa_white: { type: "single", price: 975 * 1.15 }
//   },
//   sl: {
//     white: { type: "single", price: 1022 },
//     almond: { type: "single", price: 1022 * 1.15 },
//     cocoa_white: { type: "single", price: 1022 * 1.15 }
//   },
//   sl3: {
//     white: { type: "single", price: 1485 },
//     almond: { type: "single", price: 1485 * 1.15 },
//     cocoa_white: { type: "single", price: 1485 * 1.15 }
//   },
//   aw: {
//     white: [
//       { type: "single", price: 760 },
//       { type: "single", price: 824 },
//       { type: "single", price: 915 },
//       { type: "single", price: 1230 }
//     ],
//     almond: [
//       { type: "single", price: 760 * 1.15 },
//       { type: "single", price: 824 * 1.15 },
//       { type: "single", price: 915 * 1.15 },
//       { type: "single", price: 1230 * 1.15 }
//     ],
//     cocoa_white: [
//       { type: "single", price: 760 * 1.25 },
//       { type: "single", price: 824 * 1.25 },
//       { type: "single", price: 915 * 1.25 },
//       { type: "single", price: 1230 * 1.25 }
//     ]
//   },
//   cp: {
//     white: [
//       { type: "single", price: 760 },
//       { type: "single", price: 824 },
//       { type: "single", price: 915 },
//       { type: "single", price: 1230 }
//     ],
//     almond: [
//       { type: "single", price: 760 * 1.15 },
//       { type: "single", price: 824 * 1.15 },
//       { type: "single", price: 915 * 1.15 },
//       { type: "single", price: 1230 * 1.15 }
//     ],
//     cocoa_white: [
//       { type: "single", price: 760 * 1.25 },
//       { type: "single", price: 824 * 1.25 },
//       { type: "single", price: 915 * 1.25 },
//       { type: "single", price: 1230 * 1.25 }
//     ]
//   },
//   gardenbay: {
//     white: [
//       { type: "single", price: 995.14 * 3 },
//       { type: "single", price: 1192.77 * 3 },
//       { type: "single", price: 1386.52 * 3 },
//       { type: "single", price: 1514.88 * 3 },
//       //now bigger heights
//       { type: "single", price: 1080.61 * 3 },
//       { type: "single", price: 1281.32 * 3 },
//       { type: "single", price: 1470.15 * 3 },
//       { type: "single", price: 1623.95 * 3 }
//     ],
//     almond: [
//       { type: "single", price: 995.14 * 3 },
//       { type: "single", price: 1192.77 * 3 },
//       { type: "single", price: 1386.52 * 3 },
//       { type: "single", price: 1514.88 * 3 },
//       //now bigger heights
//       { type: "single", price: 1080.61 * 3 },
//       { type: "single", price: 1281.32 * 3 },
//       { type: "single", price: 1470.15 * 3 },
//       { type: "single", price: 1623.95 * 3 }
//     ],
//     cocoa_white: [
//       { type: "single", price: 995.14 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1192.77 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1386.52 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1514.88 * 3 + 353.57 * 1.2 },

//       //now bigger heights
//       { type: "single", price: 1080.61 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1281.32 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1470.15 * 3 + 353.57 * 1.2 },
//       { type: "single", price: 1623.95 * 3 + 353.57 * 1.2 }
//     ]
//   },
//   baybow: {
//     three: [
//       { type: "single", price: 597.53 * 4 },
//       { type: "single", price: 661.33 * 4 },
//       { type: "single", price: 766.86 * 4 },
//       { type: "single", price: 845.38 * 4 },
//       { type: "single", price: 688.34 * 4 },
//       { type: "single", price: 717.77 * 4 },
//       { type: "single", price: 833.11 * 4 },
//       { type: "single", price: 938.64 * 4 }
//     ],
//     four: [
//       { type: "single", price: 707.95 * 4 },
//       { type: "single", price: 807.35 * 4 },
//       { type: "single", price: 971.76 * 4 },
//       { type: "single", price: 1082.19 * 4 },
//       { type: "single", price: 738.63 * 4 },
//       { type: "single", price: 893.24 * 4 },
//       { type: "single", price: 1034.33 * 4 },
//       { type: "single", price: 1152.12 * 4 }
//     ],
//     five: [
//       { type: "single", price: 851.52 * 4 },
//       { type: "single", price: 997.53 * 4 },
//       { type: "single", price: 1157.03 * 4 },
//       { type: "single", price: 1272.37 * 4 },
//       { type: "single", price: 1127.6 * 4 },
//       { type: "single", price: 1231.88 * 4 },
//       { type: "single", price: 1422.04 * 4 },
//       { type: "single", price: 1514.08 * 4 },
//       { type: "single", price: 1186.48 * 4 },
//       { type: "single", price: 1321.45 * 4 },
//       { type: "single", price: 1489.54 * 4 },
//       { type: "single", price: 1613.47 * 4 },
//       { type: "single", price: 1231.88 * 4 },
//       { type: "single", price: 1403.66 * 4 },
//       { type: "single", price: 1572.98 * 4 },
//       { type: "single", price: 1707.94 * 4 },
//       { type: "single", price: 1321.45 * 4 },
//       { type: "single", price: 1493.22 * 4 },
//       { type: "single", price: 1660.09 * 4 },
//       { type: "single", price: 1784.01 * 4 }
//     ]
//   },
//   gsld: {
//     white: {
//       no_grids: [
//         { type: "single", price: 677.92 * 4 },
//         { type: "single", price: 697.98 * 4 },
//         { type: "single", price: 697.98 * 4 },
//         { type: "single", price: 891.48 * 5 },
//         { type: "single", price: 1043.41 * 6 }
//       ],
//       flat_grids: [
//         { type: "single", price: 728.45 * 4 },
//         { type: "single", price: 755.31 * 4 },
//         { type: "single", price: 755.31 * 4 },
//         { type: "single", price: 958.85 * 5 },
//         { type: "single", price: 1129.41 * 6 }
//       ],
//       contour_grids: [
//         { type: "single", price: 728.45 * 4 },
//         { type: "single", price: 755.31 * 4 },
//         { type: "single", price: 755.31 * 4 },
//         { type: "single", price: 958.85 * 5 },
//         { type: "single", price: 1129.41 * 6 }
//       ]
//     },
//     almond: {
//       no_grids: [
//         { type: "single", price: 744.71 * 4 },
//         { type: "single", price: 766.78 * 4 },
//         { type: "single", price: 766.78 * 4 },
//         { type: "single", price: 979.63 * 5 },
//         { type: "single", price: 1146.75 * 6 }
//       ],
//       flat_grids: [
//         { type: "single", price: 800.29 * 4 },
//         { type: "single", price: 829.84 * 4 },
//         { type: "single", price: 829.84 * 4 },
//         { type: "single", price: 1053.73 * 5 },
//         { type: "single", price: 1241.36 * 6 }
//       ],
//       contour_grids: [
//         { type: "single", price: 837.73 * 4 },
//         { type: "single", price: 873.21 * 4 },
//         { type: "single", price: 873.21 * 4 },
//         { type: "single", price: 1106.55 * 5 },
//         { type: "single", price: 1306.39 * 6 }
//       ]
//     },
//     cocoa_white: {
//       no_grids: [
//         { type: "single", price: 1021.55 * 4 },
//         { type: "single", price: 1048.43 * 4 },
//         { type: "single", price: 1048.43 * 4 },
//         { type: "single", price: 1310.01 * 5 },
//         { type: "single", price: 1595.12 * 6 }
//       ],
//       flat_grids: [
//         { type: "single", price: 1102.55 * 4 },
//         { type: "single", price: 1140.16 * 4 },
//         { type: "single", price: 1140.16 * 4 },
//         { type: "single", price: 1417.51 * 5 },
//         { type: "single", price: 1673.33 * 6 }
//       ],
//       contour_grids: [
//         { type: "single", price: 1150.91 * 4 },
//         { type: "single", price: 1194.62 * 4 },
//         { type: "single", price: 1194.62 * 4 },
//         { type: "single", price: 1482.0 * 5 },
//         { type: "single", price: 1755.75 * 6 }
//       ]
//     },
//     white_transoms: {
//       no_grids: [
//         { type: "single", price: 227.86 * 6 },
//         { type: "single", price: 250.08 * 6 },
//         { type: "single", price: 250.08 * 6 },
//         { type: "single", price: 328.19 * 7 },
//         { type: "single", price: 354.7 * 9 }
//       ],
//       flat_grids: [
//         { type: "single", price: 240.04 * 6 },
//         { type: "single", price: 263.7 * 6 },
//         { type: "single", price: 263.7 * 6 },
//         { type: "single", price: 343.24 * 7 },
//         { type: "single", price: 377.63 * 9 }
//       ],
//       contour_grids: [
//         { type: "single", price: 249.36 * 6 },
//         { type: "single", price: 274.45 * 6 },
//         { type: "single", price: 274.45 * 6 },
//         { type: "single", price: 354.7 * 7 },
//         { type: "single", price: 395.55 * 9 }
//       ]
//     },
//     almond_transoms: {
//       no_grids: [
//         { type: "single", price: 249.64 * 6 },
//         { type: "single", price: 274.09 * 6 },
//         { type: "single", price: 274.09 * 6 },
//         { type: "single", price: 360.02 * 7 },
//         { type: "single", price: 389.18 * 9 }
//       ],
//       flat_grids: [
//         { type: "single", price: 263.05 * 6 },
//         { type: "single", price: 289.06 * 6 },
//         { type: "single", price: 289.06 * 6 },
//         { type: "single", price: 376.58 * 7 },
//         { type: "single", price: 414.4 * 9 }
//       ],
//       contour_grids: [
//         { type: "single", price: 273.31 * 6 },
//         { type: "single", price: 300.9 * 6 },
//         { type: "single", price: 300.9 * 6 },
//         { type: "single", price: 389.19 * 7 },
//         { type: "single", price: 434.11 * 9 }
//       ]
//     },
//     cocoa_white_transoms: {
//       no_grids: [
//         { type: "single", price: 392.69 * 6 },
//         { type: "single", price: 435.69 * 6 },
//         { type: "single", price: 435.69 * 6 },
//         { type: "single", price: 578.3 * 7 },
//         { type: "single", price: 625.6 * 9 }
//       ],
//       flat_grids: [
//         { type: "single", price: 412.76 * 6 },
//         { type: "single", price: 457.19 * 6 },
//         { type: "single", price: 457.19 * 6 },
//         { type: "single", price: 601.96 * 7 },
//         { type: "single", price: 662.15 * 9 }
//       ],
//       contour_grids: [
//         { type: "single", price: 423.88 * 6 },
//         { type: "single", price: 470.09 * 6 },
//         { type: "single", price: 470.09 * 6 },
//         { type: "single", price: 615.93 * 7 },
//         { type: "single", price: 683.65 * 9 }
//       ]
//     },
//     white_sidelites: {
//       no_grids: [
//         { type: "single", price: 275.16 * 4 },
//         { type: "single", price: 361.16 * 4 },
//         { type: "single", price: 447.15 * 4 }
//       ],
//       flat_grids: [
//         { type: "single", price: 292.36 * 4 },
//         { type: "single", price: 384.82 * 4 },
//         { type: "single", price: 479.4 * 4 }
//       ],
//       contour_grids: [
//         { type: "single", price: 305.27 * 4 },
//         { type: "single", price: 403.45 * 4 },
//         { type: "single", price: 505.21 * 4 }
//       ]
//     },
//     almond_sidelites: {
//       no_grids: [
//         { type: "single", price: 301.67 * 4 },
//         { type: "single", price: 396.27 * 4 },
//         { type: "single", price: 490.87 * 4 }
//       ],
//       flat_grids: [
//         { type: "single", price: 320.59 * 4 },
//         { type: "single", price: 422.28 * 4 },
//         { type: "single", price: 526.34 * 4 }
//       ],
//       contour_grids: [
//         { type: "single", price: 334.78 * 4 },
//         { type: "single", price: 442.79 * 4 },
//         { type: "single", price: 554.72 * 4 }
//       ]
//     },
//     cocoa_white_sidelites: {
//       no_grids: [
//         { type: "single", price: 490.15 * 4 },
//         { type: "single", price: 637.78 * 4 },
//         { type: "single", price: 782.54 * 4 }
//       ],
//       flat_grids: [
//         { type: "single", price: 518.11 * 4 },
//         { type: "single", price: 676.13 * 4 },
//         { type: "single", price: 835.58 * 4 }
//       ],
//       contour_grids: [
//         { type: "single", price: 534.23 * 4 },
//         { type: "single", price: 699.07 * 4 },
//         { type: "single", price: 867.46 * 4 }
//       ]
//     }
//   },
//   cs: {
//     operating: {
//       white: [
//         { type: "single", price: 718.0 },
//         { type: "single", price: 752.0 },
//         { type: "single", price: 804.0 },
//         { type: "single", price: 1240.0 }
//       ],
//       almond: [
//         { type: "single", price: 718.0 * 1.15 },
//         { type: "single", price: 752.0 * 1.15 },
//         { type: "single", price: 804.0 * 1.15 },
//         { type: "single", price: 1240.0 * 1.15 }
//       ],
//       cocoa_white: [
//         { type: "single", price: 718.0 * 1.25 },
//         { type: "single", price: 752.0 * 1.25 },
//         { type: "single", price: 804.0 * 1.25 },
//         { type: "single", price: 1240.0 * 1.25 }
//       ]
//     },
//     cpw: {
//       white: [
//         { type: "single", price: 640.0 },
//         { type: "single", price: 948.0 },
//         { type: "single", price: 1301.0 }
//       ],
//       almond: [
//         { type: "single", price: 640.0 * 1.15 },
//         { type: "single", price: 948.0 * 1.15 },
//         { type: "single", price: 1301.0 * 1.15 }
//       ],
//       cocoa_white: [
//         { type: "single", price: 640.0 * 1.25 },
//         { type: "single", price: 948.0 * 1.25 },
//         { type: "single", price: 1301.0 * 1.25 }
//       ]
//     }
//   }
// };

import { upCharge } from "./basePriceUpCharge";

export const allWindows = {
  data: {
    dh: {
      name: "DOUBLE HUNG",
      description:
        "Premium fully welded window featuring TPS - Thermo Plastic Spacer technology with superior thermal and structural strength along with double strength glass. Both sashes tilt in for easy cleaning.",
      price_desc:
        'Price includes half extruded locking screen, double strength TPS Insulated Glass, vinyl head expander & sill angle foam enhanced frame, low "e" & argon gas, vent latches, double locks (patented lock and tilt locking hardware)',
      sash: [1, 2],
      restriction: [
        {
          min_width: 14.5,
          max_width: 53,
          min_height: 22.5,
          max_height: 86,
        },
      ],

      colors: {
        white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.dh.white.type,
            price: upCharge.dh.white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.dh.white.type,
            price: upCharge.dh.white.price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.dh.almond.type,
            price: upCharge.dh.almond.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.dh.almond.type,
            price: upCharge.dh.almond.price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.dh.cocoa_white.type,
            price: upCharge.dh.cocoa_white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.dh.cocoa_white.type,
            price: upCharge.dh.cocoa_white.price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 42.5,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 58.5,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 8.5,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 16.5,
            warning: "",
          },
        ],
      },
      options: {
        full_screens: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 13.98,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "multiply",
            price: 0.28,
            warning: "",
          },
        ],
        oriel_sash: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 33.43,
          },
        ],
        // snap_mullion: [
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "white",
        //     price: 0.07
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "almond",
        //     price: 0.08
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "cocoa_white",
        //     price: 0.15
        //   }
        // ]
      },
    },
    sl: {
      name: "SLIDER (2 LITES)",
      description:
        "Premium fully welded window featuring TPS - Thermo Plastic Spacer technology with superior thermal and structural strength along with double strength glass. Both sashes slide side-to-side on brass rollers and are removable for easy cleaning. Vent latches included on all sliders.",
      price_desc:
        "Price includes 1/2 screen, double strength insulated glass, vinyl head expander and sill angle, foam enhanced frame, low E and argon gas",
      sash: [1, 2],
      restriction: [
        {
          min_width: 23,
          max_width: 104,
          min_height: 14,
          max_height: 66,
        },
      ],

      colors: {
        white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl.white.type,
            price: upCharge.sl.white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl.white.type,
            price: upCharge.sl.white.price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl.almond.type,
            price: upCharge.sl.almond.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl.almond.type,
            price: upCharge.sl.almond.price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl.cocoa_white.type,
            price: upCharge.sl.cocoa_white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl.cocoa_white.type,
            price: upCharge.sl.cocoa_white.price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 42.5,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 58.5,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 8.5,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 16.5,
            warning: "",
          },
        ],
      },
      options: {
        full_screens: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 13.98,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "multiply",
            price: 0.28,
            warning: "",
          },
        ],
        // snap_mullion: [
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "white",
        //     price: 0.07
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "almond",
        //     price: 0.08
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "cocoa_white",
        //     price: 0.15
        //   }
        // ]
      },
    },
    sl3: {
      name: "SLIDER (3 LITES)",
      description:
        "Premium fully welded window featuring TPS - Thermo Plastic Spacer technology with superior thermal and structural strength along with double strength glass. These can be ordered in quarter-half-quarter or 3 equal section configurations. Both ends slide towards the center and are removable for easy cleaning. Vent latches included on all sliders.",
      price_desc:
        "Price includes 2 screens, double strength insulated glass, vinyl head expander and sill angle, foam enhanced frame, low E and argon gas",
      sash: [1, 2, 3],
      restriction: [
        {
          min_width: 23,
          max_width: 104,
          min_height: 14,
          max_height: 66,
        },
      ],

      colors: {
        white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl3.white.type,
            price: upCharge.sl3.white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl3.white.type,
            price: upCharge.sl3.white.price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl3.almond.type,
            price: upCharge.sl3.almond.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl3.almond.type,
            price: upCharge.sl3.almond.price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.sl3.cocoa_white.type,
            price: upCharge.sl3.cocoa_white.price,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: upCharge.sl3.cocoa_white.type,
            price: upCharge.sl3.cocoa_white.price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 170,
            type: "single",
            price: 180,
            warning: "",
          },
          {
            min_ui: 171,
            max_ui: 999,
            type: "max_prev",
            price: 180,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 8.5,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 16.5,
            warning: "",
          },
        ],
      },
      options: {
        // snap_mullion: [
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "white",
        //     price: 0.07
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "almond",
        //     price: 0.08
        //   },
        //   {
        //     min_ui: 0,
        //     max_ui: 999,
        //     type: "cocoa_white",
        //     price: 0.15
        //   }
        // ],
        three_equal_section_slider: [
          { min_ui: 0, max_ui: 999, type: "single", price: 0.0 },
        ],
        quarter_half_quarter_slider: [
          { min_ui: 0, max_ui: 999, type: "single", price: 0.0 },
        ],
      },
    },
    aw: {
      name: "AWNING",
      description: "",
      price_desc:
        "Price includes extruded full screen, double strength glass, foam enhanced frame, low e and argon glass",
      sash: [1],
      restriction: [
        {
          min_width: 18,
          max_width: 52,
          min_height: 15,
          max_height: 36,
        },
      ],

      colors: {
        white: [
          {
            min_ui: 0,
            max_ui: 64,
            type: upCharge.aw.white[0].type,
            price: upCharge.aw.white[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74,
            type: upCharge.aw.white[1].type,
            price: upCharge.aw.white[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84,
            type: upCharge.aw.white[2].type,
            price: upCharge.aw.white[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.aw.white[3].type,
            price: upCharge.aw.white[3].price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 0,
            max_ui: 64,
            type: upCharge.aw.almond[0].type,
            price: upCharge.aw.almond[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74,
            type: upCharge.aw.almond[1].type,
            price: upCharge.aw.almond[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84,
            type: upCharge.aw.almond[2].type,
            price: upCharge.aw.almond[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.aw.almond[3].type,
            price: upCharge.aw.almond[3].price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 0,
            max_ui: 64,
            type: upCharge.aw.cocoa_white[0].type,
            price: upCharge.aw.cocoa_white[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74,
            type: upCharge.aw.cocoa_white[1].type,
            price: upCharge.aw.cocoa_white[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84,
            type: upCharge.aw.cocoa_white[2].type,
            price: upCharge.aw.cocoa_white[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.aw.cocoa_white[3].type,
            price: upCharge.aw.cocoa_white[3].price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 75.0,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 22.48,
            warning: "",
          },
        ],
      },
      options: {},
    },
    pw: {
      name: "PICTURE WINDOW",
      description:
        "Premium fully welded window featuring TPS - Thermo Plastic Spacer technology with superior thermal and structural strength along with double strength glass.",
      price_desc:
        'Price includes double strength Insulated Glass, vinyl head expander & sill angle foam enhanced frame, low "e" & argon gas',
      sash: [1],
      restriction: [
        {
          min_width: 15.5,
          max_width: 72,
          min_height: 15.5,
          max_height: 72,
        },
      ],

      colors: {
        white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.pw.white.type,
            price: upCharge.pw.white.price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.pw.almond.type,
            price: upCharge.pw.almond.price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 0,
            max_ui: 101,
            type: upCharge.pw.cocoa_white.type,
            price: upCharge.pw.cocoa_white.price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 82.5,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 22.48,
            warning: "",
          },
        ],
      },
      options: {},
    },
    gardenbay: {
      name: "VENTANA GARDEN BAY",
      description: "",
      price_desc:
        "Price includes 1 inch insulation board, standard 5.5 inch and 11 inch jamb are available, 17 inch fixed projection from outside of house, 1 shelf, low-e glass argon enhanced, operating trapezoid vents, birch or oak jambs header & seat board",
      sash: [1],
      restriction: [
        {
          min_width: 24,
          max_width: 72,
          min_height: 32,
          max_height: 62,
        },
      ],
      colors: {
        white: [
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.white[0].type,
            price: upCharge.gardenbay.white[0].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.white[1].type,
            price: upCharge.gardenbay.white[1].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.white[2].type,
            price: upCharge.gardenbay.white[2].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.white[3].type,
            price: upCharge.gardenbay.white[3].price,
            warning: "",
          },
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 48,
            max_height: 63,
            type: upCharge.gardenbay.white[4].type,
            price: upCharge.gardenbay.white[4].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 48,
            max_height: 63,
            type: upCharge.gardenbay.white[5].type,
            price: upCharge.gardenbay.white[5].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 48,
            max_height: 63,
            type: upCharge.gardenbay.white[6].type,
            price: upCharge.gardenbay.white[6].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 48,
            max_height: 63,
            type: upCharge.gardenbay.white[7].type,
            price: upCharge.gardenbay.white[7].price,
            warning: "",
          },
        ],
        almond: [
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.almond[0].type,
            price: upCharge.gardenbay.almond[0].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.almond[1].type,
            price: upCharge.gardenbay.almond[1].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.almond[2].type,
            price: upCharge.gardenbay.almond[2].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.almond[3].type,
            price: upCharge.gardenbay.almond[3].price,
            warning: "",
          },
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.almond[4].type,
            price: upCharge.gardenbay.almond[4].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.almond[5].type,
            price: upCharge.gardenbay.almond[5].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.almond[6].type,
            price: upCharge.gardenbay.almond[6].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.almond[7].type,
            price: upCharge.gardenbay.almond[7].price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.cocoa_white[0].type,
            price: upCharge.gardenbay.cocoa_white[0].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.cocoa_white[1].type,
            price: upCharge.gardenbay.cocoa_white[1].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.cocoa_white[2].type,
            price: upCharge.gardenbay.cocoa_white[2].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 32,
            max_height: 47.99,
            type: upCharge.gardenbay.cocoa_white[3].type,
            price: upCharge.gardenbay.cocoa_white[3].price,
            warning: "",
          },
          {
            min_width: 24,
            max_width: 36.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.cocoa_white[4].type,
            price: upCharge.gardenbay.cocoa_white[4].price,
            warning: "",
          },
          {
            min_width: 37,
            max_width: 48.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.cocoa_white[5].type,
            price: upCharge.gardenbay.cocoa_white[5].price,
            warning: "",
          },
          {
            min_width: 49,
            max_width: 60.99,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.cocoa_white[6].type,
            price: upCharge.gardenbay.cocoa_white[6].price,
            warning: "",
          },
          {
            min_width: 61,
            max_width: 72,
            min_height: 48,
            max_height: 62,
            type: upCharge.gardenbay.cocoa_white[7].type,
            price: upCharge.gardenbay.cocoa_white[7].price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
      },
      options: {
        extra_glass_shelf: [
          {
            min_width: 24,
            max_width: 36.99,
            type: "single",
            price: 28.89,
          },
          {
            min_width: 37,
            max_width: 48.99,
            type: "single",
            price: 39.03,
          },
          {
            min_width: 49,
            max_width: 60.99,
            type: "single",
            price: 47.46,
          },
          {
            min_width: 61,
            max_width: 72,
            type: "single",
            price: 58.41,
          },
        ],
        grids: [
          {
            min_width: 24,
            max_width: 36.99,
            type: "single",
            price: 32.55,
          },
          {
            min_width: 37,
            max_width: 48.99,
            type: "single",
            price: 43.41,
          },
          {
            min_width: 49,
            max_width: 60.99,
            type: "single",
            price: 54.26,
          },
          {
            min_width: 61,
            max_width: 72,
            type: "single",
            price: 58.12,
          },
        ],
      },
    },
    baybow: {
      name: "BOW WINDOWS",
      description: "",
      price_desc:
        "Price includes foam enhanced frame and low-e and argon gas, standard jamb - 5.5inches",
      sash: [1, 2],
      lite_count: [3, 4, 5],

      lite_options: [
        {
          count: 3,
          min_width: 45,
          max_width: 60.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.three[0].type,
          price: upCharge.baybow.three[0].price,
        },
        {
          count: 3,
          min_width: 45,
          max_width: 60.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.three[1].type,
          price: upCharge.baybow.three[1].price,
        },
        {
          count: 3,
          min_width: 45,
          max_width: 60.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.three[2].type,
          price: upCharge.baybow.three[2].price,
        },
        {
          count: 3,
          min_width: 45,
          max_width: 60.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.three[3].type,
          price: upCharge.baybow.three[3].price,
        },
        {
          count: 3,
          min_width: 61,
          max_width: 72,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.three[4].type,
          price: upCharge.baybow.three[4].price,
        },
        {
          count: 3,
          min_width: 61,
          max_width: 72,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.three[5].type,
          price: upCharge.baybow.three[5].price,
        },
        {
          count: 3,
          min_width: 61,
          max_width: 72,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.three[6].type,
          price: upCharge.baybow.three[6].price,
        },
        {
          count: 3,
          min_width: 61,
          max_width: 72,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.three[7].type,
          price: upCharge.baybow.three[7].price,
        },
        {
          count: 4,
          min_width: 66,
          max_width: 84.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.four[0].type,
          price: upCharge.baybow.four[0].price,
        },
        {
          count: 4,
          min_width: 66,
          max_width: 84.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.four[1].type,
          price: upCharge.baybow.four[1].price,
        },
        {
          count: 4,
          min_width: 66,
          max_width: 84.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.four[2].type,
          price: upCharge.baybow.four[2].price,
        },
        {
          count: 4,
          min_width: 66,
          max_width: 84.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.four[3].type,
          price: upCharge.baybow.four[3].price,
        },
        {
          count: 4,
          min_width: 85,
          max_width: 96,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.four[4].type,
          price: upCharge.baybow.four[4].price,
        },
        {
          count: 4,
          min_width: 85,
          max_width: 96,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.four[5].type,
          price: upCharge.baybow.four[5].price,
        },
        {
          count: 4,
          min_width: 85,
          max_width: 96,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.four[6].type,
          price: upCharge.baybow.four[6].price,
        },
        {
          count: 4,
          min_width: 85,
          max_width: 96,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.four[7].type,
          price: upCharge.baybow.four[7].price,
        },
        {
          count: 5,
          min_width: 85,
          max_width: 96.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.five[0].type,
          price: upCharge.baybow.five[0].price,
        },
        {
          count: 5,
          min_width: 85,
          max_width: 96.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.five[1].type,
          price: upCharge.baybow.five[1].price,
        },
        {
          count: 5,
          min_width: 85,
          max_width: 96.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.five[2].type,
          price: upCharge.baybow.five[2].price,
        },
        {
          count: 5,
          min_width: 85,
          max_width: 96.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.five[3].type,
          price: upCharge.baybow.five[3].price,
        },
        {
          count: 5,
          min_width: 97,
          max_width: 108.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.five[4].type,
          price: upCharge.baybow.five[4].price,
        },
        {
          count: 5,
          min_width: 97,
          max_width: 108.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.five[5].type,
          price: upCharge.baybow.five[5].price,
        },
        {
          count: 5,
          min_width: 97,
          max_width: 108.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.five[6].type,
          price: upCharge.baybow.five[6].price,
        },
        {
          count: 5,
          min_width: 97,
          max_width: 108.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.five[7].type,
          price: upCharge.baybow.five[7].price,
        },
        {
          count: 5,
          min_width: 109,
          max_width: 120.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.five[8].type,
          price: upCharge.baybow.five[8].price,
        },
        {
          count: 5,
          min_width: 109,
          max_width: 120.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.five[9].type,
          price: upCharge.baybow.five[9].price,
        },
        {
          count: 5,
          min_width: 109,
          max_width: 120.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.five[10].type,
          price: upCharge.baybow.five[10].price,
        },
        {
          count: 5,
          min_width: 109,
          max_width: 120.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.five[11].type,
          price: upCharge.baybow.five[11].price,
        },
        {
          count: 5,
          min_width: 121,
          max_width: 132.99,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.five[12].type,
          price: upCharge.baybow.five[12].price,
        },
        {
          count: 5,
          min_width: 121,
          max_width: 132.99,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.five[13].type,
          price: upCharge.baybow.five[13].price,
        },
        {
          count: 5,
          min_width: 121,
          max_width: 132.99,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.five[14].type,
          price: upCharge.baybow.five[14].price,
        },
        {
          count: 5,
          min_width: 121,
          max_width: 132.99,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.five[15].type,
          price: upCharge.baybow.five[15].price,
        },
        {
          count: 5,
          min_width: 133,
          max_width: 144,
          min_height: 36,
          max_height: 45,
          type: upCharge.baybow.five[16].type,
          price: upCharge.baybow.five[16].price,
        },
        {
          count: 5,
          min_width: 133,
          max_width: 144,
          min_height: 45.01,
          max_height: 58,
          type: upCharge.baybow.five[17].type,
          price: upCharge.baybow.five[17].price,
        },
        {
          count: 5,
          min_width: 133,
          max_width: 144,
          min_height: 58.01,
          max_height: 72,
          type: upCharge.baybow.five[18].type,
          price: upCharge.baybow.five[18].price,
        },
        {
          count: 5,
          min_width: 133,
          max_width: 144,
          min_height: 72.01,
          max_height: 78,
          type: upCharge.baybow.five[19].type,
          price: upCharge.baybow.five[19].price,
        },
      ],
      restriction: [
        {
          lite_count: 3,
          min_height: 36,
          max_height: 78,
          min_width: 45,
          max_width: 72,
        },
        {
          lite_count: 4,
          min_height: 36,
          max_height: 78,
          min_width: 66,
          max_width: 96,
        },
        {
          lite_count: 5,
          min_height: 36,
          max_height: 78,
          min_width: 85,
          max_width: 144,
        },
      ],
      number_options: {
        casement_vents: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 134.97,
            warning: "",
          },
        ],
        dh_tilt: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 154.59,
            warning: "",
          },
        ],
      },
      options: {
        cocoa_white_color: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "multiply",
            price: 1.4,
            warning: "",
          },
        ],
        almond_vinyl: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "multiply",
            price: 1.15,
            warning: "",
          },
        ],
        red_oak_seat_board: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 84.48,
          },
        ],
        extended_sill: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 45.4,
          },
        ],
        roof_option: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 61.35,
          },
        ],
        jamb_width_extended: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 128.84,
          },
        ],
        box_bow: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 144.69,
          },
        ],
        white_vinyl_board_construction: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 86.81,
          },
        ],
      },
    },
    bay: {
      name: "BAY WINDOWS",
      description: "",
      price_desc:
        "Price includes foam enhanced frame and low-e and argon gas, standard jamb - 5.5inches",
      sash: [1, 2],
      lite_count: [3],

      lite_options: [
        {
          count: 3,
          min_width: 45,
          max_width: 108.99,
          min_height: 36,
          max_height: 79,
          type: upCharge.bay.three[0].type,
          price: upCharge.bay.three[0].price,
        },
        {
          count: 3,
          min_width: 109,
          max_width: 144,
          min_height: 36,
          max_height: 78,
          type: upCharge.bay.three[1].type,
          price: upCharge.bay.three[1].price,
        },
      ],
      restriction: [
        {
          lite_count: 3,
          min_height: 36,
          max_height: 78,
          min_width: 45,
          max_width: 144,
        },
      ],
      number_options: {
        casement_vents: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 134.97,
            warning: "",
          },
        ],
        dh_tilt: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 154.59,
            warning: "",
          },
        ],
      },
      options: {
        cocoa_white_color: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "multiply",
            price: 1.4,
            warning: "",
          },
        ],
        almond_vinyl: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "multiply",
            price: 1.15,
            warning: "",
          },
        ],
        red_oak_seat_board: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 84.48,
          },
        ],
        extended_sill: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 45.4,
          },
        ],
        roof_option: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 61.35,
          },
        ],
        jamb_width_extended: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 128.84,
          },
        ],
        box_bow: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 144.69,
          },
        ],
        white_vinyl_board_construction: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 86.81,
          },
        ],
      },
    },
    gsld: {
      name: "CAMELOT SLIDING GLASS DOOR",
      description: "",
      price_desc:
        "Price included fully welded frames and sashes, color matched handle set with key cylinder included, extruded aluminum locking screen, transoms and sidelites available, dp 50 performance rating, foot lock included",
      sash: [1],
      //no restriction used
      restriction: [
        {
          min_width: 0,
          max_width: 999,
          min_height: 0,
          max_height: 99,
        },
      ],
      colors: {
        white: {
          no_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white.no_grids[0].type,
              price: upCharge.gsld.white.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white.no_grids[1].type,
              price: upCharge.gsld.white.no_grids[1].price,
              warning: "",
            },

            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white.no_grids[3].type,
              price: upCharge.gsld.white.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white.no_grids[4].type,
              price: upCharge.gsld.white.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white.contour_grids[0].type,
              price: upCharge.gsld.white.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white.contour_grids[1].type,
              price: upCharge.gsld.white.contour_grids[1].price,
              warning: "",
            },

            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white.contour_grids[3].type,
              price: upCharge.gsld.white.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white.contour_grids[4].type,
              price: upCharge.gsld.white.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white.flat_grids[0].type,
              price: upCharge.gsld.white.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white.flat_grids[1].type,
              price: upCharge.gsld.white.flat_grids[1].price,
              warning: "",
            },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white.flat_grids[3].type,
              price: upCharge.gsld.white.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white.flat_grids[4].type,
              price: upCharge.gsld.white.flat_grids[4].price,
              warning: "",
            },
          ],
        },
        almond: {
          no_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond.no_grids[0].type,
              price: upCharge.gsld.almond.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond.no_grids[1].type,
              price: upCharge.gsld.almond.no_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond.no_grids[2].type,
            //   price: upCharge.gsld.almond.no_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond.no_grids[3].type,
              price: upCharge.gsld.almond.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond.no_grids[4].type,
              price: upCharge.gsld.almond.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond.contour_grids[0].type,
              price: upCharge.gsld.almond.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond.contour_grids[1].type,
              price: upCharge.gsld.almond.contour_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond.contour_grids[2].type,
            //   price: upCharge.gsld.almond.contour_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond.contour_grids[3].type,
              price: upCharge.gsld.almond.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond.contour_grids[4].type,
              price: upCharge.gsld.almond.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond.flat_grids[0].type,
              price: upCharge.gsld.almond.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond.flat_grids[1].type,
              price: upCharge.gsld.almond.flat_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond.flat_grids[2].type,
            //   price: upCharge.gsld.almond.flat_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond.flat_grids[3].type,
              price: upCharge.gsld.almond.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond.flat_grids[4].type,
              price: upCharge.gsld.almond.flat_grids[4].price,
              warning: "",
            },
          ],
        },
        cocoa_white: {
          no_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.no_grids[0].type,
              price: upCharge.gsld.cocoa_white.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white.no_grids[1].type,
              price: upCharge.gsld.cocoa_white.no_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white.no_grids[2].type,
            //   price: upCharge.gsld.cocoa_white.no_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.no_grids[3].type,
              price: upCharge.gsld.cocoa_white.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.no_grids[4].type,
              price: upCharge.gsld.cocoa_white.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.contour_grids[0].type,
              price: upCharge.gsld.cocoa_white.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white.contour_grids[1].type,
              price: upCharge.gsld.cocoa_white.contour_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white.contour_grids[2].type,
            //   price: upCharge.gsld.cocoa_white.contour_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.contour_grids[3].type,
              price: upCharge.gsld.cocoa_white.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.contour_grids[4].type,
              price: upCharge.gsld.cocoa_white.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.flat_grids[0].type,
              price: upCharge.gsld.cocoa_white.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white.flat_grids[1].type,
              price: upCharge.gsld.cocoa_white.flat_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white.flat_grids[2].type,
            //   price: upCharge.gsld.cocoa_white.flat_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.flat_grids[3].type,
              price: upCharge.gsld.cocoa_white.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white.flat_grids[4].type,
              price: upCharge.gsld.cocoa_white.flat_grids[4].price,
              warning: "",
            },
          ],
        },
      },
      transoms: {
        white: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },

            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white_transoms.no_grids[0].type,
              price: upCharge.gsld.white_transoms.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white_transoms.no_grids[1].type,
              price: upCharge.gsld.white_transoms.no_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.white_transoms.no_grids[2].type,
            //   price: upCharge.gsld.white_transoms.no_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white_transoms.no_grids[3].type,
              price: upCharge.gsld.white_transoms.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white_transoms.no_grids[4].type,
              price: upCharge.gsld.white_transoms.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white_transoms.contour_grids[0].type,
              price: upCharge.gsld.white_transoms.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white_transoms.contour_grids[1].type,
              price: upCharge.gsld.white_transoms.contour_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.white_transoms.contour_grids[2].type,
            //   price: upCharge.gsld.white_transoms.contour_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white_transoms.contour_grids[3].type,
              price: upCharge.gsld.white_transoms.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white_transoms.contour_grids[4].type,
              price: upCharge.gsld.white_transoms.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.white_transoms.flat_grids[0].type,
              price: upCharge.gsld.white_transoms.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.white_transoms.flat_grids[1].type,
              price: upCharge.gsld.white_transoms.flat_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.white_transoms.flat_grids[2].type,
            //   price: upCharge.gsld.white_transoms.flat_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.white_transoms.flat_grids[3].type,
              price: upCharge.gsld.white_transoms.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.white_transoms.flat_grids[4].type,
              price: upCharge.gsld.white_transoms.flat_grids[4].price,
              warning: "",
            },
          ],
        },
        almond: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.no_grids[0].type,
              price: upCharge.gsld.almond_transoms.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond_transoms.no_grids[1].type,
              price: upCharge.gsld.almond_transoms.no_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond_transoms.no_grids[2].type,
            //   price: upCharge.gsld.almond_transoms.no_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.no_grids[3].type,
              price: upCharge.gsld.almond_transoms.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.no_grids[4].type,
              price: upCharge.gsld.almond_transoms.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.contour_grids[0].type,
              price: upCharge.gsld.almond_transoms.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond_transoms.contour_grids[1].type,
              price: upCharge.gsld.almond_transoms.contour_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond_transoms.contour_grids[2].type,
            //   price: upCharge.gsld.almond_transoms.contour_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.contour_grids[3].type,
              price: upCharge.gsld.almond_transoms.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.contour_grids[4].type,
              price: upCharge.gsld.almond_transoms.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.flat_grids[0].type,
              price: upCharge.gsld.almond_transoms.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.almond_transoms.flat_grids[1].type,
              price: upCharge.gsld.almond_transoms.flat_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.almond_transoms.flat_grids[2].type,
            //   price: upCharge.gsld.almond_transoms.flat_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.flat_grids[3].type,
              price: upCharge.gsld.almond_transoms.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.almond_transoms.flat_grids[4].type,
              price: upCharge.gsld.almond_transoms.flat_grids[4].price,
              warning: "",
            },
          ],
        },
        cocoa_white: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.no_grids[0].type,
              price: upCharge.gsld.cocoa_white_transoms.no_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.no_grids[1].type,
              price: upCharge.gsld.cocoa_white_transoms.no_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white_transoms.no_grids[2].type,
            //   price: upCharge.gsld.cocoa_white_transoms.no_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.no_grids[3].type,
              price: upCharge.gsld.cocoa_white_transoms.no_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.no_grids[4].type,
              price: upCharge.gsld.cocoa_white_transoms.no_grids[4].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.contour_grids[0].type,
              price: upCharge.gsld.cocoa_white_transoms.contour_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.contour_grids[1].type,
              price: upCharge.gsld.cocoa_white_transoms.contour_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white_transoms.contour_grids[2].type,
            //   price: upCharge.gsld.cocoa_white_transoms.contour_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.contour_grids[3].type,
              price: upCharge.gsld.cocoa_white_transoms.contour_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.contour_grids[4].type,
              price: upCharge.gsld.cocoa_white_transoms.contour_grids[4].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "5 ft (59.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.flat_grids[0].type,
              price: upCharge.gsld.cocoa_white_transoms.flat_grids[0].price,
              warning: "",
            },
            {
              height: "6 ft (70.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.flat_grids[1].type,
              price: upCharge.gsld.cocoa_white_transoms.flat_grids[1].price,
              warning: "",
            },
            // {
            //   height: "6 ft (71.5 x 79.5)",
            //   type: upCharge.gsld.cocoa_white_transoms.flat_grids[2].type,
            //   price: upCharge.gsld.cocoa_white_transoms.flat_grids[2].price,
            //   warning: ""
            // },
            {
              height: "8 ft (95.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.flat_grids[3].type,
              price: upCharge.gsld.cocoa_white_transoms.flat_grids[3].price,
              warning: "",
            },
            {
              height: "9 ft (106.25 x 79.5)",
              type: upCharge.gsld.cocoa_white_transoms.flat_grids[4].type,
              price: upCharge.gsld.cocoa_white_transoms.flat_grids[4].price,
              warning: "",
            },
          ],
        },
      },
      sidelites: {
        white: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.no_grids[0].type,
              price: upCharge.gsld.white_sidelites.no_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.no_grids[1].type,
              price: upCharge.gsld.white_sidelites.no_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.no_grids[2].type,
              price: upCharge.gsld.white_sidelites.no_grids[2].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.contour_grids[0].type,
              price: upCharge.gsld.white_sidelites.contour_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.contour_grids[1].type,
              price: upCharge.gsld.white_sidelites.contour_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.contour_grids[2].type,
              price: upCharge.gsld.white_sidelites.contour_grids[2].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.flat_grids[0].type,
              price: upCharge.gsld.white_sidelites.flat_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.flat_grids[1].type,
              price: upCharge.gsld.white_sidelites.flat_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.white_sidelites.flat_grids[2].type,
              price: upCharge.gsld.white_sidelites.flat_grids[2].price,
              warning: "",
            },
          ],
        },
        almond: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.no_grids[0].type,
              price: upCharge.gsld.almond_sidelites.no_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.no_grids[1].type,
              price: upCharge.gsld.almond_sidelites.no_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.no_grids[2].type,
              price: upCharge.gsld.almond_sidelites.no_grids[2].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.contour_grids[0].type,
              price: upCharge.gsld.almond_sidelites.contour_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.contour_grids[1].type,
              price: upCharge.gsld.almond_sidelites.contour_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.contour_grids[2].type,
              price: upCharge.gsld.almond_sidelites.contour_grids[2].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.flat_grids[0].type,
              price: upCharge.gsld.almond_sidelites.flat_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.flat_grids[1].type,
              price: upCharge.gsld.almond_sidelites.flat_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.almond_sidelites.flat_grids[2].type,
              price: upCharge.gsld.almond_sidelites.flat_grids[2].price,
              warning: "",
            },
          ],
        },
        cocoa_white: {
          no_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.no_grids[0].type,
              price: upCharge.gsld.cocoa_white_sidelites.no_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.no_grids[1].type,
              price: upCharge.gsld.cocoa_white_sidelites.no_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.no_grids[2].type,
              price: upCharge.gsld.cocoa_white_sidelites.no_grids[2].price,
              warning: "",
            },
          ],
          contour_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.contour_grids[0].type,
              price: upCharge.gsld.cocoa_white_sidelites.contour_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.contour_grids[1].type,
              price: upCharge.gsld.cocoa_white_sidelites.contour_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.contour_grids[2].type,
              price: upCharge.gsld.cocoa_white_sidelites.contour_grids[2].price,
              warning: "",
            },
          ],
          flat_grids: [
            {
              height: "none",
              type: "single",
              price: 0.0,
              warning: "",
            },
            {
              height: "1 ft (11.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.flat_grids[0].type,
              price: upCharge.gsld.cocoa_white_sidelites.flat_grids[0].price,
              warning: "",
            },
            {
              height: "2 ft (23.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.flat_grids[1].type,
              price: upCharge.gsld.cocoa_white_sidelites.flat_grids[1].price,
              warning: "",
            },
            {
              height: "3 ft (35.5 x 79.5)",
              type: upCharge.gsld.cocoa_white_sidelites.flat_grids[2].type,
              price: upCharge.gsld.cocoa_white_sidelites.flat_grids[2].price,
              warning: "",
            },
          ],
        },
      },

      glass: {},
      options: {
        custom_size: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 450.77,
            warning: "",
          },
        ],
        internal_blinds_5ft: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 460.0,
            warning: "",
          },
        ],
        internal_blinds_6ft: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 460.0,
            warning: "",
          },
        ],
        internal_blinds_9ft: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 790.0,
            warning: "",
          },
        ],
        queen_ann_grids: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 38.33,
            warning: "",
          },
        ],
      },
    },
    cs: {
      name: "CASEMENT",
      description: "",
      price_desc:
        "Price includes extruded full screen, double strength insulated glass, foam enhanced frame, low e and argon gas",
      sash: [1],
      lite_count: [1, 2, 3, 4, 5],
      operating_lite: [0, 1, 2],
      restriction: [
        {
          min_width: 15.0,
          max_width: 36.0,
          min_height: 22.25,
          max_height: 76,
        },
      ],
      colors: { white: {}, almond: {}, cocoa_white: {} },
      operating: {
        white: [
          {
            min_ui: 37,
            max_ui: 64.99,
            type: upCharge.cs.operating.white[0].type,
            price: upCharge.cs.operating.white[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74.99,
            type: upCharge.cs.operating.white[1].type,
            price: upCharge.cs.operating.white[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84.24,
            type: upCharge.cs.operating.white[2].type,
            price: upCharge.cs.operating.white[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.cs.operating.white[3].type,
            price: upCharge.cs.operating.white[3].price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 37,
            max_ui: 64.99,
            type: upCharge.cs.operating.almond[0].type,
            price: upCharge.cs.operating.almond[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74.99,
            type: upCharge.cs.operating.almond[1].type,
            price: upCharge.cs.operating.almond[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84.24,
            type: upCharge.cs.operating.almond[2].type,
            price: upCharge.cs.operating.almond[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.cs.operating.almond[3].type,
            price: upCharge.cs.operating.almond[3].price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 37,
            max_ui: 64.99,
            type: upCharge.cs.operating.cocoa_white[0].type,
            price: upCharge.cs.operating.cocoa_white[0].price,
            warning: "",
          },
          {
            min_ui: 65,
            max_ui: 74.99,
            type: upCharge.cs.operating.cocoa_white[1].type,
            price: upCharge.cs.operating.cocoa_white[1].price,
            warning: "",
          },
          {
            min_ui: 75,
            max_ui: 84.24,
            type: upCharge.cs.operating.cocoa_white[2].type,
            price: upCharge.cs.operating.cocoa_white[2].price,
            warning: "",
          },
          {
            min_ui: 84.25,
            max_ui: 999,
            type: upCharge.cs.operating.cocoa_white[3].type,
            price: upCharge.cs.operating.cocoa_white[3].price,
            warning: "",
          },
        ],
      },
      cpw: {
        white: [
          {
            min_ui: 37,
            max_ui: 80.24,
            type: upCharge.cs.cpw.white[0].type,
            price: upCharge.cs.cpw.white[0].price,
            warning: "",
          },
          {
            min_ui: 80.25,
            max_ui: 101,
            type: upCharge.cs.cpw.white[1].type,
            price: upCharge.cs.cpw.white[1].price,
            warning: "",
          },
          {
            min_ui: 101.01,
            max_ui: 999,
            type: upCharge.cs.cpw.white[2].type,
            price: upCharge.cs.cpw.white[2].price,
            warning: "",
          },
        ],
        almond: [
          {
            min_ui: 37,
            max_ui: 80.24,
            type: upCharge.cs.cpw.almond[0].type,
            price: upCharge.cs.cpw.almond[0].price,
            warning: "",
          },
          {
            min_ui: 80.25,
            max_ui: 101,
            type: upCharge.cs.cpw.almond[1].type,
            price: upCharge.cs.cpw.almond[1].price,
            warning: "",
          },
          {
            min_ui: 101.01,
            max_ui: 999,
            type: upCharge.cs.cpw.almond[2].type,
            price: upCharge.cs.cpw.almond[2].price,
            warning: "",
          },
        ],
        cocoa_white: [
          {
            min_ui: 37,
            max_ui: 80.24,
            type: upCharge.cs.cpw.cocoa_white[0].type,
            price: upCharge.cs.cpw.white[0].price,
            warning: "",
          },
          {
            min_ui: 80.25,
            max_ui: 101,
            type: upCharge.cs.cpw.cocoa_white[1].type,
            price: upCharge.cs.cpw.cocoa_white[1].price,
            warning: "",
          },
          {
            min_ui: 101.01,
            max_ui: 999,
            type: upCharge.cs.cpw.cocoa_white[2].type,
            price: upCharge.cs.cpw.cocoa_white[2].price,
            warning: "",
          },
        ],
      },
      glass: {
        clear: [
          {
            min_ui: 0,
            max_ui: 999,
            type: "single",
            price: 0.0,
            warning: "",
          },
        ],
        tempered: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 75.0,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 95.0,
            warning: "",
          },
        ],
        obscure: [
          {
            min_ui: 0,
            max_ui: 101,
            type: "single",
            price: 22.48,
            warning: "",
          },
          {
            min_ui: 102,
            max_ui: 999,
            type: "single",
            price: 44.97,
            warning: "",
          },
        ],
      },
      options: {},
    },
  },
};
