import React, { useEffect } from "react";

//styling
import { ModalStyle } from "./styles";
import { StyledButton } from "../../styles/styles";

const MoreCustomModal = props => {
  const buttonColor =
    props.buttonColor !== undefined ? props.buttonColor : "#001649";
  const escFunction = e => {
    if (e.keyCode === 27) {
      return props.submit === undefined ? props.onClose(e) : props.submit(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  let element = props.show ? (
    <div className="modal" id="modal" style={{ width: "100%" }}>
      <div className="modalContainer">
        <div className="content" style={{ width: "85%" }}>
          {props.children}
        </div>
        <div
          className="actions"
          style={{ display: "flex", flexDirection: "row", width: "85%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <StyledButton
              className={`${buttonColor} ${props.buttonActive}`}
              onClick={props.onClose}
              style={{
                alignSelf: "center",
                margin: "0",
                fontSize: "auto",
                width: "50%",
                maxWidth: "300px",
                height: "90%"
              }}
            >
              <b>{"CLOSE"}</b>
            </StyledButton>
          </div>
          {props.buttonOne !== undefined ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <StyledButton
                className={buttonColor}
                onClick={props.buttonOne}
                style={{
                  margin: "0",
                  width: "50%",
                  maxWidth: "300px",
                  height: "90%",
                  fontSize: "auto"
                }}
              >
                <b>{props.buttonOneText}</b>
              </StyledButton>
            </div>
          ) : null}
          {props.buttonTwo !== undefined ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <StyledButton
                className={buttonColor}
                onClick={props.buttonTwo}
                style={{
                  margin: "0",
                  width: "33%",
                  maxWidth: "300px",
                  height: "90%",
                  fontSize: "auto"
                }}
              >
                <b>{props.buttonTwoText}</b>
              </StyledButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
  return <ModalStyle theme={buttonColor}>{element}</ModalStyle>;
};

export default MoreCustomModal;
