import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { FormCard } from "../styles/styles";
import CustomModal from "../components/CustomModal/CustomModal";
import { StyledButton } from "../styles/styles";
import { Redirect } from "react-router";
import Dexie from "dexie";
import Success from "../components/Success/Success";
import { numberWithCommas } from "../components/utils/helper";
// import Toggle from "../components/Toggle/Toggle";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  InputLabel,
  Input,
  FormControl,
  Card,
  Typography,
  Slider,
  TextField
} from "@material-ui/core";

const CheckoutAdmin = () => {
  const {
    cart,
    totalPrice,
    isAdmin,
    setCart,
    syncIt,
    setIsAdmin,
    managerValues,
    setManagerValues,
    newCart,
    setNewCart
  } = useContext(Context);
  const [addModal, setAddModal] = useState(false);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [mh_num, setMHNum] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  useEffect(() => {
    console.log(totalPrice);
  });

  const backToCheckout = e => {
    e.preventDefault();
    setIsAdmin(false);
  };

  const downChange = e => {
    let tmp = managerValues;
    console.log("changing");
    console.log(managerValues);
    tmp.down = e.target.value;
    setManagerValues({ ...tmp });
  };

  const discountChange = (e, value) => {
    e.preventDefault();
    let tmp = managerValues;
    tmp.percent = ((100 - value) / 100).toFixed(2);
    console.log(managerValues);
    setManagerValues({ ...tmp });
  };

  const onAdd = e => {
    e.preventDefault();
    setAddModal(true);
  };
  const onDone = e => {
    e.preventDefault();
    setAddModal(false);
    setSaveModal(false);
  };
  const setVal = e => {
    e.preventDefault();
    const tmp = e.target.value;
    switch (e.target.id) {
      case "mh_num":
        setMHNum(tmp);
        break;
      case "first_name":
        setCustomerFirstName(tmp);

        break;
      case "last_name":
        setCustomerLastName(tmp);
        break;
      default:
        break;
    }
  };

  const saveClick = e => {
    e.preventDefault();
    setIsLoading(true);
    let tmp = {};
    tmp["mh_num"] = mh_num;
    tmp["first_name"] = customerFirstName;
    tmp["last_name"] = customerLastName;
    tmp["windows"] = cart;
    tmp["date"] = Date.now();
    tmp["submitted"] = false;
    setCart({});

    if ("serviceWorker" in navigator) {
      console.log("here it goes");
      console.log(newCart);
      navigator.serviceWorker.getRegistration("../App").then(registration => {
        saveData(newCart)
          .then(() => {
            if (registration.sync) {
              console.log("added to db");
              registration.sync.register("back-sync").catch(function(err) {
                return err;
              });
            } else {
              if (navigator.onLine) {
                //run axios call
                syncIt();
              } else {
                console.log(
                  "You are offline and your quote will be sent whenever you have service."
                );
              }
            }
          })
          .then(() => {
            setAddModal(false);
            setIsAdmin(false);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          });
      });
    } else {
      console.log("not valid sw");
    }
  };

  const submitClick = e => {
    e.preventDefault();
    setIsLoading(true);
    let tmp = {};
    tmp["mh_num"] = mh_num;
    tmp["first_name"] = customerFirstName;
    tmp["last_name"] = customerLastName;
    tmp["windows"] = cart;
    tmp["date"] = Date.now();
    tmp["submitted"] = true;
    setCart({});

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration("../App").then(registration => {
        console.log("here it goes");
        console.log(newCart);
        saveData(newCart)
          .then(() => {
            if (registration.sync) {
              console.log("added to db");
              registration.sync.register("back-sync").catch(function(err) {
                return err;
              });
            } else {
              if (navigator.onLine) {
                //run axios call
                syncIt();
              } else {
                console.log(
                  "You are offline and your quote will be sent whenever you have service."
                );
              }
            }
          })
          .then(() => {
            setAddModal(false);
            setIsAdmin(false);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          });
      });
    } else {
      console.log("not valid sw");
    }
  };

  function saveData(finalCart) {
    return new Promise(function(resolve, reject) {
      var db = new Dexie("savedCart");
      db.version(2).stores({ savedCartObj: "mh_num" });

      db.savedCartObj
        .add(finalCart)
        .then(() => {
          db.close();
          resolve();
        })
        .catch(e => {
          console.log(e);
          reject();
        });
    });
  }

  return isLoading ? (
    <Success />
  ) : isAdmin ? (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <Table
          style={{
            width: "75%",
            marginTop: "1em",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
          }}
        >
          <TableHead style={{ background: "#001649", color: "white" }}>
            <TableRow>
              <TableCell style={{ color: "white" }} align="center">
                <b>One Year</b>
              </TableCell>
              <TableCell style={{ color: "white" }} align="center">
                <b>Promo</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover="true" style={{ cursor: "pointer" }}>
              <TableCell align="center">
                <b>{`$${numberWithCommas((+totalPrice * 1.12).toFixed(2))}`}</b>
              </TableCell>
              <TableCell align="center">
                <b>{`$${numberWithCommas((+totalPrice).toFixed(2))}`}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center">
                {`$${numberWithCommas(
                  (+totalPrice * 1.12 * 0.029).toFixed(2)
                )}`}
              </TableCell>

              <TableCell align="center">
                {`$${numberWithCommas((+totalPrice * 0.0151).toFixed(2))}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table
          style={{
            width: "75%",
            marginTop: "1em",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
          }}
        >
          <TableHead style={{ background: "#001649", color: "white" }}>
            <TableRow>
              <TableCell style={{ color: "white" }} align="center">
                <b>Customer Promo Total</b>
              </TableCell>
              <TableCell style={{ color: "white" }} align="center">
                <b>Customer Payment</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover="true" style={{ cursor: "pointer" }}>
              <TableCell align="center" style={{ color: "green" }}>
                <b>{`$${numberWithCommas(
                  (
                    +totalPrice * managerValues.percent -
                    managerValues.down
                  ).toFixed(2)
                )}`}</b>
              </TableCell>
              <TableCell align="center" style={{ color: "green" }}>
                <b>{`$${numberWithCommas(
                  (
                    (+totalPrice * managerValues.percent - managerValues.down) *
                    0.0151
                  ).toFixed(2)
                )}`}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Card
          style={{
            width: "75%",
            marginTop: "1em",
            height: "20vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FormControl style={{ paddingTop: "1em", width: "85%" }}>
            <Typography id="continuous-slider" gutterBottom>
              <b>{`Promo Discount ${((1 - managerValues.percent) * 100).toFixed(
                0
              )}%`}</b>
            </Typography>
            <Slider
              valueLabelDisplay="auto"
              id="continuous-slider"
              name="promo_discount"
              label="promo_discount"
              min={0}
              max={15}
              step={1}
              value={((1 - managerValues.percent) * 100).toFixed(0)}
              onChange={discountChange}
            />
          </FormControl>
          <FormControl style={{ paddingTop: "1em", width: "85%" }}>
            <TextField
              label={"Downpayment"}
              id="downpayment"
              type="number"
              inputProps={{ step: 0.01 }}
              value={managerValues.down}
              onChange={downChange}
            />
          </FormControl>
        </Card>
        <StyledButton onClick={backToCheckout}>
          <b>DONE</b>
        </StyledButton>
      </div>
      <CustomModal
        show={saveModal}
        onClose={saveClick}
        value="SAVE"
        submit={onDone}
      >
        <FormCard
          show="false"
          style={{
            height: "350px",
            border: "solid",
            display: "flex",
            flexDirection: "column",
            width: "65%",
            transform: "none"
          }}
        >
          <h1 style={{ alignSelf: "center" }}>Customer Information</h1>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">MH NUM</InputLabel>
            <Input
              id="mh_num"
              placeholder="MH NUM"
              value={mh_num}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">First Name</InputLabel>
            <Input
              id="first_name"
              placeholder="First Name"
              value={customerFirstName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">Last Name</InputLabel>
            <Input
              id="last_name"
              placeholder="Last Name"
              value={customerLastName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
        </FormCard>
      </CustomModal>

      <CustomModal
        show={addModal}
        onClose={submitClick}
        value="SUBMIT"
        submit={onDone}
      >
        <FormCard
          show="false"
          style={{
            height: "350px",
            border: "solid",
            display: "flex",
            flexDirection: "column",
            width: "65%",
            transform: "none"
          }}
        >
          <h1 style={{ alignSelf: "center" }}>Customer Information</h1>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">MH NUM</InputLabel>
            <Input
              id="mh_num"
              placeholder="MH NUM"
              value={mh_num}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">First Name</InputLabel>
            <Input
              id="first_name"
              placeholder="First Name"
              value={customerFirstName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
          <FormControl
            className="inputMargin"
            style={{ width: "50%", alignSelf: "center" }}
          >
            <InputLabel htmlFor="location">Last Name</InputLabel>
            <Input
              id="last_name"
              placeholder="Last Name"
              value={customerLastName}
              onChange={setVal}
              required="true"
            />
          </FormControl>
        </FormCard>
      </CustomModal>
    </div>
  ) : (
    <Redirect to="/checkout" />
  );
};

export default CheckoutAdmin;
