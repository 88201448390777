import React, { useEffect, useContext } from "react";
//components
import Modal from "./styles";
import { Context } from "../../App";

const ModalComponent = ({ children, width, handleClose, onClose }) => {
  const { show } = useContext(Context);
  const { showPrice, setShowPrice } = useContext(Context);

  const escFunction = e => {
    if (e.keyCode === 27) {
      onClose(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  const handleClick = e => {
    if (e.target.id === "modalBackground") {
      return onClose(e);
    } else {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", handleClick, false);
  }, []);

  return (
    showPrice && (
      <Modal sideNavOpen={show} id="modalBackground" width={width}>
        <div className="modalMain" onClick={e => handleClick(e)}>
          {children}
        </div>
      </Modal>
    )
  );
};

export default ModalComponent;
