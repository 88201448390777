const { codeToPictureAPI } = require("../components/utils/helper");

export const getWindow = data => {
  return new Promise(async (resolve, reject) => {
    const windowType = codeToPictureAPI(data.mts_Style);
    const gridStyle = data.tmpGridStyle;
    const gridType =
      gridStyle.toLowerCase() === "diamond" ? "STANDARD" : "CONTOUR";
    const color = data.mts_color === "COCOA-WHITE" ? "COCOA" : data.mts_color;
    const url = `http://nbpapi.renoworks.com/core/api/_api2.asp?Product=CrusaderElite${windowType}&Configuration=SINGLE&Color=${color}&GridStyle=${gridStyle}&widIn=${data.mts_Width}&hgtIn=${data.mts_Height}&GridType=${gridType}`;
    console.log(url);
    resolve(url);
  });
};

export const readCart = pulledInfo => {
  const final = {
    cart: {},
    newcart: {
      OPP_NUM: pulledInfo[0].OPP_NUM,
      PROPOSAL_NAME: pulledInfo[0].PROPOSAL_NAME,
      EMAIL: "",
      PROPOSAL_ID: pulledInfo[0].PROPOSAL_ID,
      CART: [],
      TOTAL: {
        DOWNPAYMENT: pulledInfo[0].DOWNPAYMENT,
        PROMO_PERCENT: pulledInfo[0].PROMO_PERCENT,
        TOTAL_WINDOWS: pulledInfo[0].TOTAL_WINDOWS,
        PAR_TOTAL_PRICE: pulledInfo[0].PAR_TOTAL_PRICE,
        ACTUAL_TOTAL_PRICE: pulledInfo[0].ACTUAL_TOTAL_PRICE
      }
    }
  };
  pulledInfo.forEach(v => {
    let tmp = {
      name: `${v.MTS_STYLE} ${v.MTS_LOCATION}`,
      price: `${v.PAR_PRICE}`,
      PRODUCT_ID: `${v.PRODUCT_ID}`,
      mts_series: `${v.MTS_SERIES}`.toLowerCase(),
      mts_Style: `${v.MTS_STYLE}`.toLowerCase(),
      mts_GlassType: `${v.MTS_GLASSTYPE}`.toLowerCase(),
      mts_color: `${v.MTS_COLOR}`.toLowerCase(),
      mts_Width: +v.MTS_WIDTH,
      mts_Height: +v.MTS_HEIGHT,
      mts_Quantity: +v.MTS_QUANTITY,
      mts_DemarcationLocation: `${v.MTS_LOCATION}`,
      mts_gridtype: `${v.MTS_GRIDCODE}`,
      mts_gridpattern: `${v.MTS_GRIDPATTERN}`.toLowerCase(),
      tmpGridStyle: `${v.MTS_GRIDSTYLE}`.toLowerCase(),
      tmpGridPattern: `${v.MTS_FULLGRIDPATTERN}`,
      mts_Notes: `${v.MTS_NOTES}`,
      mts_sashCount: +v.MTS_SASHCOUNT,
      mts_Options: v.MTS_OPTIONS,
      mts_RemovalOptions: `${v.MTS_REMOVALOPTIONS}`.toLowerCase(),
      mts_Trim: v.MTS_TRIM,
      mts_Lite: `${v.MTS_LITE_COUNT}`,
      mts_OperatingLite: `${v.MTS_OPERATINGLITE}`,
      mts_Vents: v.MTS_VENTS !== "none" ? +v.MTS_VENTS : v.MTS_VENTS,
      mts_DHTilts: v.MTS_DHTILTS !== "none" ? +v.MTS_DHTILTS : v.MTS_DHTILTS,
      mts_DoorHeight: `${v.MTS_DOORHEIGHT}`,
      mts_DoorGrid: `${v.MTS_DOORGRID}`,
      mts_TransomHeight: `${v.MTS_TRANSOMHEIGHT}`,
      mts_TransomGrid: `${v.MTS_TRANSOMGRID}`,
      mts_SideliteHeight: `${v.MTS_SIDELITEHEIGHT}`,
      mts_SideliteGrid: `${v.MTS_SIDELITEGRID}`
    };
    final.cart[v.PRODUCT_ID] = tmp;
    let tmpCart = {
      PRODUCT_ID: `${v.PRODUCT_ID}`,
      window_details: {
        MTS_SERIES: `${v.MTS_SERIES}`,
        MTS_STYLE: `${v.MTS_STYLE}`,
        MTS_GLASS_TYPE: `${v.MTS_GLASSTYPE}`,
        MTS_COLOR: `${v.MTS_COLOR}`,
        MTS_QUANTITY: +v.MTS_QUANTITY,
        MTS_WIDTH: `${v.MTS_WIDTH}`,
        MTS_HEIGHT: `${v.MTS_HEIGHT}`,
        MTS_LOCATION: `${v.MTS_LOCATION}`,
        MTS_REMOVALOPTIONS: `${v.MTS_REMOVALOPTIONS}`,
        MTS_NOTES: `${v.MTS_NOTES}`,
        MTS_GRIDCODE: `${v.MTS_GRIDCODE}`,
        MTS_GRIDPATTERN: `${v.MTS_GRIDPATTERN}`,
        MTS_GRIDSTYLE: `${v.MTS_GRIDSTYLE}`,
        MTS_FULLGRIDPATTERN: `${v.MTS_FULLGRIDPATTERN}`,
        MTS_SASHCOUNT: +v.MTS_SASHCOUNT,
        MTS_LITE_COUNT: `${v.MTS_LITE_COUNT}`,
        MTS_OPERATINGLITE: `${v.MTS_OPERATINGLITE}`,
        MTS_DOORHEIGHT: `${v.MTS_DOORHEIGHT}`,
        MTS_DOORGRID: `${v.MTS_DOORGRID}`,
        MTS_TRANSOMHEIGHT: `${v.MTS_TRANSOMHEIGHT}`,
        MTS_TRANSOMGRID: `${v.MTS_TRANSOMGRID}`,
        MTS_SIDELITEHEIGHT: `${v.MTS_SIDELITEHEIGHT}`,
        MTS_SIDELITEGRID: `${v.MTS_SIDELITEGRID}`,
        MTS_DHTILTS: `${v.MTS_DHTILTS}`,
        MTS_VENTS: `${v.MTS_VENTS}`
      },
      options: {
        MTS_TRIM: v.MTS_TRIM,
        MTS_OPTIONS: v.MTS_OPTIONS
      },
      price_breakdown: {},
      par_price: +v.PAR_PRICE,
      unit_par_price: +v.UNIT_PAR_PRICE,
      actual_price: +v.ACTUAL_PRICE,
      actual_unit_price: +v.ACTUAL_UNIT_PRICE
    };
    final.newcart.CART.push(tmpCart);
  });
  return final;
};
