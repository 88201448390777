import React from "react";
import SuccessStyle from "./styles";

const Success = props => {
  return (
    <SuccessStyle>
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
    </SuccessStyle>
  );
};

export default Success;
