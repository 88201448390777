import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
import OtherLoader from "../components/OtherLoader/OtherLoader";
import { StyledBody } from "../styles/styles";
import Card from "../components/AnimatedCard";
import { Context } from "../App";
import { allWindows } from "../Data/window";
const getWindowPricing = () => allWindows;
// axios.get(
//   "https://azhlooipqc.execute-api.us-east-2.amazonaws.com/prod/staticwindows"
// );

const Home = ({ history }) => {
  //context
  const { show } = useContext(Context);
  const [windowPricing, setWindowPricing] = useState("");

  useEffect(() => {
    const getWindows = () => {
      const windows = getWindowPricing();
      console.log(windows.data);
      setWindowPricing(windows.data);
    };

    getWindows();
  }, []);

  const goToPricer = id => {
    history.push(`/pricer/${id}`, {
      data: Object.keys(windowPricing).filter(x => windowPricing[x].name === id)
    });
  };

  return !windowPricing ? (
    <OtherLoader />
  ) : (
    <StyledBody show={show}>
      {Object.keys(windowPricing).map((v, index) => {
        console.log("here is v");
        console.log(v);
        return (
          <Card
            key={index}
            onClick={() => goToPricer(windowPricing[v].name)}
            name={windowPricing[v].name}
          />
        );
      })}
    </StyledBody>
  );
};

export default Home;
