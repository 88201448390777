import styled from "styled-components";
import { Table } from "@material-ui/core";

export const StyledTable = styled(Table)`
  font-family: ${props => props.theme.font};
  height: 100%;
  margin: 30px auto;
  width: auto !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  .headerCells {
    line-height: 1;
    &:first-child {
      text-align: left;
      border-left: 1px solid #707070;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      color: white;
      background-color: #313e50;
      text-align: center;
    }

    &:last-child {
      border-right: 1px solid #707070;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      text-align: center;
    }
    font-size: 20px;
    color: #707070;
    text-align: center;
    border-bottom: 1px solid #707070;
    border-top: 1px solid #707070;
    text-align: center;
  }
  .tableRows {
    &:last-child {
      td {
        &:last-child {
        }
      }
    }
  }
  .rowCells {
    &:first-child {
      white-space: nowrap;
      text-align: left;
      background-color: #313e50;
      color: white;
      text-align: center;
    }
    &:last-child {
    }
    line-height: 2.5;
    color: #313e50;
    text-align: center;
    font-size: 16px;
    text-align: center;
  }

  .cellInput {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    min-height: 36px;
    padding: 8px 12px;
    width: 100%;
    margin-top: 5px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #c0c0c0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    font-size: 15px;
    color: #404040;
    outline: none;
  }
`;
