import React, { useState, useEffect, useContext } from "react";
import PinStyle from "./styles";
import { Context } from "../../App";

const Pin = props => {
  //context
  const {
    setShowPin,
    setIsAdmin,
    setShowAdd,
    setShowSave,
    isAdminPin
  } = useContext(Context);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");

  //check pin
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    setButton(userInput.length);
    if (userInput.length === 4) {
      console.log(userInput);
      if (userInput === "1234") {
        if (isAdminPin === "admin") {
          correctPin();
        } else if (isAdminPin === "add") {
          console.log("inside");
          correctAddPinModal();
        } else if (isAdminPin === "save") {
          correctSavePinModal();
        } else {
          wrongPin();
        }
      } else {
        wrongPin();
      }
    }

    // console.log(first);
  });

  const setButton = count => {
    switch (count) {
      case 1:
        setFirst("active");
        setSecond("");
        setThird("");
        setFourth("");
        break;
      case 2:
        setSecond("active");
        break;
      case 3:
        setThird("active");
        break;
      case 4:
        setFourth("active");
        break;
      default:
        break;
    }
  };

  const clearDots = () => {
    setFirst("");
    setSecond("");
    setThird("");
    setFourth("");
  };

  const correctDots = () => {
    setFirst("correct");
    setSecond("correct");
    setThird("correct");
    setFourth("correct");
  };

  const wrongDots = () => {
    setFirst("wrong");
    setSecond("wrong");
    setThird("wrong");
    setFourth("wrong");
  };

  const wrongPin = () => {
    console.log("WRONG");

    wrongDots();
    setUserInput("");
  };

  const correctPin = () => {
    correctDots();
    setTimeout(() => {
      setUserInput("");
      setIsAdmin(true);
      props.history.push("/checkoutadmin");
      setShowPin(false);
    }, 750);
  };
  const correctAddPinModal = () => {
    correctDots();
    setTimeout(() => {
      setUserInput("");
      setShowPin(false);
      setShowAdd(true);
    }, 750);
  };

  const correctSavePinModal = () => {
    correctDots();
    setTimeout(() => {
      setUserInput("");
      setShowPin(false);
      setShowSave(true);
    }, 750);
  };
  const clickNumber = e => {
    e.preventDefault();

    console.log(`Clicked ${e.target.id}`);
    let ui = userInput;
    ui += e.target.id;
    setUserInput(ui);
  };

  return (
    <PinStyle>
      <div id="pin">
        <div className="dots">
          <div className={`dot ${first}`}></div>
          <div className={`dot ${second}`}></div>
          <div className={`dot ${third}`}></div>
          <div className={`dot ${fourth}`}></div>
        </div>
        <p>Enter the password</p>
        <div class="numbers">
          <div id="1" className={`number `} onClick={clickNumber}>
            1
          </div>
          <div id="2" className="number" onClick={clickNumber}>
            2
          </div>
          <div id="3" className="number" onClick={clickNumber}>
            3
          </div>
          <div id="4" className="number" onClick={clickNumber}>
            4
          </div>
          <div id="5" className="number" onClick={clickNumber}>
            5
          </div>
          <div id="6" className="number" onClick={clickNumber}>
            6
          </div>
          <div id="7" className="number" onClick={clickNumber}>
            7
          </div>
          <div id="8" className="number" onClick={clickNumber}>
            8
          </div>
          <div id="9" className="number" onClick={clickNumber}>
            9
          </div>
        </div>
      </div>
    </PinStyle>
  );
};

export default Pin;
