import React from "react";
// import twoslider from "../assets/2slider.jpg";
// import awning from "../assets/awning.jpg";
// import casement from "../assets/casement.jpg";
// import pictureDH from "../assets/PictureDH.jpg";
// import pictureCS from "../assets/PictureCS.jpg";
// import dh from "../assets/DH.jpg";
// import threeSlide from "../assets/3SLIDE.jpg";
// import gardenbay from "../assets/gardenbay.jpeg";
// import baybow from "../assets/bay_bow.jpg";
// import glass_sliding from "../assets/sliding_glass.png";
// import bay from "../assets/baywndw.png";

//v2 photos
import dh from "../assets/DH_v2.png";
import awning from "../assets/awning_v2.png";
import twoslider from "../assets/2slider_v2.png";
import pictureDH from "../assets/PictureDH_v2.png";
import pictureCS from "../assets/PictureCS_v2.png";
import casement from "../assets/casement_v2.png";
import bay from "../assets/baywndw_v2.png";
import gardenbay from "../assets/gardenbay_v2.png";
import glass_sliding from "../assets/sliding_glass_v2.png";
import threeSlide from "../assets/3SLIDE_v2.png";
// import baybow from "../assets/bay_bow_v2.png";

//v3
import baybow from "../assets/bay_bow_v3.png";

const pics = [
  { name: "DOUBLE HUNG", img: dh },
  { name: "AWNING", img: awning },
  { name: "PICTURE WINDOW", img: pictureDH },
  { name: "PICTURE WINDOW CASEMENT", img: pictureCS },
  { name: "CASEMENT", img: casement },
  { name: "SLIDER (3 LITES)", img: threeSlide },
  { name: "SLIDER (2 LITES)", img: twoslider },
  { name: "VENTANA GARDEN BAY", img: gardenbay },
  { name: "BOW WINDOWS", img: baybow },
  { name: "CAMELOT SLIDING GLASS DOOR", img: glass_sliding },
  { name: "BAY WINDOWS", img: bay }
];

const Card = ({ onClick, name }) => {
  return (
    <div className="card" onClick={onClick} style={{ textAlign: "center" }}>
      <h1>{name}</h1>
      <img
        src={pics.filter(x => x.name === name)[0].img}
        alt={pics.filter(x => x.name === name)[0].name}
        height="280px"
      />
    </div>
  );
};

export default Card;
